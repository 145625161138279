import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormSelect,
  CRow,
} from "@coreui/react";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import ToastMessage from "../../utils/ToastMessage";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  baseURL,
  countryactivelist,
  createstate_api,
  get_speciality_api,
  specialtycreate_api,
  update_speciality_api,
} from "../../API/baseUrl";
import { useDispatch, useSelector } from "react-redux";
import {
  addspecialityRequest,
  editspecialitybyidRequest,
  removeDataFromReducer,
  specialityDetailsRequest,
} from "../../redux/actions";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { specialityshema } from "../../utils/schema";

const SpecialityAdd = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { addspecialitydata } = useSelector(
    (state) => state?.specialityaddreduser || {}
  );
  const { updateSpecialitydata } = useSelector(
    (state) => state?.SpecialityUpdateReduser || {}
  );

  const { detailSpecialitydata } = useSelector(
    (state) => state?.SpecialitydetailsReduser || {}
  );
  console.log("activecountrylistreduser", addspecialitydata);

  const Intial = {
    specialty: "",
  };

  const {
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: Intial,
    validationSchema: specialityshema,
    onSubmit: (values) => ApiHandlesubmit(),
  });

  useEffect(() => {
    if (addspecialitydata?.status) {
      setLoading(false)
      ToastMessage("success", addspecialitydata?.message);
      navigate("/dashboard/speciality");
      dispatch(removeDataFromReducer("ADD_SPECIALITY_RESET"));
    } else {
      ToastMessage("error", addspecialitydata?.message);
      setLoading(false)
    }
  }, [addspecialitydata]);

  
  useEffect(() => {
    if (updateSpecialitydata?.status) {
      setLoading(false)
      ToastMessage("success", updateSpecialitydata?.message);
      navigate("/dashboard/speciality");
      dispatch(removeDataFromReducer("SPECIALITY_UPDATE_RESET"));
      dispatch(removeDataFromReducer("SPECIALITY_DETAILS_RESET"));
    } else {
      ToastMessage("error", updateSpecialitydata?.message);
      setLoading(false)
    }
  }, [updateSpecialitydata]);

  const handleSubmit2 = (e) => {
    e.preventDefault();
    handleSubmit();
    console.log("error", errors);
    console.log("values", values);
  };


  
  useEffect(() => {
    const parameter = {
      url: `${get_speciality_api}${id}`,
    };
    dispatch(specialityDetailsRequest(parameter));
  }, [id]);


  
  useEffect(() => {
    if (detailSpecialitydata?.status) {
     values.specialty = detailSpecialitydata?.data?.specialty
     setFieldValue("specialty",detailSpecialitydata?.data?.specialty)
    } 
      // ToastMessage("error", getstatedata?.message);
  }, [detailSpecialitydata]);



  const ApiHandlesubmit = async () => {
    setLoading(true)
    const parameter = {
      specialty: values.specialty,
      url: id ? `${update_speciality_api}${id}` : specialtycreate_api ,
    };
    if(id){
      dispatch(editspecialitybyidRequest(parameter));
    }
    else{
      dispatch(addspecialityRequest(parameter));
    }
  
  };
  const handleBackNavigation = () => {
    navigate(-1); // Navigates back to the previous page
  };

  return (
    <CForm onSubmit={handleSubmit2}>
   
      <h3 className="AddEdit_Heading">  <ArrowBackIcon style={{cursor:"pointer"}}  onClick={handleBackNavigation}/> {id ? "Edit Cuisine" : "Add Cuisine"}</h3>

      <CContainer className="add_form_container">
        <CRow xs={{ gutter: 2 }}>
          <CCol sm={12} md={6}>
            <CFormInput
              name="specialty"
              type="text"
              value={values.specialty}
              id="exampleFormControlInput1"
              label="Cuisine Name *"
              placeholder="Cuisine Name"
              // text={errors.specialty}
              invalid={touched.specialty && !!errors.specialty}

              onChange={handleChange}
              onBlur={handleBlur}
              aria-describedby="exampleFormControlInputHelpInline"
              className="field-width"
            />
                          {touched.specialty && errors.specialty && (
              <div className="invalid-feedback">{errors.specialty}</div>
            )}
          </CCol>
        </CRow>

        <div className="d-grid gap-2 d-md-flex justify-content-md-end py-5">
        <CButton style={{
            backgroundColor: "var(--colorused)",
            border: "none",
            width: "120px",
            color: "var(--colorWhite)",
          }} type="submit" className="me-md-2">
            {loading ? (
              <CircularProgress size={24} style={{ color: "white" }} />
            ) :

              <>{id ? "Update" : "Submit"}</>
            }

          </CButton>
        </div>
      </CContainer>
    </CForm>
  );
};

export default SpecialityAdd;
