import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import "./index.css";
import { CFormSelect } from '@coreui/react';

// Register the required chart elements
ChartJS.register(ArcElement, Tooltip, Legend);

const PieChartDropdown = ({data}) => {
    const [piedataval, setPiedataval] = useState([]);
    const [pending, setPending] = useState(true);


    useEffect(() => {
        if (data && data.length > 0) {
          setPiedataval(data[0].surpriseBagSizeList || []);
        }
      }, [data]);
    






  const labels = piedataval?.map(item => item.surpriseBagSize);
  const counts = piedataval?.map(item => Number(item.totalNetCommissionAdminSurpriseBagSize));



  const pieData = {
    labels: labels,
    datasets: [
      {
        data: counts,
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56' ,'green' , 'blue'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
      },
    ],
  };

  // Use inline styles or external CSS to control the chart size
  const options = {
    maintainAspectRatio: false, // Allow custom size control
  };

  const statusArray = [
    {
      label: 'Pending',
      value: 0,
    },
    {
      label: 'Accept',
      value: 1,
    },
    {
      label: 'Not-Accept',
      value: 2,
    },
  ]

//   const handleselectchange = (e) => {
//     // setAccountstatus(e.target.value)
//     console.log("e.target.value", e.target.value)
//     setPiedataval([])
//   };


  const handleselectchange = (e) => {
    const selectedTypeId = e.target.value;
    // Find the selected restaurant object
    const selectedRestaurant = data?.find(
      (item) => item.restaurantTypeIdFk.toString() === selectedTypeId
    );

    if (selectedRestaurant) {
      // Update the state with the selected restaurant's count
      setPiedataval(selectedRestaurant.surpriseBagSizeList);
    }
  };

  return (
    <div className='pie_container_fluid'> {/* Control chart size here */}

        {/* <CFormSelect
          className="Status_dropdown_dashboard"
          name="status"
          label="Select Restaurant Type"
          // disabled={statusvalue === 1 || statusvalue === 2}
          placeholder="Document Status"
          value=""
          onChange={()=>handleselectchange()}
        >
          {data?.map((item, index) => (
            <option key={index} value={item.value}>
              {item.restaurantType}
            </option>
          ))}
        </CFormSelect> */}

<CFormSelect
        className="Status_dropdown_dashboard"
        name="status"
        label="Select Restaurant Type"
        placeholder="Document Status"
        onChange={handleselectchange}
      >
        {data?.map((item, index) => (
          <option key={index} value={item.restaurantTypeIdFk}>
            {item.restaurantType}
          </option>
        ))}
      </CFormSelect>



      <Pie data={pieData} options={options} />
    </div>
  );
};

export default PieChartDropdown;