import { CButton, CCol, CFormSwitch, CRow } from "@coreui/react";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  countryStatus_api,
  getcountrylist_api,
  ImageUrl,
} from "../../API/baseUrl";
import ClearIcon from "@mui/icons-material/Clear";
import {
  countryList,
  countryStatusRequest,
  removeDataFromReducer,
} from "../../redux/actions";
import ToastMessage from "../../utils/ToastMessage";
import { TableSk } from "../skelaton/tableSk";
import { CountryTitle } from "../Title";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";

const Country = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pending, setPending] = useState(true); // State for loading indicator
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [sortBy, setSortBy] = useState("DESC");
  const [sortByColumnName, setSortByColumnName] = useState("");
  const dispatch = useDispatch();
  const { countryListInfo } = useSelector((state) => state?.countryList || {});
  const { countrystatusdata } =
    useSelector((state) => state?.countrystatus) || {};

  useEffect(() => {

    document.title = CountryTitle;
  }, []);

  useEffect(() => {
    if (countryListInfo?.status) {
      setData(countryListInfo?.data);
      setTotalRows(countryListInfo?.count);
      setPending(false);
    } else {
      ToastMessage("error", countryListInfo?.message);
    }
  }, [countryListInfo]);

  useEffect(() => {
    const parameter = {
      offset: offset,
      limit: limit,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      url: getcountrylist_api,
    };
    dispatch(countryList(parameter));
  }, [offset, limit, searchText, sortByColumnName, sortBy]);

  const handleStatus = (id) => {
    const parameter = {
      url: `${countryStatus_api}/${id} `,
    };
    dispatch(countryStatusRequest(parameter));
  };

  //   const handleAddCountry = (e) => {
  //     navigate("/dashboard/country/add");
  //     setIsLoading(true)

  //     setTimeout(() => {
  //         setIsLoading(false);
  //       }, 5000);
  //   };

  const handleAddCountry = async (e) => {
    dispatch(removeDataFromReducer("COUNTRY_DETAILS_RESET"));
    navigate("/dashboard/country/add");
  };

  useEffect(() => {
    const parameter = {
      offset: offset,
      limit: limit,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      url: getcountrylist_api,
    };
    if (countrystatusdata?.status) {
      ToastMessage("success", countrystatusdata?.message);
      dispatch(countryList(parameter));
      dispatch(removeDataFromReducer("COUNTRY_STATUS_RESET"));
    } else {
      ToastMessage("error", countrystatusdata?.message);
    }
  }, [countrystatusdata]);

  const columns = [
    {
      name: "Id",
      selector: (row) => row?.id,
      sortable: true,
      column_name: "id",
      reorder: true,
    },
    {
      name: "ISD code",
      width: "150px",
      // selector: (row) => row.countryISD,
      selector: (row) => `+${row.countryISD}`,
      // sortable: true,
      column_name: "countryISD",
      reorder: true,
    },
    {
      name: "Short code",
      width: "200px",
      selector: (row) => row.countryShortCode,
      sortable: true,
      column_name: "countryShortCode",
      reorder: true,
    },
    {
      name: "Name",
      width: "150px",
      selector: (row) => row.countryName,
      sortable: true,
      column_name: "countryName",
      reorder: true,
    },
    {
      name: "Currency",
      width: "150px",
      selector: (row) => row.countryCurrency,
      // sortable: true,
      column_name: "countryCurrency",
      reorder: true,
    },
    {
      name: "Flag",
      width: "120px",
      // selector: (row) => row.countryFlag,
      selector: (row) => (
        <img
          height={50}
          width={50}
          src={`${ImageUrl}${row.countryFlag}`}
          alt="country flag"
        />
      ),
      // sortable: true,
      column_name: "countryFlag",
      reorder: true,
    },
    {
      name: "Status",
      width: "150px",
      selector: (row) => (
        <div className="switch_row">
          <CFormSwitch
            onClick={() => handleStatus(row.id)}
            label={row.isActive === 1 ? "Active" : "Inactive"}
            id="formSwitchCheckChecked"
            defaultChecked={row.isActive}
            style={{
              backgroundColor:
                row.isActive === 1 ? "var(--colorused)" : "initial",
              color: row.isActive === 1 ? "var(--colorWhite)" : "initial",
            }}
          />
        </div>
      ),
      // sortable: true,
      column_name: "isActive",
      reorder: true,
    },
    {
      name: "Edit",
      selector: (row) => (
        <button
          className="edit_btn"
          onClick={() => handleEdit(row.id)}
          style={{
            backgroundColor: "var(--colorused)",
            border: "none",
            color: "var(--colorWhite)",
          }}
        >
          Edit
        </button>
      ),
      // sortable: true,
      column_name: "",
      reorder: true,
    },
  ];

  const handleEdit = (id) => {
    navigate(`/dashboard/country/add/${id}`);
  };

  const customStyles = {
    headCells: {
        style: {
            fontSize: "16px",
            fontWeight: 500,
        },
    },
};

  const handlePerRowsChange = async (newLimit, offset) => {
    setOffset(offset);
    setLimit(newLimit);
  };

  const handlePageChange = (offset) => {
    setOffset(offset);
  };

  const handleSort = async (column, sortDirection) => {
    setSortBy(sortDirection);
    setSortByColumnName(column.column_name || "");
  };

  const handleclearSearch = () => {
    setSearchText("");
  };

  return (
    <div>
      {/* <div>
        <CRow>
          <CCol
            className="d-grid gap-2 d-md-flex justify-content-md-start pb-5"
            lg={7}
            md={6}
            sm={4}
            xs={12}
          >
            <h3>Country List</h3>
          </CCol>
          <CCol
            className="d-grid gap-2 d-md-flex justify-content-md-end pb-5"
            lg={3}
            md={3}
            sm={4}
            xs={7}
          >
            <div className="search-feild">
              <input
                type="text"
                placeholder="Search"
                style={{ outline: "none" }}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              {searchText ? (
                <ClearIcon
                  onClick={handleclearSearch}
                  sx={{
                    color: `var(--colorused)`,
                    cursor: "pointer",
                    fontSize: "20px",
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </CCol>
          <CCol
            className="d-grid gap-2 d-md-flex justify-content-md-end pb-5"
            lg={2}
            md={3}
            sm={4}
            xs={5}
          >
            <CButton
              style={{
                backgroundColor: "var(--colorused)",
                border: "none",
                color: "var(--colorWhite)",
                fontSize: "13px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              className="me-md-2 btn_add_country"
              onClick={handleAddCountry}
              // disabled={isLoading}
            >
              Add Country
            </CButton>
          </CCol>
        </CRow>
      </div> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "20px",
          flexWrap:"wrap",
        }}
        lg={8}
      >
        <div>
          <h3 style={{ margin: 0 }}>Country List</h3>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="search-feild mx-1">
            <input
              type="text"
              placeholder="Search"
              style={{ outline: "none" }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            {searchText ? (
              <ClearIcon
                onClick={handleclearSearch}
                sx={{
                  color: `var(--colorused)`,
                  cursor: "pointer",
                  fontSize: "20px",
                }}
              />
            ) : (
              ""
            )}
          </div>
          <CButton
            style={{
              backgroundColor: "var(--colorused)",
              border: "none",
              color: "var(--colorWhite)",
              fontSize: "13px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            className="me-md-2 btn_add_country"
            onClick={handleAddCountry}
          // disabled={isLoading}
          >
            Add Country
          </CButton>
        </div>
      </div>

      <DataTable
        customStyles={customStyles}
        columns={columns}
        data={data}
        dataAlign="center"
        fixedHeader
        progressPending={pending}
        progressComponent={<LoaderAnimation/>}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        fixedHeaderScrollHeight="58vh"
        sortServer
        responsive
        onSort={handleSort}
      />
    </div>
  );
};

export default Country;
