// import { Skeleton, TableBody, TableCell, TableRow } from '@mui/material'
// import React from 'react'
// import DataTable from 'react-data-table-component'
// const data = [1,2,3,4,5,6]

// export const SkeletonBody = () => {
//     return (
//         <DataTable
//           className="data_table_class billing_table"
//           fixedHeader
//           fixedHeaderScrollHeight="60vh"
//           title="(99 Enriched/ 04 In Process)"
//           columns={header}
//           data={data}
//           progressPending={pending}
//           progressComponent={'loading........'}
//           pagination
//           paginationServer
//           paginationTotalRows={totalRows}
//           onChangeRowsPerPage={handlePerRowsChange}
//           onChangePage={handlePageChange}
//           sortServer
//           responsive
//           onSort={handleSort}
//         />

//     )
// }

// export const TableSk = () => {
//     // const [state, setState] = useState([])
//     // useEffect(()=>{
//     // const timer = setTimeout(() => {
//     //     setState(["Data Not Found..."])
//     // }, 2000);
//     // return()=>{
//     // clearTimeout(timer)
//     // }
//     // },[])
//     return (
//         <TableBody >
//             {data.map((row, index) =>
//                 <TableRow key={index}
//                     sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
//                 >
//                     <TableCell className="first_cell" size="small" scope="row">
//                         <Skeleton animation="pulse" width={80} height={55} />
//                     </TableCell>
//                     <TableCell align="center"><Skeleton animation="pulse" width={80} height={55} /></TableCell>
//                     <TableCell align="center"><Skeleton animation="pulse" width={80} height={55} /></TableCell>
//                     <TableCell align="center"><Skeleton animation="pulse" width={80} height={55} /></TableCell>
//                     <TableCell align="center"><Skeleton animation="pulse" width={80} height={55} /></TableCell>
//                     <TableCell align="center"><Skeleton animation="pulse" width={80} height={55} /></TableCell>
//                     <TableCell align="center"><Skeleton animation="pulse" width={80} height={55} /></TableCell>
//                     <TableCell align="center"><Skeleton animation="pulse" width={80} height={55} /></TableCell>
//                     <TableCell align="center"><Skeleton animation="pulse" width={80} height={55} /></TableCell>
//                     <TableCell align="center"><Skeleton animation="pulse" width={80} height={55} /></TableCell>
//                     <TableCell align="center"><Skeleton animation="pulse" width={80} height={55} /></TableCell>
//                     <TableCell align="center"><Skeleton animation="pulse" width={80} height={55} /></TableCell>
//                     <TableCell align="center"><Skeleton animation="pulse" width={80} height={55} /></TableCell>
//                     <TableCell align="center"><Skeleton animation="pulse" width={80} height={55} /></TableCell>
//                     <TableCell align="center"><Skeleton animation="pulse" width={80} height={55} /></TableCell>
//                     <TableCell align="center"><Skeleton animation="pulse" width={80} height={55} /></TableCell>
//                 </TableRow>

//             )}
//         </TableBody>

//     )
// }















import { Skeleton, TableBody, TableCell, TableRow } from '@mui/material'; // Import components from MUI
import React from 'react';
import DataTable from 'react-data-table-component';

const data = [1, 2, 3, 4, 5, 6];

export const SkeletonBody = () => {
  // Placeholder values for props used in DataTable, replace with actual data
  const header = []; // Define your header columns array
  const pending = true; // Define pending state
  const totalRows = data.length; // Calculate total rows based on data length

  // Dummy handlers, replace with actual implementation
  const handlePerRowsChange = (newLimit, page) => {
    console.log('Rows per page changed:', newLimit);
  };

  const handlePageChange = (page) => {
    console.log('Page changed:', page);
  };

  const handleSort = (column, sortDirection) => {
    console.log('Sorting:', column, sortDirection);
  };

  return (
    <DataTable
      className="data_table_class billing_table"
      fixedHeader
      fixedHeaderScrollHeight="60vh"
      title="(99 Enriched/ 04 In Process)"
      columns={header}
      data={data}
      progressPending={pending}
      progressComponent={'loading........'}
      pagination
      paginationServer
      paginationTotalRows={totalRows}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      sortServer
      responsive
      onSort={handleSort}
    />
  );
};

export const TableSk = () => {
  return (
    <TableBody>
      {data.map((row, index) => (
        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          {/* Adjust this based on your actual data structure */}
          <TableCell className="first_cell" size="small" scope="row">
            <Skeleton animation="pulse" style={{width:"100px"}} height={55} />
          </TableCell>
          <TableCell align="center">
            <Skeleton animation="pulse" width={80} height={55} />
          </TableCell>
          <TableCell align="center">
            <Skeleton animation="pulse" width={80} height={55} />
          </TableCell>
          <TableCell align="center">
            <Skeleton animation="pulse" width={80} height={55} />
          </TableCell>
          <TableCell align="center">
            <Skeleton animation="pulse" width={80} height={55} />
          </TableCell>
          <TableCell align="center">
            <Skeleton animation="pulse" width={80} height={55} />
          </TableCell>
          <TableCell align="center">
            <Skeleton animation="pulse" width={80} height={55} />
          </TableCell>
          <TableCell align="center">
            <Skeleton animation="pulse" width={80} height={55} />
          </TableCell><TableCell align="center">
            <Skeleton animation="pulse" width={80} height={55} />
          </TableCell>
          {/* Repeat TableCell for each column */}
        </TableRow>
      ))}
    </TableBody>
  );
};

