
export const HomeDashboard ="Pick'n'Treat"
export const CountryTitle = "Country";
export const StateTitle = "State";
export const CityTitle = "City";
export const CuisineTitle = "Cuisine";
export const LiveVendorsTitle = "Live Vendors";
export const VendorAccountsTitle = "Vendor Accounts";
export const VendorDocumentsTitle = "Vendor Documents";
export const MagicBagSizeTitle = "Magic Bag Size";
export const RestaurantTypeTitle = "Restaurant Type";
export const OrderTitle = "Orders";
export const BillingTitle = "Billing";
export const FaqTitleTitle = "FAQ";
export const SupportTicketListTitle="Support Ticket";
export const PrivacyPolicyTitle="Privacy Policy";
export const TermsandConditionsTitle="Terms and Conditions";
export const UsersTitle="Users";
export const SettingsTitle="Settings";




