import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import "./index.css";
import { CFormSelect } from '@coreui/react';

// Register the required chart elements
ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ data }) => {
  const labels = data?.map(item => item.restaurantType);
  const counts = data?.map(item => Number(item.restaurantCount));

  const pieData = {
    labels: labels,
    datasets: [
      {
        data: counts,
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
      },
    ],
  };

  // Use inline styles or external CSS to control the chart size
  const options = {
    maintainAspectRatio: false, // Allow custom size control
  };


  return (
    <div className='pie_container_fluid'> {/* Control chart size here */}

      <h2 className='pie_heading'>Active Restaurants</h2>


      <Pie data={pieData} options={options} />
    </div>
  );
};

export default PieChart;