import axios from "axios";

// export const baseUrl = axios.create({
//     baseURL: 'https://81c6-2401-4900-1ca3-5985-131-97b2-1eb8-11b3.ngrok-free.app/',
// });

export const ImageUrl =
  "https://pickntreat.s3.amazonaws.com/";

  // dev

// export const baseURL = "https://dev.api.pickntreat.in/";


// production

export const baseURL = "https://prod.api.pickntreat.in/";


export const signIn_api = baseURL + "admin/login";

export const countryactivelist = baseURL + "country/getList";

export const create_city_api = baseURL + "admin/city/create";

export const stateactivelist = baseURL + "state/getList";

export const speciality_status =
  baseURL + "specialty/admin/specialty/active-inactive";

export const specialtyList_api = baseURL + "specialty/admin/specialty/getList";

export const specialtycreate_api = baseURL + "specialty/admin/specialty/create";

export const sendemail_api = baseURL + "admin/resetPassword/email/send";

export const createcountry_api = baseURL + "admin/country/create";

export const createstate_api = baseURL + "admin/state/create";

export const getcountrylist_api = baseURL + "admin/country/getAdminList";

export const getcitylist_api = baseURL + "admin/city/adminList";

export const getstatelist_api = baseURL + "admin/state/getAdminList";

export const stateStatus_api = baseURL + "admin/state/active-deactivate";

export const cityStatus_api = baseURL + "admin/city/active-deactivate";

export const countryStatus_api = baseURL + "admin/country/active-deactivate";

// export const vendorList_api = baseURL + "admin/restaurant/list";

export const vendorList_api = baseURL + "admin/restaurant/list";

export const restaurantView_api = baseURL + "admin/restaurant/history/getById/";

export const restauranttypelist_api = baseURL + "get/restaurant-type-list";

export const restaurantaccountstatus_api = baseURL + "admin/restaurant/account-status/update";

export const getcityById_api = baseURL + "admin/city/";

export const resturant_type_active_api = baseURL + "get/restaurant-type-list";

export const editById_api = baseURL + "admin/city/edit/";

export const getstateById_api = baseURL + "admin/state/getById/";

export const editstateById_api = baseURL + "admin/state/edit/";

export const editcountryById_api = baseURL + "admin/country/edit/";

export const getcountryById_api = baseURL + "admin/country/getById";

export const document_list_api = baseURL + "admin/get/restaurant/document/list";

export const document_viewlist_api = baseURL + "restaurant/document/history/getById/";

export const document_statusUpdate_api = baseURL + "admin/restaurant/document-status/update";

export const bagSizeList_api = baseURL + "admin/get/surprise-bag-size/list";

export const bagSizecreate_api = baseURL + "admin/create/surpriseBagSize";

export const typeList_api = baseURL + "admin/get/restaurant-type-list";

export const typecreate_api = baseURL + "admin/create/restaurant-type";

export const typestatus_api = baseURL + "admin/active-deactivate/restaurant-type/";

export const sizeStatus_api = baseURL + "admin/active-deactivate/surprise-bag-size/";

export const get_speciality_api = baseURL + "specialty/admin/get/specialty/";

export const update_speciality_api = baseURL + "specialty/admin/specialty/update/";

export const get_bagsize_api = baseURL + "admin/get/surpriseBagSize/";

export const update_bagsize_api = baseURL + "admin/surpriseBagSize/update/";

export const get_restaurantType_api = baseURL + "admin/get/restaurant-type/";

export const update_type_api = baseURL + "admin/restaurant-type/update/";

export const order_list_api = baseURL + "admin/get/order/list";

export const create_faq_api = baseURL + "admin/faq/create";

export const typelist_faq_api = baseURL + "get/faq-type-list";

export const faqList_api = baseURL + "admin/get/faq-list";

export const Create_Orderlist_api = baseURL + "admin/get/order-history/by/order-id";

export const supportTicketList_api = baseURL + "admin/support-ticket-list";

export const supportTicket_getbyid_api = baseURL + "admin/get/support-ticket/";

export const get_ticket_status_api = baseURL + "get/ticket-status";

export const faq_active_api = baseURL + "admin/faq/active-deactivate/";

export const create_setting_api = baseURL + "admin/create/setting";

export const get_setting_api = baseURL + "admin/get/setting/data";

export const live_vendorList_api = baseURL + "admin/live-restaurant/list";

export const update_faq_api = baseURL + "admin/update/faq/";

export const getbyId_faq_api = baseURL + "admin/get/faq/ById/";

export const vendorActive_deactive_api = baseURL + "admin/active-deactivate/restaurant/";

export const setting_list_api = baseURL + "admin/setting/getAdminList";

export const setting_create_api = baseURL + "admin/create/setting";

export const setting_getbyId_api = baseURL + "admin/setting/getById/";

export const setting_edit_api = baseURL + "admin/setting/edit/";

export const setting_activeInactive_api = baseURL + "admin/setting/active-deactivate/";

export const change_ticket_status_api = baseURL + "admin/support-ticket/edit/";

export const TicketChatData_api = baseURL + "submit-ticket-chat-data"

export const GetTicketChatData_api = baseURL + "get-ticket-chat-data"

export const exceldownload_api = baseURL + "admin/get/order/list/excel-download"

export const billing_list_api = baseURL + "admin/get/transaction/order/list"

export const user_active_inactive = baseURL + "admin/active-deactivate/user/"

export const billing_list_export_download_api = baseURL + "admin/get/transaction/order/list/excel-download"

export const dashboard_api = baseURL + "revenue-dashboard/admin/revenue-dashboard"

export const User_api = baseURL + "admin/get/user-list"

export const user_viewlist_api = baseURL + "admin/get/user/profile-history/"

export const vendor_order_api = baseURL + "admin/get/user/order/list/"

export const orderList_api = baseURL + "admin/get/vendor/order/list/"

export const BankList_api = baseURL + "admin/bank/getList"

export const AddBank_api = baseURL + "admin/bank/create"

export const bank_inactive_api = baseURL + "admin/bank/active-inactive/"

export const bank_Edit_api = baseURL + "admin/bank/update/"

export const Get_bank_ByID_api =baseURL + "admin/get/bank/"

export const Revenue_Dashboard_Admin_vendor =baseURL + "revenue-dashboard/admin/vendor/commission"