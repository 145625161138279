import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import App from './App'
import DefaultLayout from './layout/DefaultLayout'
import store from './redux/store'
import City from './views/city'
import CityAdd from './views/city/cityadd'
import Country from './views/country'
import CountryAdd from './views/country/countryAdd'
import Dashboard from './views/dashboard/Dashboard'
import ForgetPassword from './views/pages/login/forget'
import Login from './views/pages/login/Login'
import RestaurantTypeAdd from './views/restaurantType/addtype'
import RestaurantType from './views/restaurantType/restaurantTypelist'
import Specialty from './views/specialty'
import SpecialityAdd from './views/specialty/specialityadd'
import State from './views/state'
import StateAdd from './views/state/stateadd'
import SurpriceBagSize from './views/surpriceBagSize'
import SurpriseBagAdd from './views/surpriceBagSize/bagSizeadd'
import Accountverification from './views/vendor/account'
import Documentverification from './views/vendor/document'
import ViewDocument from './views/vendor/documentViem'
import ViewRestuarant from './views/vendor/viewrestaurant'
import '../src/index.css'
import Orderlist from './views/orderList'
import AddFaq from './views/faq/addFaq'
import FaqList from './views/faq/faqlist'
import CreateOrderList from './views/orderList/createOrderList'
import SupportList from './views/support'
import ViewSupport from './views/support/supportView'
import Privacy from './views/setting/Privacy'
import TermsConditions from './views/setting/TermConditions'
import LiveVendor from './views/vendor/liveVendor'
import SettingList from './views/setting/settingList'
import AddSetting from './views/setting/settingAdd'
import Billinglist from './views/orderbilling/Billing'
import ViewLiveRestuarant from './views/vendor/livewendorview'
import User from './views/User/Index'
import ViewUser from './views/User/ViewUser'
import BankList from './views/Bank/Banklist'
import AddBank from './views/Bank/AddBank'
import Adminuser from './views/admins/adminuser'
import Adminrole from './views/admins/adminrole'
import Permission from './views/admins/permission'



const router = createBrowserRouter([
  
  {
    path: "/",
    element: <Login/>,
  },
  {
    path: "/forgetPassword",
    element: <ForgetPassword/>,
  },
  {
    path: "/dashboard",
    element: <DefaultLayout/>,
    children: [
      { element: <Navigate to="dashboard/app" />, index: true },
      { path: "app", element: <Dashboard/> },
      { path: "country", element: <Country/> },
      { path: "country/add", element: <CountryAdd/>},
      { path: "country/add/:id", element: <CountryAdd/>},
      { path: "city", element: <City/> },
      { path: "city/add", element: <CityAdd/> },
      { path: "city/add/:id", element: <CityAdd/> },
      { path: "state", element: <State/> },
      { path: "state/add", element: <StateAdd/> },
      { path: "state/add/:id", element: <StateAdd/> },
      { path: "speciality/add", element: <SpecialityAdd/> },
      { path: "speciality/add/:id", element: <SpecialityAdd/> },
      { path: "speciality", element: <Specialty/> },
      { path: "document", element: <Documentverification/> },
      { path: "account", element: <Accountverification/> },
      { path: "liveVendor", element: <LiveVendor/> },
      { path: "restaurantView/:id", element: <ViewRestuarant/> },
      { path: "vendorView/:id", element: <ViewLiveRestuarant/> },
      { path: "supportView/:id", element: <ViewSupport/> },
      { path: "documentView/:id", element: <ViewDocument/> },
      { path: "bagsize", element: <SurpriceBagSize/> },
      { path: "bagsize/add", element: <SurpriseBagAdd/> },
      { path: "bagsize/add/:id", element: <SurpriseBagAdd/> },
      { path: "type", element: <RestaurantType/> },
      { path: "type/add", element: <RestaurantTypeAdd/> },
      { path: "type/add/:id", element: <RestaurantTypeAdd/> },
      { path: "orderlist", element: <Orderlist/> },
      { path: "billinglist", element: <Billinglist/> },
      { path: "faq/add", element: <AddFaq/> },
      { path: "faq/add/:id", element: <AddFaq/> },
      { path: "faq", element: <FaqList/> },
      { path: "support", element: <SupportList/> },
      { path: "privacy", element: <Privacy/> },
      { path: "terms", element: <TermsConditions/> },
      { path: "setting", element: <SettingList/> },
      { path: "setting/add/:id", element: <AddSetting/> },
      { path: "setting/add", element: <AddSetting/> },
      { path: "createorderlist/:id", element: <CreateOrderList/> },
      {path: "users" , element:<User/>},
      { path: "userView/:id", element: <ViewUser/> },
      {path:"bank" ,element:<BankList/>},
      { path: "bank/add", element: <AddBank/> },
      { path: "bank/add/:id", element: <AddBank/> },
      { path: "adminusers", element: <Adminuser/> },
      { path: "userRole", element: <Adminrole/> },
      { path: "permission", element: <Permission/> },
                      
    ]
  },
]);

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <RouterProvider router={router}>
    <App/>
    </RouterProvider>
    <ToastContainer/>
  </Provider>,
)
