import {
  CButton,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormSelect,
  CRow,
} from "@coreui/react";
import { useFormik } from "formik";
import ToastMessage from "../utils/ToastMessage";
import {
  document_statusUpdate_api,
  restauranttypelist_api,
} from "../API/baseUrl";
import {
  accountStatusRequest,
  removeDataFromReducer,
  restauranttypeListRequest,
} from "../redux/actions";
import { Checkbox, FormControlLabel } from "@mui/material";
import { rejectStatusShema } from "../utils/schema";

const Intial = {
  reasonOfRejection: "",
};

const DocumentStatusModal = ({
  visible,
  setVisible,
  restaurantId,
  statusId,
  setStatusvalue,
}) => {
  const dispatch = useDispatch();
  const [typelist, setTypelist] = useState([]);
  const { TypeListdata } = useSelector(
    (state) => state?.restauranttypeListreduser || {}
  );
  const { accountStatusdata } = useSelector(
    (state) => state?.accountStatusReduser || {}
  );
  const [rejectItems, setRejectItems] = useState([]);

  console.log("wwww", accountStatusdata);
  const {
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: Intial,
    validationSchema: statusId == 2 ? rejectStatusShema : "",
    onSubmit: (values) => ApiHandlesubmit(),
  });

  const handleselectchange = (e) => {
    values.restaurantType = e.target.value;
  };

  const handleSubmitform = (e) => {
    e.preventDefault();
    handleSubmit();
    console.log("error", errors);
    console.log("values", values);
  };

  useEffect(() => {
    if (TypeListdata?.status) {
      const newType = TypeListdata?.data?.map((item) => ({
        label: item.type,
        value: item.restaurantTypeIdFk,
      }));
      setTypelist(newType);
    } else {
      ToastMessage("error", TypeListdata?.message);
    }
  }, [TypeListdata]);

  useEffect(() => {
    if (accountStatusdata?.status) {
      setStatusvalue(statusId);
      setVisible(false);
      ToastMessage("success", accountStatusdata?.message);
      dispatch(removeDataFromReducer("ACCOUNT_STATUS_RESET"));
    } else {
      ToastMessage("error", accountStatusdata?.message);
    }
  }, [accountStatusdata]);

  useEffect(() => {
    const parameter = {
      url: restauranttypelist_api,
    };
    dispatch(restauranttypeListRequest(parameter));
  }, []);

  const ApiHandlesubmit = async () => {
    console.log("values", values);
    const parameter = {
      restaurantIdFk: restaurantId,
      documentStatus: statusId,
      reasonOfRejection: values.reasonOfRejection,
      rejectItems: JSON.stringify(rejectItems),
      url: document_statusUpdate_api,
    };
    dispatch(accountStatusRequest(parameter));
  };

  // const handleCheckboxChange = (e , type) =>{

  // }

  const handleCheckboxChange = (e, type) => {
    if (e.target.checked) {
      setRejectItems([...rejectItems, { rejectItem: type }]);
    } else {
      setRejectItems(rejectItems.filter((item) => item.rejectItem !== type));
    }
  };

  console.log("rejectItems", rejectItems);
  // "restaurantIdFk": 0,
  // "documentStatus": 0,
  // "reasonOfRejection": "string"

  return (
    <div>
      <CModal
        backdrop="static"
        visible={visible}
        alignment="center"
        onClose={() => setVisible(false)}
        aria-labelledby="StaticBackdropExampleLabel"
      >
        <CModalTitle id="StaticBackdropExampleLabel" style={{ padding: '4px 31px' }} className="mt-3">
          Update Document Status
        </CModalTitle>

        <CModalBody>
          <CForm onSubmit={handleSubmitform}>
            <CContainer>
              <CRow xs={{ gutter: 2 }}>
                {statusId == 0 || statusId == 1 ? (
                  <p>Are you sure you want to change the status ?</p>
                ) : (
                  ""
                )}

                {statusId == 2 ? (
                  <>
                    <CCol sm={12} md={12}>
                      <CFormTextarea
                        id="exampleFormControlTextarea1"
                        name="reasonOfRejection"
                        type="text"
                        value={values.reasonOfRejection}
                        label="Reason Of Rejection"
                        rows={3}
                        placeholder="Reason Of Rejection"
                        text={errors.reasonOfRejection}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ></CFormTextarea>
                      {touched.reasonOfRejection &&
                        errors.reasonOfRejection && (
                          <div className="invalid-feedback">
                            {errors.reasonOfRejection}
                          </div>
                        )}
                    </CCol>

                    <p>If you want the documents to be re-uploaded, please select the checkbox. If you are rejecting the account, please update it directly with the reason for rejection.</p>

                    <div className="formcontrol-div">
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              color: "#2a666b",
                              "&.Mui-checked": {
                                color: "#2a666b",
                              },
                            }}
                            value="5"
                            // checked={selectedValues.includes(item.specialty)}
                            onChange={(e) => handleCheckboxChange(e, "bank")}
                          />
                        }
                        label="Bank Details"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              color: "#2a666b",
                              "&.Mui-checked": {
                                color: "#2a666b",
                              },
                            }}
                            value="4"
                            // checked={selectedValues.includes(item.specialty)}
                            onChange={(e) =>
                              handleCheckboxChange(e, "fssaiLicence")
                            }
                          />
                        }
                        label="Fssai Licence"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              color: "#2a666b",
                              "&.Mui-checked": {
                                color: "#2a666b",
                              },
                            }}
                            value="2"
                            // checked={selectedValues.includes(item.specialty)}
                            onChange={(e) =>
                              handleCheckboxChange(e, "regularGstIn")
                            }
                          />
                        }
                        label="Regular Gstin"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              color: "#2a666b",
                              "&.Mui-checked": {
                                color: "#2a666b",
                              },
                            }}
                            value="3"
                            // checked={selectedValues.includes(item.specialty)}
                            onChange={(e) => handleCheckboxChange(e, "panCard")}
                          />
                        }
                        label="PanCard"
                      />



                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              color: "#2a666b",
                              "&.Mui-checked": {
                                color: "#2a666b",
                              },
                            }}
                            value="4"
                            // checked={selectedValues.includes(item.specialty)}
                            onChange={(e) =>
                              handleCheckboxChange(e, "foodImage")
                            }
                          />
                        }
                        label="Food Image"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              color: "#2a666b",
                              "&.Mui-checked": {
                                color: "#2a666b",
                              },
                            }}
                            value="4"
                            // checked={selectedValues.includes(item.specialty)}
                            onChange={(e) =>
                              handleCheckboxChange(e, "restaurantLogo")
                            }
                          />
                        }
                        label="Restaurant Logo"
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </CRow>
            </CContainer>

            <CModalFooter className="modalFooter">
              <CButton color="secondary" onClick={() => setVisible(false)}>
                Close
              </CButton>
              <CButton type="submit" className="btn_color">
                Update
              </CButton>
            </CModalFooter>
          </CForm>
        </CModalBody>
      </CModal>
    </div>
  );
};

export default DocumentStatusModal;
