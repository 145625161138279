import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormSelect,
  CRow,
} from "@coreui/react";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  countryactivelist,
  createstate_api,
  editstateById_api,
  getstateById_api,
  setting_create_api,
  setting_edit_api,
  setting_getbyId_api,
} from "../../API/baseUrl";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  activecountrylistRequest,
  addstateRequest,
  editstatebyidRequest,
  getstatebyidRequest,
  removeDataFromReducer,
} from "../../redux/actions";
import ToastMessage from "../../utils/ToastMessage";
import {
  Settingaddshema,
  Settingeditshema,
  stateaddshema,
} from "../../utils/schema";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

const StateAdd = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { addstatedata } = useSelector((state) => state?.stateaddreduser || {});
  const { editStatedata } = useSelector(
    (state) => state?.EditStateReduser || {}
  );
  const { getstatedata } = useSelector((state) => state?.getStateReduser || {});

  const Intial = {
    type: "",
    data: "",
    description: "",
  };

  const {
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: Intial,
    validationSchema: id ? Settingeditshema : Settingaddshema,
    onSubmit: (values) => ApiHandlesubmit(),
  });

  useEffect(() => {
    if (getstatedata?.status) {
      setFieldValue("type", getstatedata?.data?.settingType);
      setFieldValue("data", getstatedata?.data?.settingData);
      setFieldValue("description", getstatedata?.data?.settingDescription);
    }
  }, [getstatedata]);

  useEffect(() => {
    const parameter = {
      url: `${setting_getbyId_api}${id}`,
    };
    dispatch(getstatebyidRequest(parameter));
  }, [id]);

  useEffect(() => {
    if (addstatedata?.status) {
      setLoading(false);
      ToastMessage("success", addstatedata?.message);
      navigate("/dashboard/setting");
      dispatch(removeDataFromReducer("ADD_STATE_RESET"));
    } else {
      ToastMessage("error", addstatedata?.message);
      setLoading(false);
    }
  }, [addstatedata]);

  useEffect(() => {
    if (editStatedata?.status) {
      setLoading(false);
      ToastMessage("success", editStatedata?.message);
      navigate("/dashboard/setting");
      dispatch(removeDataFromReducer("STATE_EDIT_RESET"));
      dispatch(removeDataFromReducer("STATE_DETAILS_RESET"));
    } else {
      ToastMessage("error", editStatedata?.message);
      setLoading(false);
    }
  }, [editStatedata]);

  const handleSubmit2 = (e) => {
    e.preventDefault();
    handleSubmit();
    console.log("error", errors);
    console.log("values", values);
  };

  const handleselectchange = (e) => {
    values.type = e.target.value;
    setFieldValue("type", e.target.value);
  };

  const ApiHandlesubmit = async () => {
    setLoading(true);
    const parameter = {
      type: values.type,
      data: values.data,
      description: values.description,
      url: setting_create_api,
    };
    const editparameter = {
      data: values.data,
      description: values.description,
      url: `${setting_edit_api}${id}`,
    };
    if (id) {
      dispatch(editstatebyidRequest(editparameter));
    } else {
      dispatch(addstateRequest(parameter));
    }
  };

  const settingtype = [
    {
      id: 1,
      type: "gst",
    },
    {
      id: 2,
      type: "platform fee",
    },
    {
      id: 3,
      type: "platform fee gst",
    },
    {
      id: 4,
      type: "vendor gst",
    },
    {
      id: 5,
      type: "vendor comission",
    },
    {
      id: 6,
      type: "endDate",
    },
    {
      id: 7,
      type: "razorPay gst",
    },
    {
      id: 8,
      type: "razorPay comission",
    },
  ];
  const handleBackNavigation = () => {
    navigate(-1); 
  }; 
  return (
    <CForm onSubmit={handleSubmit2}>
      <h3 className="AddEdit_Heading" ><ArrowBackIcon onClick={handleBackNavigation} style={{cursor:"pointer"}}/>{id ? "Edit Setting" : "Add Setting"}</h3>

      <CContainer className="add_form_container">
        <CRow xs={{ gutter: 2 }}>
          <CCol sm={12} md={12}>
            <CFormSelect
              disabled
              name="type"
              label="Setting Type*"
              placeholder="Setting Type"
              value={values.type}
              // text={errors.type}
              invalid={touched.type && !!errors.type}
              onChange={handleselectchange}
              onBlur={handleBlur}
              // className="field-width"
            >
              <option value="" disabled selected>
                Select a type
              </option>
              {settingtype.map((setting, index) => (
                <option key={index} value={setting.type}>
                  {setting.type}
                </option>
              ))}
            </CFormSelect>
            {touched.type && errors.type && (
              <div className="invalid-feedback">{errors.type}</div>
            )}
          </CCol>
      
          {/* <FormControl>
            <div style={{ marginTop: "10px" }}>Discount Type*</div>
            <RadioGroup
              sx={{ flexDirection: "row", gap: "25px" }}
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="discountType"
              value={values.discountType}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <FormControlLabel
                value="%"
                control={<Radio size="small" />}
                label="%"
              />
              <FormControlLabel
                value="₹"
                control={<Radio size="small" />}
                label="₹"
              />
              <FormControlLabel
                value="period"
                control={<Radio size="small" />}
                label="Days"
              />
            </RadioGroup>
          </FormControl> */}

          <CCol sm={12} md={12}>
            <CFormInput
              name="data"
              type="text"
              value={values.data}
              id="exampleFormControlInput1"
              // label="Value*"
              label={getstatedata?.data?.settingDataType ? `Value* (in ${getstatedata?.data?.settingDataType})` : "Value*"}
              placeholder="Value"
              // text={errors.data}
              invalid={touched.data && !!errors.data}
              onChange={handleChange}
              onBlur={handleBlur}
              aria-describedby="exampleFormControlInputHelpInline"
              // className="field-width"
            />
            {touched.data && errors.data && (
              <div className="invalid-feedback">{errors.data}</div>
            )}
          </CCol>
          <CCol sm={12} md={12}>
            <CFormInput
              name="description"
              type="text"
              value={values.description}
              id="exampleFormControlInput1"
              label="Description*"
              placeholder="Description"
              // text={errors.data}
              invalid={touched.description && !!errors.description}
              onChange={handleChange}
              onBlur={handleBlur}
              aria-describedby="exampleFormControlInputHelpInline"
              // className="field-width"
            />
            {touched.description && errors.description && (
              <div className="invalid-feedback">{errors.description}</div>
            )}
          </CCol>
        </CRow>

        <div className="d-grid gap-2 d-md-flex justify-content-md-end py-5">
          <CButton
            style={{
              backgroundColor: "var(--colorused)",
              border: "none",
              width: "120px",
              color: "var(--colorWhite)",
            }}
            type="submit"
            className="me-md-2"
          >
            {loading ? (
              <CircularProgress size={24} style={{ color: "white" }} />
            ) : (
              <>{id ? "Update" : "Submit"}</>
            )}
          </CButton>
        </div>
      </CContainer>
    </CForm>
  );
};

export default StateAdd;
