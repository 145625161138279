import React, { useEffect, useState } from 'react'
import { AppContent, AppSidebar, AppFooter, AppHeader } from '../components/index'
import { Outlet, useNavigate } from 'react-router-dom';
import "./index.css"

const DefaultLayout = () => {
  const navigate = useNavigate();
  const [sidebarShow , setSidebarShow] = useState(true)

  useEffect(() => {
    const auth = localStorage.getItem("auth");
    if (auth) {
      <Outlet/>
    } else {
      navigate("/");
    }
  }, []);



  return (
    <div>
      <AppSidebar {...{sidebarShow , setSidebarShow}} />
      <div className="wrapper d-flex flex-column min-vh-100">
        <AppHeader {...{sidebarShow , setSidebarShow}} />
        <div className="body flex-grow-1 outletcontainer">
          {/* <AppContent/> */}
          <Outlet/>
        </div>
        {/* <AppFooter /> */}
      </div>
    </div>
  )
}

export default DefaultLayout
