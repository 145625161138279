import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import {
  CRow,
  CCol,
  CDropdown,
  CDropdownMenu,
  CDropdownItem,
  CDropdownToggle,
  CWidgetStatsA,
} from '@coreui/react'
import { getStyle } from '@coreui/utils'
import { CChartBar, CChartLine } from '@coreui/react-chartjs'
import CIcon from '@coreui/icons-react'
import { cilArrowBottom, cilArrowTop, cilOptions } from '@coreui/icons'

const WidgetsDropdown = (props) => {
  const widgetChartRef1 = useRef(null)
  const widgetChartRef2 = useRef(null)

  useEffect(() => {
    document.documentElement.addEventListener('ColorSchemeChange', () => {
      if (widgetChartRef1.current) {
        setTimeout(() => {
          widgetChartRef1.current.data.datasets[0].pointBackgroundColor = getStyle('--cui-primary')
          widgetChartRef1.current.update()
        })
      }

      if (widgetChartRef2.current) {
        setTimeout(() => {
          widgetChartRef2.current.data.datasets[0].pointBackgroundColor = getStyle('--cui-info')
          widgetChartRef2.current.update()
        })
      }
    })
  }, [widgetChartRef1, widgetChartRef2])

  const magicBagRevenueArray = props.data?.magic_bag_revenue_Array || [];
  const magicBagPurchaseArray = props.data?.magic_bag_purchase_Array || [];
  const magicBagcommissonArray = props.data?.magic_bag_commission_Array || [];


  return (
   

    <CRow className={props.className} xs={{ gutter: 4 }}>
    <CCol sm={6} xl={3} xxl={3}> {/* Adjust width to fit all in one row */}
      <CWidgetStatsA
        style={{ height: "115px", backgroundColor: `var(--colorused)`, color: "#ffffff", borderRadius: "25px" }}
        value={<> ₹ {props.data?.magic_bag_details?.Total_Revenue}</>}
        title="Revenue"
        // chart={
        //   <CChartBar
        //     className="mt-3 mx-3"
        //     style={{ height: '70px' }}
        //     data={{
        //       labels: props.data?.X_Axis_Array,
        //       datasets: [
        //         {
        //           label: 'Revenue',
        //           backgroundColor: 'rgba(255,255,255,.2)',
        //           borderColor: 'rgba(255,255,255,.55)',
        //           data: magicBagRevenueArray,
        //           barPercentage: 0.6,
        //         },
        //       ],
        //     }}
        //     options={{
        //       maintainAspectRatio: false,
        //       plugins: { legend: { display: false } },
        //       scales: {
        //         x: {
        //           grid: { display: false, drawTicks: false },
        //           ticks: { display: false },
        //         },
        //         y: {
        //           border: { display: false },
        //           grid: { display: false, drawBorder: false, drawTicks: false },
        //           ticks: { display: false },
        //         },
        //       },
        //     }}
        //   />
        // }
      />
    </CCol>
  
    <CCol sm={6} xl={3} xxl={3}>
      <CWidgetStatsA
        style={{ height: "115px", backgroundColor: `var(--colorused)`, color: "#ffffff", borderRadius: "25px" }}
        value={<>{props.data?.magic_bag_details?.Total_Purchase}</>}
        title="Magic Bag Sold"
        // chart={
        //   <CChartLine
        //     className="mt-3 mx-3"
        //     style={{ height: '70px' }}
        //     data={{
        //       labels: props.data?.X_Axis_Array,
        //       datasets: [
        //         {
        //           label: 'Magic Bag Sold',
        //           backgroundColor: 'transparent',
        //           borderColor: 'rgba(255,255,255,.55)',
        //           pointBackgroundColor: getStyle('--cui-info'),
        //           data: magicBagPurchaseArray,
        //         },
        //       ],
        //     }}
        //     options={{
        //       plugins: { legend: { display: false } },
        //       maintainAspectRatio: false,
        //       scales: {
        //         x: { grid: { display: false, drawBorder: false }, ticks: { display: false } },
        //         y: { min: -9, max: 39, display: false, grid: { display: false }, ticks: { display: false } },
        //       },
        //       elements: {
        //         line: { borderWidth: 1 },
        //         point: { radius: 4, hitRadius: 10, hoverRadius: 4 },
        //       },
        //     }}
        //   />
        // }
      />
    </CCol>
  
    <CCol sm={6} xl={3} xxl={3}>
      <CWidgetStatsA
        style={{ height: "115px", backgroundColor: `var(--colorused)`, color: "#ffffff", borderRadius: "25px" }}
        value={<>₹ {props.data?.magic_bag_details?.Total_Commision}</>}
        title="Commission"
        // chart={
        //   <CChartBar
        //     className="mt-3 mx-3"
        //     style={{ height: '70px' }}
        //     data={{
        //       labels: props.data?.X_Axis_Array,
        //       datasets: [
        //         {
        //           label: 'Commission',
        //           backgroundColor: 'rgba(255,255,255,.2)',
        //           borderColor: 'rgba(255,255,255,.55)',
        //           data: magicBagcommissonArray,
        //           barPercentage: 0.6,
        //         },
        //       ],
        //     }}
        //     options={{
        //       maintainAspectRatio: false,
        //       plugins: { legend: { display: false } },
        //       scales: {
        //         x: { grid: { display: false, drawTicks: false }, ticks: { display: false } },
        //         y: { border: { display: false }, grid: { display: false, drawTicks: false }, ticks: { display: false } },
        //       },
        //     }}
        //   />
        // }
      />
    </CCol>
  

    <CCol sm={6} xl={3} xxl={3}>
      <CWidgetStatsA
        style={{ height: "115px", backgroundColor: `var(--colorused)`, color: "#ffffff", borderRadius: "25px" }}
        value={<>₹ {props.data?.magic_bag_details?.Total_PlatformFee}</>}
        title="Platform Fee (Including GST)"
        // chart={
        //   <CChartBar
        //     className="mt-3 mx-3"
        //     style={{ height: '70px' }}
        //     data={{
        //       labels: props.data?.X_Axis_Array,
        //       datasets: [
        //         {
        //           label: 'Commission',
        //           backgroundColor: 'rgba(255,255,255,.2)',
        //           borderColor: 'rgba(255,255,255,.55)',
        //           data: magicBagcommissonArray,
        //           barPercentage: 0.6,
        //         },
        //       ],
        //     }}
        //     options={{
        //       maintainAspectRatio: false,
        //       plugins: { legend: { display: false } },
        //       scales: {
        //         x: { grid: { display: false, drawTicks: false }, ticks: { display: false } },
        //         y: { border: { display: false }, grid: { display: false, drawTicks: false }, ticks: { display: false } },
        //       },
        //     }}
        //   />
        // }
      />
    </CCol>
  
    <CCol sm={6} xl={3} xxl={3}>
      <CWidgetStatsA
        style={{ height: "115px", backgroundColor: `var(--colorused)`, color: "#ffffff", borderRadius: "25px" }}
        value={<>{props.data?.totalActiveRestaurantCount}</>}
        title="New Vendors"
      />
    </CCol>
  
    <CCol sm={6} xl={3} xxl={3}>
      <CWidgetStatsA
        style={{ height: "115px", backgroundColor: `var(--colorused)`, color: "#ffffff", borderRadius: "25px" }}
        value={<>{props.data?.totalUserCount}</>}
        title="New Users"
      />
    </CCol>
  </CRow>
  
  )
}

WidgetsDropdown.propTypes = {
  className: PropTypes.string,
  withCharts: PropTypes.bool,
}



export default WidgetsDropdown
