import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ToastMessage from "../../utils/ToastMessage";
import DataTable from "react-data-table-component";
import { CButton,CRow,CCol } from "@coreui/react";
import { useNavigate } from "react-router-dom";
import { document_list_api, vendorList_api } from "../../API/baseUrl";
import { documentListRequest, vendorList } from "../../redux/actions";
import moment from 'moment/moment';
import StatusDropdown from "./statusDropdown";
import ClearIcon from '@mui/icons-material/Clear';
import "./index.css"
import { TableSk } from "../skelaton/tableSk";
import { VendorAccountsTitle, VendorDocumentsTitle } from "../Title";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";


const Documentverification = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [pending, setPending] = useState(true);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [sortBy, setSortBy] = useState("");
    const [sortByColumnName, setSortByColumnName] = useState("");
    const dispatch = useDispatch();
    const { documentListdata } = useSelector((state) => state?.documentListReduser || {});

    console.log("vendorListInfo",documentListdata)
    useEffect(() => {

      document.title = VendorDocumentsTitle;
    }, []);
  

const handleView = (id , status) =>{
  if(status == 0){
    ToastMessage("error", "documents are not uploaded yet");
  }
  else{
    navigate(`/dashboard/documentView/${id}`);
  }
}
const customStyles = {
  headCells: {
      style: {
          fontSize: "16px",
          fontWeight: 500,
      },
  },
};


      const handlePerRowsChange = async (newLimit, offset) => {
        setOffset(offset);
        setLimit(newLimit);
      };
    
      const handlePageChange = (offset) => {
        setOffset(offset);
      };
    
      const handleSort = async (column, sortDirection) => {
        setSortBy(sortDirection);
        setSortByColumnName(column.column_name || "");
      };

      const handleclearSearch =  () => {
        setSearchText("");
      };

      const columns = [
        {
          name: "Restaurant Id",
          selector: (row) => <p className="id_navigate" onClick={() =>  handleView (row?.restaurantIdFK , row?.isDocumentUploaded)}>{row?.restaurantID ? row.restaurantID : "---"}</p>,
          sortable: true,
          column_name: "restaurantIdFK",
          reorder: true,
          width: "130px",
        },
        // {
        //   name: "Document Id",
        //   selector: (row) => row.restaurantDocumentIdFK ? row.restaurantDocumentIdFK :"---",
        //   sortable: true,
        //   column_name: "restaurantDocumentIdFK",
        //   reorder: true,
        // },
        
        // {
        //   name: "Restaurant Id",
        //   selector: (row) => row.restaurantIdFK ? row.restaurantIdFK :"---",
        //   sortable: true,
        //   column_name: "restaurantIdFK",
        //   reorder: true,
        // },
        {
          name: "Restaurant Name",
          selector: (row) => row.restaurantName  ? row.restaurantName : "---",
          sortable: true,
          column_name: "restaurantName",
          reorder: true,
          width: "180px",
        }, 
        {
          name: "Restaurant Email",
          selector: (row) => row.vendorEmail  ? row.vendorEmail : "---",
          // sortable: true,
          column_name: "vendorEmail",
          reorder: true,
          width: "200px",
        }, 
        {
          name: "Restaurant Mobile Number",
          selector: (row) => row.restaurantMobileNumber ?  <p className="margin-0">{row.restaurantCountryCode} {row.restaurantMobileNumber}</p> : "---",
          // sortable: true,
          column_name: "restaurantMobileNumber",
          reorder: true,
          width: "250px",
        }, 
        {
          name: "Restaurant Landline Number",
          selector: (row) => row.restaurantLandlineNumber ?  <p className="margin-0">{row.restaurantLandlineNumberCountryCode} {row.restaurantLandlineNumber}</p> : "---",
          // sortable: true,
          column_name: "restaurantLandlineNumber",
          reorder: true,
          width: "250px",
        }, 
        {
          name: "Restaurant Owner Name",
          selector: (row) => row.restaurantOwnerName  ? row.restaurantOwnerName : "---",
          sortable: true,
          column_name: "restaurantOwnerName",
          reorder: true,
          width: "250px",
        }, 
        {
          name: "Restaurant Owner Email",
          selector: (row) => row.restaurantOwnerEmail  ? row.restaurantOwnerEmail : "---",
          // sortable: true,
          column_name: "restaurantOwnerEmail",
          reorder: true,
          width: "250px",
        }, 
        {
          name: " Owner Mobile Number",
          selector: (row) => row.restaurantOwnerMobileNumber ?  <p className="margin-0">{row.restaurantOwnerCountryCode} {row.restaurantOwnerMobileNumber}</p> : "---",
          // sortable: true,
          column_name: "restaurantOwnerMobileNumber",
          reorder: true,
          width: "180px",
        }, 
       
        {
          name: "Restaurant Address",
          selector: (row) => row.restaurantAddress  ? row.restaurantAddress : "---",
          // sortable: true,
          column_name: "restaurantAddress",
          reorder: true,
          width: "180px",
        },
       
        {
          name: 'Created Date',
          selector: row => row.restaurantCreatedAt ,
          format: (row) =>row.restaurantCreatedAt ? moment(row.restaurantCreatedAt).format('MMMM Do YYYY') :"---",
          sortable: true,
          column_name: 'restaurantCreatedAt',
          reorder: true,
          width: "150px",
        },
        {
          name: 'Uploaded Date',
          selector: row => row.documentUploadedDateAndTime ,
          format: (row) =>row.documentUploadedDateAndTime ? moment(row.documentUploadedDateAndTime).format('MMMM Do YYYY') :"---",
          sortable: true,
          column_name: 'documentUploadedDateAndTime',
          reorder: true,
          width: "150px",
        },
        {
          name: "Status",
          selector: (row) => row.documentStatus === 0 ? "Pending" : row.documentStatus === 1 ? "Accepted" : "Rejected",
          sortable: true,
          column_name: "documentStatus",
          reorder: true,
        }, 
        //  {
        //   name: "Document Status",
        //   selector: (row) => <StatusDropdown/>,
        //   sortable: true,
        //   column_name: "",
        //   reorder: true,
        // },
        
      ];


      useEffect(() => {
        if (documentListdata?.status) {
          setData(documentListdata?.data);
          setTotalRows(documentListdata?.count);
          setPending(false);
        } else {
          ToastMessage("error", documentListdata?.message);
        }
      }, [documentListdata]);

      
    
      useEffect(() => {
        const parameter = {
          offset: offset,
          limit: limit,
          search: searchText,
          columnName: sortByColumnName,
          sortBy: sortBy.toUpperCase(),
          url: document_list_api,
        };
        dispatch(documentListRequest(parameter));
      }, [offset, limit, searchText, sortByColumnName, sortBy]);

  
  return (
    <div>



<div>
        <CRow>
          <CCol className="d-grid gap-2 d-md-flex justify-content-md-start pb-5"
           lg={9}
           md={9}
           sm={8}
           xs={12}>
            <h3>Vendor Documents</h3>
          </CCol>
          <CCol className="d-grid gap-2 d-md-flex justify-content-md-center pb-5"
             lg={3}
             md={3}
             sm={3}
             xs={7}>
              <div className="search-feild">
            <input
              type="text"
              placeholder="Search"
              
              style={{ outline: 'none' }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            {searchText ? <ClearIcon onClick={handleclearSearch} sx={{color:`var(--colorused)` , cursor:"pointer", fontSize:"20px"}} /> : ""}
            
            </div>
          </CCol>
        </CRow>
      </div>

    <DataTable
      customStyles={customStyles}
      columns={columns}
      data={data}
      dataAlign="center"
      fixedHeader
      pagination
      paginationServer
      paginationTotalRows={totalRows}
      onChangeRowsPerPage={handlePerRowsChange}
      progressComponent={<LoaderAnimation/>}
      progressPending={pending}
      onChangePage={handlePageChange}
      fixedHeaderScrollHeight="58vh"
      sortServer
      responsive
      onSort={handleSort}
    />
  </div>
  );
};

export default Documentverification;
