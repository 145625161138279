import React from 'react'
import Lottie from "lottie-react";
import animationData from "../animations/animation_1.json";
import "./index.css";
import { CSpinner } from '@coreui/react';
import { Player } from '@lottiefiles/react-lottie-player';
import loaderGif from "../../assets/images/logo-2-ezgif.com-optimize-new.gif"

const LoaderAnimation = () => {

  console.log(animationData, "lklopoiuyhjoplkiok");

  return (
    <div style={styles.loaderContainer}>
      {/* <Lottie
        animationData={animationData}
        loop={true}
        style={styles.animationStyle}
      /> */}

      {/* <Player
        src={animationData}
        className="player"
        loop={true}
        style={styles.animationStyle}
      /> */}

      {/* <CSpinner color="success" variant="grow" /> */}
      <img src={loaderGif} alt="loading" height={250} width={250} />
    </div>

  )
}

const styles = {
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "75vh",
    // background: "#f8f8f8", // Optional background color
  },
  animationStyle: {
    height: 400,
    width: 400,
  },
};

export default LoaderAnimation;




// import React from "react";
// import { Lottie } from "lottie-react"; // Import Lottie component
// import animationData from "./manifest.json"; // Import your Lottie animation JSON file

// const LoaderAnimation = () => {
//   return (
//     <div style={styles.loaderContainer}>
//       <Lottie
//         animationData={animationData}
//         loop={true}
//         style={styles.animationStyle}
//       />
//     </div>
//   );
// };

// const styles = {
//   loaderContainer: {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     height: "100vh",
//     background: "#f8f8f8", // Optional background color
//   },
//   animationStyle: {
//     height: 200,
//     width: 200,
//   },
// };

// export default LoaderAnimation;