// import React, { useEffect, useRef, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import "./index.css";
// import {
//   supportChatgETAction,
//   supportChatSendAction,
// } from "../../../redux/actions";
// import ToastMessage from "../../../utils/ToastMessage";
// import {
//   GetTicketChatData_api,
//   TicketChatData_api,
// } from "../../../API/baseUrl";
// import { Avatar } from "@mui/material";
// import SendIcon from '@mui/icons-material/Send';
// import CloseIcon from '@mui/icons-material/Close';
// const ChatBox = ({ supportTicketIdFk, ticketId, onClose }) => {
//   const [TicketChatData, setTicketChatData] = useState({});
//   const [messageContent, setMessageContent] = useState(""); // State to store the input value
//   const [ChatGetData, setGetChatData] = useState([]);
//   const dispatch = useDispatch();
//   const { chatsenddata } = useSelector((state) => state?.chatSendReduser || {});
//   const { getChatdata } = useSelector((state) => state?.chatGetReduser || {});
//   const chatMessagesEndRef = useRef(null);
//   useEffect(() => {
//     chatMessagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
//   }, [ChatGetData]);

//   // *************Post API**********
//   useEffect(() => {

//     if (chatsenddata?.status) {
//       console.log("Ticket data:", chatsenddata?.data);
//       GetChatData();
//       setTicketChatData(chatsenddata?.data);
//     } else {
//       ToastMessage("error", chatsenddata?.message);
//     }
//   }, [chatsenddata]);

//   const ChatData = () => {
//     GetChatData();
//     if (messageContent.trim() === "") {
//       ToastMessage("error", "Message cannot be empty");
//       return;
//     }
//     const parameter = {
//       supportTicketIdFk: supportTicketIdFk,
//       from: "admin",
//       fromType: "admin",
//       ticketId: ticketId,
//       content: messageContent,
//       type: "text",
//       url: TicketChatData_api,
//     };

//     dispatch(supportChatSendAction(parameter));
//     setMessageContent("");
//   };

//   const handleInputChange = (e) => {
//     setMessageContent(e.target.value);
//   };

//   const handleKeyPress = (e) => {
//     if (e.key === "Enter") {
//       ChatData();
//     }
//   };

//   // **************Get Chat************
//   useEffect(() => {
//     if (getChatdata?.status) {
//       console.log("Ticket data:", getChatdata?.chatData);
//       setGetChatData(getChatdata?.chatData);
//     } else {
//       ToastMessage("error", getChatdata?.message);
//     }
//   }, [getChatdata]);

//   const GetChatData = () => {
//     const parameter = {
//       ticketId: ticketId,
//       url: GetTicketChatData_api,
//     };

//     dispatch(supportChatgETAction(parameter));
//     setMessageContent("");
//   };

//   useEffect(() => {
//     GetChatData();
//   }, []);
//   return (
//     <div className='chat-room'>
//       <div className="crossbtn">
//         < CloseIcon onClick={onClose} className="CrossIcon"
//         /></div>
//       <div className="chat-messages">
//         {ChatGetData?.map((TicketChatData, index) => {
//           const messageClass =
//             TicketChatData?.chatFromType === "admin"
//               ? "admin-message"
//               : "vendor-message";

//           return (
//             <div key={index} className={`message-container ${messageClass}`}>

//               <div
//                 className='d-flex  gap-2'
//               >

//                 <Avatar
//                   alt={TicketChatData?.chatFromType}
//                   src="/static/images/avatar/1.jpg"
//                 />
//                 <span
//                   className="ml-2"
//                   style={{
//                     marginLeft: "3px",
//                   }}
//                 >
//                   {" "}
//                   {TicketChatData?.chatContent}
//                 </span>
//               </div>
//             </div>
//           );
//         })}
//         <div ref={chatMessagesEndRef} />
//       </div>

//       <div className="chatinput">
//         <input
//           type="text"
//           value={messageContent}
//           onChange={handleInputChange}
//           onKeyPress={handleKeyPress}
//           placeholder="Type a Message "
//           className="message_box_input"
//         />
//         <button type="submit" onClick={ChatData} className="send-button" >
//           <SendIcon />
//         </button>
//       </div>
//     </div>
//   );
// };


// export default ChatBox;

// **********

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  supportChatgETAction,
  supportChatSendAction,
} from "../../../redux/actions";
import ToastMessage from "../../../utils/ToastMessage";
import {
  GetTicketChatData_api,
  TicketChatData_api,
} from "../../../API/baseUrl";
import { Avatar, Box, Paper, IconButton, TextField, Typography } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';

const ChatBox = ({ supportTicketIdFk, ticketId, onClose }) => {
  const [TicketChatData, setTicketChatData] = useState({});
  const [messageContent, setMessageContent] = useState("");
  const [ChatGetData, setGetChatData] = useState([]);
  const dispatch = useDispatch();
  const { chatsenddata } = useSelector((state) => state?.chatSendReduser || {});
  const { getChatdata } = useSelector((state) => state?.chatGetReduser || {});
  const chatMessagesEndRef = useRef(null);

  useEffect(() => {
    chatMessagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [ChatGetData]);

  useEffect(() => {
    if (chatsenddata?.status) {
      GetChatData();
      setTicketChatData(chatsenddata?.data);
    } else {
      ToastMessage("error", chatsenddata?.message);
    }
  }, [chatsenddata]);

  const ChatData = () => {
    GetChatData();
    if (messageContent.trim() === "") {
      ToastMessage("error", "Message cannot be empty");
      return;
    }
    const parameter = {
      supportTicketIdFk: supportTicketIdFk,
      from: "admin",
      fromType: "admin",
      ticketId: ticketId,
      content: messageContent,
      type: "text",
      url: TicketChatData_api,
    };

    dispatch(supportChatSendAction(parameter));
    setMessageContent("");
  };

  const handleInputChange = (e) => setMessageContent(e.target.value);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      ChatData();
    }
  };

  useEffect(() => {
    if (getChatdata?.status) {
      setGetChatData(getChatdata?.chatData);
    } else {
      ToastMessage("error", getChatdata?.message);
    }
  }, [getChatdata]);

  const GetChatData = () => {
    const parameter = {
      ticketId: ticketId,
      url: GetTicketChatData_api,
    };

    dispatch(supportChatgETAction(parameter));
    setMessageContent("");
  };

  useEffect(() => {
    GetChatData();
  }, []);

  return (
    <Paper elevation={3} sx={{ width: "100%",  height: "88vh", display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, borderBottom: '1px solid #eee' }}>
        <Typography variant="h6">Chat Support</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box sx={{ flex: 1, overflowY: 'auto', p: 2 }}>
        {ChatGetData?.map((chat, index) => {
          console.log("kkkkk",chat)
          const isAdmin = chat?.chatFromType === "admin";
          return (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 2,
                flexDirection: isAdmin ? 'row-reverse' : 'row', 
              }}
            >
              <Avatar alt={chat?.chatFromType} src="/static/images/avatar/1.jpg" />
              <Paper
                elevation={1}
                sx={{
                  p: 1,
                  ml: isAdmin ? 0 : 2,
                  mr: isAdmin ? 2 : 0,
                  backgroundColor: isAdmin ? 'var(--colorused)' : '#f5f5f5', 
                  color: isAdmin ? 'white' : 'black',
                  borderRadius: 2,
                  maxWidth: '70%',
                                overflow: 'hidden',        
                  whiteSpace: 'normal',     
                  wordWrap: 'break-word',   
                  overflowWrap: 'break-word',
                }}
              >
                <Typography variant="body2">{chat?.chatContent}</Typography>
              </Paper>
            </Box>
          );
        })}
        <div ref={chatMessagesEndRef} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 2,
          borderTop: '1px solid #eee',
        }}
      >
        <TextField
          fullWidth
          value={messageContent}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder="Type a message"
          variant="outlined"
          size="small"
        />
        <IconButton onClick={ChatData} style={{
      color:"var(--colorused)"}}>
          <SendIcon />
        </IconButton>
      </Box>
    </Paper>
  );
};

export default ChatBox;
