
// import React, { useEffect, useState } from "react";
// import { Grid, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Typography } from "@mui/material";

// const Permission = () => {
//   const [role, setRole] = useState("");
//   const permissions = [
//     "Dashboard",
//     "Live Vendors",
//     "Vendor Accounts",
//     "Vendor Documents",
//     "User Role",
//     "Permission",
//     "Users",
//     "Orders",
//     "Billing",
//     "Restaurant Types",
//     "Magic Bag Size",
//     "Cuisine",
//     "Location",
//     "Bank",
//     "Support",
//     "Terms and Conditions",
//     "FAQ",
//     "Privacy Policy",
//     "Settings",
//     "Vendor Account Status",
//     "Vendor Documents Status",
    
//   ];

//   const [checkedPermissions, setCheckedPermissions] = useState({});

//   const handleRoleChange = (event) => {
//     setRole(event.target.value);
//   };


//   useEffect(() => {

//     document.title = "Permission";
//   }, []);

//   const handleCheckboxChange = (permission) => {
//     setCheckedPermissions((prev) => ({
//       ...prev,
//       [permission]: !prev[permission],
//     }));
//   };

//   return (
//     <div style={{ padding: "20px" }}>
//       <Typography variant="h5" gutterBottom>
//         Permission
//       </Typography>
//       <FormControl fullWidth style={{ marginBottom: "20px" , width:"300px"}}>
//         <InputLabel>Role</InputLabel>
//         <Select value={role} onChange={handleRoleChange}>
//           <MenuItem value="admin">Admin</MenuItem>
//           <MenuItem value="editor">Editor</MenuItem>
//           <MenuItem value="viewer">Viewer</MenuItem>
//         </Select>
//       </FormControl>
//       <Grid container spacing={3}>
//         {permissions.map((permission, index) => (
//           <Grid item xs={12} sm={6} md={4} key={index}>
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={checkedPermissions[permission] || false}
//                   onChange={() => handleCheckboxChange(permission)}
//                 />
//               }
//               label={permission}
//             />
//           </Grid>
//         ))}
//       </Grid>
//     </div>
//   );
// };

// export default Permission;



import React, { useEffect, useState } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";

const Permission = () => {
  const [role, setRole] = useState("");
  const permissions = [
    "Dashboard",
    "Live Vendors",
    "Vendor Accounts",
    "Vendor Documents",
    "User Role",
    "Permission",
    "Users",
    "Orders",
    "Billing",
    "Restaurant Types",
    "Magic Bag Size",
    "Cuisine",
    "Location",
    "Bank",
    "Support",
    "Terms and Conditions",
    "FAQ",
    "Privacy Policy",
    "Settings",
  ];

  const [checkedPermissions, setCheckedPermissions] = useState({});

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  useEffect(() => {
    document.title = "Permission";
  }, []);

  const handleParentCheckboxChange = (permission) => {
    setCheckedPermissions((prev) => {
      const isParentChecked = prev[permission]?.parent || false;
      return {
        ...prev,
        [permission]: {
          parent: !isParentChecked,
          view: !isParentChecked, // Select or deselect all children
          edit: !isParentChecked,
        },
      };
    });
  };

  const handleChildCheckboxChange = (permission, child) => {
    setCheckedPermissions((prev) => {
      const current = prev[permission] || { parent: false, view: false, edit: false };
      const updated = {
        ...current,
        [child]: !current[child],
      };

      // If all children are selected, mark the parent as checked
      updated.parent = updated.view && updated.edit;

      return {
        ...prev,
        [permission]: updated,
      };
    });
  };

  return (
    <div style={{ padding: "20px" }}>
      <Typography variant="h5" gutterBottom>
        Permission
      </Typography>
      <FormControl fullWidth style={{ marginBottom: "20px", width: "300px" }}>
        <InputLabel>Role</InputLabel>
        <Select value={role} onChange={handleRoleChange}>
          <MenuItem value="admin">Admin</MenuItem>
          <MenuItem value="editor">Editor</MenuItem>
          <MenuItem value="viewer">Viewer</MenuItem>
        </Select>
      </FormControl>
      <Grid container spacing={3}>
        {permissions.map((permission, index) => {
          const parentChecked = checkedPermissions[permission]?.parent || false;
          const viewChecked = checkedPermissions[permission]?.view || false;
          const editChecked = checkedPermissions[permission]?.edit || false;

          return (
            <Grid item xs={12} sm={6} md={4} key={index}>
              {/* Parent Checkbox */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={parentChecked}
                    onChange={() => handleParentCheckboxChange(permission)}
                    sx={{
                      color: `var(--colorused)`,
                      '&.Mui-checked': {
                          color: `var(--colorused)`,
                      },
                  }}
                  />
                }
                label={permission}
              />

              {/* Child Checkboxes */}
              <div style={{ marginLeft: "20px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={viewChecked}
                      onChange={() =>
                        handleChildCheckboxChange(permission, "view")
                      }
                      sx={{
                        color: `var(--colorused)`,
                        '&.Mui-checked': {
                            color: `var(--colorused)`,
                        },
                    }}
                    />
                  }
                  label="View Permission"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={editChecked}
                      onChange={() =>
                        handleChildCheckboxChange(permission, "edit")
                      }
                      sx={{
                        color: `var(--colorused)`,
                        '&.Mui-checked': {
                            color: `var(--colorused)`,
                        },
                    }}
                    />
                  }
                  label="Edit Permission"
                />
              </div>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default Permission;
