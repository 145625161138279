import React, { useEffect, useState } from 'react'
import { Stack, Grid, Box, TableCell, Skeleton } from '@mui/material';
import ViewScreen from '../../components/viewScreen';
import "./index.css"
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ImageUrl, document_viewlist_api, restaurantView_api } from '../../API/baseUrl';
import { documentViewRequest, restaurantViewRequest } from '../../redux/actions';
import ToastMessage from '../../utils/ToastMessage';
import StatusDropdown from './statusDropdown';
import { CFormSelect } from '@coreui/react';
import StatusModal from './modal';
import DocumentStatusModal from '../../components/statusModal';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TableSk } from '../skelaton/tableSk';
import LoaderAnimation from '../../logo-2/loader/loaderanimation';


const ViewDocument = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [restaurantdata, setRestaurantdata] = useState('')
    const [statusvalue, setStatusvalue] = useState('')
    const [accountstatus, setAccountstatus] = useState('')
    const [visible, setVisible] = useState(false)
    const [pending, setPending] = useState(true);
    const { id } = useParams();
    const { documentviewListdata } = useSelector((state) => state?.documentviewListReduser || {});

    console.log("restaurantViewdata", documentviewListdata)

    useEffect(() => {
        const parameter = {
            url: `${document_viewlist_api}${id}`,
        };
        dispatch(documentViewRequest(parameter));
    }, [id]);


    useEffect(() => {
        if (documentviewListdata?.status) {
            setPending(false)
            setRestaurantdata(documentviewListdata?.data);
            setStatusvalue(documentviewListdata?.data?.documentStatus)
        } else {
            ToastMessage("error", documentviewListdata?.message);
        }
    }, [documentviewListdata]);

    const checkAvailability = data => (data || "-")



    const statusArray = [
        {
            label: 'Pending',
            value: 0,
        },
        {
            label: 'Accept',
            value: 1,
        },
        {
            label: 'Not-Accept',
            value: 2,
        },
    ]
    // useEffect(() => {
    //     const storedStatus = localStorage.getItem('accountStatus');
    //     if (storedStatus) {
    //         setStatusvalue(storedStatus);
    //     }
    // }, []);


    // const handleselectchange = (e) => {
    //     setAccountstatus(e.target.value)
    //     setVisible(true)
    // };



    const handleselectchange = (e) => {
        const selectedStatus = e.target.value;
        setAccountstatus(e.target.value)
        // setStatusvalue(selectedStatus);
        setVisible(true);
    };

    console.log("99statusvaluestatusvalue", statusvalue)
    // const handleOpenImage = (url) => {
    //     const newWindow = window.open();
    //     if (newWindow) {
    //         newWindow.opener = null;
    //         newWindow.location = url;
    //     }
    // };

    // const handleOpenImage = (url) => {
    //     // Open image in a new tab
    //     const newWindow = window.open(url);
    //     if (newWindow) {
    //         newWindow.focus();
    //     }


    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.download = url.substring(url.lastIndexOf('/') + 1); // Extracts the file name from the URL
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link); 
    // };
    const handleOpenImage = (url) => {

        const newWindow = window.open(url);
        if (newWindow) {
            newWindow.focus();
        }
    };
    const repeatDivs = Array(7).fill(null);
    return (
        <div className='viewsreen_container'>
            <Box maxWidth="false" className="containerdivuser">
                <Stack className='editAndAddHeadClass headandback' >
                    <ArrowBackIcon onClick={() => navigate(-1)}
                        style={{ cursor: "pointer" }} />
                    <h2>View Documents</h2>
                </Stack>
            </Box>

            {visible ? <DocumentStatusModal

                {...{ visible, setVisible, setStatusvalue }} restaurantId={id} statusId={accountstatus} /> : ""}
            {pending ? (
            //   <div>
            //   {repeatDivs.map((_, index) => (
            //     <div
            //       key={index}
            //       style={{
            //         gap: "10px",
            //         display: "flex",
            //         justifyContent: "space-between",
            //         alignItems: "center",
            //       }}
            //     >
            //       <Skeleton animation="pulse" style={{ width: "250px" }} height={55} />
            //       <Skeleton animation="pulse" style={{ width: "250px" }} height={55} />
            //     </div>
            //   ))}
            // </div>
            <LoaderAnimation/>
            ) : (
                <ViewScreen>
                    <Grid className='support_section_view' item xs={12} md={12}>
                        <div className="view_membership_field">
                            <b>Restaurant ID  : </b>
                            <p className='viewpagevalues'>{checkAvailability(restaurantdata?.restaurantID)}</p>
                        </div>
                    </Grid>



                    <Grid className='support_section_view' item xs={12} md={12}>
                        <div className="view_membership_field">
                            <b>Restaurant Name : </b>
                            <p className='viewpagevalues'>{checkAvailability(restaurantdata?.restaurantName)}</p>
                        </div>
                    </Grid>

                    <Grid className='support_section_view' item xs={12} md={12}>
                        <div className="view_membership_field">
                            <b>Bank Name : </b>
                            <p className='viewpagevalues'>{checkAvailability(restaurantdata?.bankName)}</p>
                        </div>
                    </Grid>

                    <Grid className='support_section_view' item xs={12} md={12}>
                        <div className="view_membership_field">
                            <b>Bank Account Number : </b>
                            <p className='viewpagevalues'>{checkAvailability(restaurantdata?.account_number)}</p>
                        </div>
                    </Grid>

                    <Grid className='support_section_view' item xs={12} md={12}>
                        <div className="view_membership_field">
                            <b>Account Holder Name : </b>
                            <p className='viewpagevalues'>{checkAvailability(restaurantdata?.account_holder_name)}</p>
                        </div>
                    </Grid>

                    <Grid className='support_section_view' item xs={12} md={12}>
                        <div className="view_membership_field">
                            <b>IFSC code : </b>
                            <p className='viewpagevalues'>{checkAvailability(restaurantdata?.ifsc_code)}</p>
                        </div>
                    </Grid>

                    <Grid className='support_section_view' item xs={12} md={12}>
                        <div className="view_membership_field">
                            <b>Branch Name : </b>
                            <p className='viewpagevalues'>{checkAvailability(restaurantdata?.branch_name)}</p>
                        </div>
                    </Grid>

                    {/* <Grid className='support_section_view' item xs={12} md={12}>
                    <div className="view_membership_field">
                        <b>Fssai Licence : </b>
                        <div className='view_image_container'>
                            {(restaurantdata?.fssaiLicence)?.map((item, index) => {
                                return (
                                    <img onClick={() => handleOpenImage(`${ImageUrl}${checkAvailability(item.documentPath)}`)} height={80} width={80} key={index} src={`${ImageUrl}${checkAvailability(item.documentPath)}`} alt="" />
                                )
                            })}
                        </div>


                    </div>
                </Grid> */}



                    <Grid className='support_section_view' item xs={12} md={12}>
                        <div className="view_membership_field">
                            <b>Fssai Licence: </b>
                            <div className='view_image_container'>
                                {(restaurantdata?.fssaiLicence)?.map((item, index) => {
                                    const filePath = `${ImageUrl}${checkAvailability(item.documentPath)}`;
                                    const isPdf = item.documentPath.toLowerCase().endsWith('.pdf');
                                    console.log(filePath, "filePathfilePath"

                                    )
                                    return isPdf ? (
                                        <a
                                            key={index}
                                            href={filePath}
                                            // target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ cursor: 'pointer', display: 'block', width: '80px', height: '80px', border: '1px solid #ddd', textAlign: 'center', lineHeight: '80px' }}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            View PDF
                                        </a>
                                    ) : (
                                        <img
                                            key={index}
                                            src={filePath}
                                            height={80}
                                            width={80}
                                            alt=""
                                            onClick={() => handleOpenImage(filePath)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    );
                                })}
                            </div>

                        </div>
                    </Grid>









                    <Grid className='support_section_view' item xs={12} md={12}>
                        <div className="view_membership_field">
                            <b>Fssai Licence Expiry Date  : </b>
                            <p className='viewpagevalues'>{checkAvailability(restaurantdata?.fssaiLicenceExpiryDate)}</p>
                        </div>
                    </Grid>
                    <Grid className='support_section_view' item xs={12} md={12}>
                        {/* <div className="view_membership_field">
                        <b>PanCard  : </b>
                        <div className='view_image_container'>
                            {(restaurantdata?.panCard)?.map((item, index) => {
                                return (
                                    <img onClick={() => handleOpenImage(`${ImageUrl}${checkAvailability(item.documentPath)}`)} height={80} width={80} key={index} src={`${ImageUrl}${checkAvailability(item.documentPath)}`} alt="" />
                                )
                            })}
                        </div>
                    </div> */}

                        <div className="view_membership_field">
                            <b>PanCard  : </b>
                            <div className='view_image_container'>
                                {(restaurantdata?.panCard)?.map((item, index) => {
                                    const filePath = `${ImageUrl}${checkAvailability(item.documentPath)}`;
                                    const isPdf = item.documentPath.toLowerCase().endsWith('.pdf'); // Check if the file is a PDF

                                    return isPdf ? (
                                        <a
                                            key={index}
                                            href={filePath}
                                            // target="_blank" 
                                            rel="noopener noreferrer"
                                            style={{
                                                cursor: 'pointer',
                                                display: 'block',
                                                width: '80px',
                                                height: '80px',
                                                border: '1px solid #ddd',
                                                textAlign: 'center',
                                                lineHeight: '80px'
                                            }}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            View PDF
                                        </a>
                                    ) : (
                                        <img
                                            key={index}
                                            src={filePath}
                                            height={80}
                                            width={80}
                                            alt=""
                                            onClick={() => handleOpenImage(filePath)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    );
                                })}
                            </div>
                        </div>

                    </Grid>


                    <Grid className='support_section_view' item xs={12} md={12}>
                        {/* <div className="view_membership_field">
                        <b>Regular GSTin : </b>
                        <div className='view_image_container'>
                            {(restaurantdata?.regularGstIn)?.map((item, index) => {
                                return (
                                    <img onClick={() => handleOpenImage(`${ImageUrl}${checkAvailability(item.documentPath)}`)} height={80} width={80} key={index} src={`${ImageUrl}${checkAvailability(item.documentPath)}`} alt="" />
                                )
                            })}
                        </div>
                    </div> */}


                        <div className="view_membership_field">
                            <b>Regular GSTin : </b>
                            <div className='view_image_container'>
                                {(restaurantdata?.regularGstIn)?.map((item, index) => {
                                    const filePath = `${ImageUrl}${checkAvailability(item.documentPath)}`;
                                    const isPdf = item.documentPath.toLowerCase().endsWith('.pdf'); // Check if the file is a PDF

                                    return isPdf ? (
                                        <a
                                            key={index}
                                            href={filePath}
                                            // target="_blank" 
                                            rel="noopener noreferrer"
                                            style={{
                                                cursor: 'pointer',
                                                display: 'block',
                                                width: '80px',
                                                height: '80px',
                                                border: '1px solid #ddd',
                                                textAlign: 'center',
                                                lineHeight: '80px'
                                            }}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            View PDF
                                        </a>
                                    ) : (
                                        <img
                                            key={index}
                                            src={filePath}
                                            height={80}
                                            width={80}
                                            alt=""
                                            onClick={() => handleOpenImage(filePath)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    );
                                })}
                            </div>
                        </div>

                    </Grid>


                    <Grid className='support_section_view' item xs={12} md={12}>
                        <div className="view_membership_field">
                            <b>Food Image : </b>
                            <div className='view_image_container'>
                                {(restaurantdata?.foodImage)?.map((item, index) => {
                                    return (
                                        <img onClick={() => handleOpenImage(`${ImageUrl}${checkAvailability(item.documentPath)}`)} height={80} width={80} key={index} src={`${ImageUrl}${checkAvailability(item.documentPath)}`} alt="foodImage" />
                                    )
                                })}
                            </div>
                        </div>
                    </Grid>

                    <Grid className='support_section_view' item xs={12} md={12}>
                        <div className="view_membership_field">
                            <b>Restaurant Logo : </b>
                            <div className='view_image_container'>
                                {(restaurantdata?.restaurantLogo)?.map((item, index) => {
                                    return (
                                        <img onClick={() => handleOpenImage(`${ImageUrl}${checkAvailability(item.documentPath)}`)} height={80} width={80} key={index} src={`${ImageUrl}${checkAvailability(item.documentPath)}`} alt="foodImage" />
                                    )
                                })}
                            </div>
                        </div>
                    </Grid>





                    {/* <Grid className='support_section_view' item xs={12} md={12}>
                    <div className="view_membership_field">
                        <b>Document Status  : </b>
                        <CFormSelect
                        className="Status_dropdown"
                            name="status"
                            label=""
                            disabled={statusvalue == 1 || statusvalue == 2}
                            placeholder="Document Status"
                            value={statusvalue}
                            // defaultValue={restaurantdata?.documentStatus}
                            onChange={handleselectchange}
                        >
                            {statusArray?.map((item, index) => (
                                <option key={index} value={item.value}>
                                    {item.label}
                                </option>
                            ))}
                        </CFormSelect>
                    </div>
                </Grid> */}

                    {restaurantdata?.panCard ?

                        <Grid className='support_section_view' item xs={12} md={12}>
                            <div className="view_membership_field">
                                <b>Document Status  : </b>
                                <CFormSelect
                                    className="Status_dropdown"
                                    name="status"
                                    label=""
                                    disabled={statusvalue == 1 || statusvalue == 2 ||  accountstatus == 1 || accountstatus == 2}
                                    placeholder="Document Status"
                                    value={statusvalue}
                                    onChange={handleselectchange}
                                >
                                    {statusArray?.map((item, index) => (
                                        <option key={index} value={item.value}>
                                            {statusvalue === item.value
                                                ? item.value === 1
                                                    ? 'Accepted'
                                                    : item.value === 2
                                                        ? 'Not Accepted'
                                                        : item.label
                                                : item.label}
                                        </option>
                                    ))}
                                </CFormSelect>
                            </div>
                        </Grid>


                        : ""


                    }

                </ViewScreen>
            )}
        </div>
    )
}

export default ViewDocument;