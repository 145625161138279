import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import "./index.css"
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { useFormik } from 'formik'
import { baseUrl, sendemail_api, signIn_api } from '../../../API/baseUrl'
import { sendemailshema } from '../../../utils/schema'
import { useDispatch, useSelector } from 'react-redux'
import { sendemail } from '../../../redux/actions'
import ToastMessage from '../../../utils/ToastMessage'
import { CircularProgress } from '@mui/material'

const Intial = {
    email: '',
};

const ForgetPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { sendemailInfo } = useSelector((state) => state?.sendemail || {});
    const [loading, setLoading] = useState(false)

    const { handleChange, handleSubmit, setFieldValue, handleBlur, values, errors, touched } =
        useFormik({
            initialValues: Intial,
            validationSchema: sendemailshema,
            onSubmit: values => ApiHandleforget(),
        });



    // const ApiHandleLogin = async () => {
    //     const apiValue = {
    //         email: values.email
    //     }
    //     try {
    //         setLoading(true)
    //         const result = await baseUrl.post(sendemail_api, apiValue)
    //         if (result.data.status) {
    //             setLoading(false)
    //             values.email = ""
    //         } else {
    //             console.log("error")
    //         }
    //     } catch (error) {
    //         console.error(error)

    //     } finally {
    //         setLoading(false)
    //     }

    // }

    const ApiHandleforget = async () => {
        setLoading(true)
        const parameter = {
            email: values.email,
            url: sendemail_api,
        };
        dispatch(sendemail(parameter));
    };

    useEffect(() => {
        if (sendemailInfo?.status) {
            setLoading(false)
            ToastMessage('success', sendemailInfo?.message);
        } else {
            ToastMessage('error', sendemailInfo?.message);
            setLoading(false)
        }
    }, [sendemailInfo]);


    const handleSubmit2 = (e) => {
        e.preventDefault()
        handleSubmit()
        console.log("error", errors)
        console.log("values", values)
    }


    return (
        <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md={6} sx={12} sm={10}>
                        <CCardGroup>
                            <CCard className="p-4">
                                <CCardBody>
                                    <CForm onSubmit={handleSubmit2}>
                                        <h1>Forget Password</h1>
                                        <p className="text-body-secondary">Enter your email to reset your password</p>
                                        <CInputGroup className="mb-3">
                                            <CInputGroupText>
                                                <CIcon icon={cilUser} />
                                            </CInputGroupText>
                                            <CFormInput placeholder="Email"
                                                autoComplete="Email"
                                                name="email"
                                                value={values.email}
                                                type="email"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />

                                        </CInputGroup>
                                        {errors.email && touched.email ? (
                                            <p className="form-error">{errors.email}</p>
                                        ) : null}

                                        <CRow>
                                            <CCol xs={6}>
                                                <CButton
                                                    color="primary"
                                                    className="px-4 btn_add_country"
                                                    type="submit"
                                                    style={{ background: "var(--colorused)", border: "none" }}
                                                >
                                                    {loading ? (
                                                        <CircularProgress size={24} style={{ color: "white" }} />
                                                    ) : (
                                                        "Submit"
                                                    )}
                                                </CButton>
                                            </CCol>
                                            {/* <CCol xs={6} className="text-right">
                        <CButton color="link" className="px-0">
                          Forgot password?
                        </CButton>
                      </CCol> */}
                                        </CRow>
                                    </CForm>
                                </CCardBody>
                            </CCard>

                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
}

export default ForgetPassword
