import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormSelect,
  CRow,
} from "@coreui/react";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ToastMessage from "../../utils/ToastMessage";
import CircularProgress from "@mui/material/CircularProgress";
import {
  countryactivelist,
  create_city_api,
  editById_api,
  getcityById_api,
  stateactivelist,
} from "../../API/baseUrl";
import {
  activecountrylistRequest,
  activestatelistRequest,
  addcityRequest,
  removeDataFromReducer,
  getcitybyidRequest,
  editbyidRequest,
} from "../../redux/actions";
import { useNavigate, useParams } from "react-router-dom";
import { cityaddshema } from "../../utils/schema";
import "./index.css"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoaderAnimation from "../../logo-2/loader/loaderanimation";

const Intial = {
  countryIdFk: "",
  stateIdFk: "",
  cityName: "",
};

const CityAdd = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [countrylist, setCountrylist] = useState([]);
  const [statelist, setStatelist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loaderShow, setLoaderShow] = useState(true);
  const dispatch = useDispatch();
  const { activecountrydata } = useSelector(
    (state) => state?.activecountrylistreduser || {}
  );
  const { activestatedata } = useSelector(
    (state) => state?.activestatelistreduser || {}
  );
  const { citycreatedata } = useSelector(
    (state) => state?.cityaddreduser || {}
  );

  const { getCitydata } = useSelector(
    (state) => state?.getCityReduser || {}
  );

  const { editCitydata } = useSelector(
    (state) => state?.EditCityReduser || {}
  );


  const {
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: Intial,
    validationSchema: cityaddshema,
    onSubmit: (values) => ApiHandlesubmit(),
  });

  useEffect(() => {
    if (citycreatedata?.status) {
      setLoading(false)
      ToastMessage("success", citycreatedata?.message);
      navigate("/dashboard/city");
      dispatch(removeDataFromReducer("ADD_CITY_RESET"));
    } else {
      setLoading(false)
      ToastMessage("error", citycreatedata?.message);
    }
  }, [citycreatedata]);

  useEffect(() => {
    if (editCitydata?.status) {
      setLoading(false)
      ToastMessage("success", editCitydata?.message);
    
      dispatch(removeDataFromReducer("CITY_EDIT_RESET"));
      dispatch(removeDataFromReducer("CITY_DETAILS_REQUEST"));
      navigate("/dashboard/city");
    } else {
      ToastMessage("error", editCitydata?.message);
      setLoading(false)
    }
  }, [editCitydata]);


  useEffect(() => {
    if (getCitydata?.status) {
      // values.countryIdFk = getCitydata?.data?.countryIdFk
      // values.stateIdFk = getCitydata?.data?.stateIdFk
      // values.cityName = getCitydata?.data?.cityName
      setFieldValue("countryIdFk",getCitydata?.data?.countryIdFk)
      setFieldValue("stateIdFk",getCitydata?.data?.stateIdFk)
      setFieldValue("cityName",getCitydata?.data?.cityName)
    } else {
      ToastMessage("error", getCitydata?.message);
    }
  }, [getCitydata]);

  const handleSubmit2 = (e) => {
    e.preventDefault();
    handleSubmit();
    console.log("error", errors);
    console.log("values", values);
  };
  // const handleselectchange = (e, type) => {
  //   if (type === "state") {
  //     values.stateIdFk = e.target.value;
  //     setFieldValue("stateIdFk" ,e.target.value )
  //   } else {
  //     values.countryIdFk = e.target.value;
  //     setFieldValue("countryIdFk" ,e.target.value)
  //   }
  // };  
  const handleselectchange = (e, type) => {
    const value = e.target.value;
    if (type === "state") {
      setFieldValue("stateIdFk", value);
    } else {
      setFieldValue("countryIdFk", value);
      setFieldValue("stateIdFk", ""); // Clear state dropdown when country changes
    }
  };
  const ApiHandlesubmit = async () => {
    setLoading(true)
    const parameter = {
      stateIdFk: values.stateIdFk,
      countryIdFk: values.countryIdFk,
      cityName: values.cityName,
      url: id ? `${editById_api}${id}` : create_city_api,
    };

    if (id) {
      dispatch(editbyidRequest(parameter));
    }
    else {
      dispatch(addcityRequest(parameter));
    }

  };

  useEffect(() => {
    const parameter = {
      url: countryactivelist,
    };
    dispatch(activecountrylistRequest(parameter));
  }, []);


  useEffect(() => {
    const parameter = {
      url: `${getcityById_api}${id}`,
    };
    dispatch(getcitybyidRequest(parameter));
  }, [id]);




  useEffect(() => {
    if (values?.countryIdFk) {
      const parameter = {
        countryIdFk: values.countryIdFk,
        url: stateactivelist,
      };
      dispatch(activestatelistRequest(parameter));
    }
    
    // correction
    else {
      // Clear the state list if no country is selected
      setStatelist([]);
    }
  }, [values.countryIdFk]);

  useEffect(() => {
    if (activecountrydata?.status) {
      const newCountries = activecountrydata?.data?.map((country) => ({
        label: country.countryName,
        value: country.id,
      }));
      setCountrylist(newCountries);
    } else {
      ToastMessage("error", activecountrydata?.message);
    }
  }, [activecountrydata]);
  console.log(countrylist, "newCountries");

  useEffect(() => {
    if (activestatedata?.status) {
      const newCountries = activestatedata?.data?.map((country) => ({
        label: country.stateName,
        value: country.stateId,
      }));
      setStatelist(newCountries);
    } else {
      ToastMessage("error", activestatedata?.message);
    }
  }, [activestatedata]);

  console.log(values, "values");
  const handleBackNavigation = () => {
    navigate(-1); // Navigates back to the previous page
  }; 
  return (
<>
{/* {
  loaderShow ? <LoaderAnimation/>: */}


  <CForm onSubmit={handleSubmit2}>
  <h3 className="AddEdit_Heading">  <ArrowBackIcon onClick={handleBackNavigation} style={{cursor:"pointer"}}/>{id ? "Edit City" : "Add City"}</h3>

  <CContainer className="add_form_container">
    <CRow xs={{ gutter: 2 }}>
      <CCol sm={12} md={6}>
        <CFormSelect
          name="countryIdFk"
          label="Country *"
          value={values.countryIdFk}
          placeholder="Country "
          aria-label="Default select example"
          onChange={(e) => handleselectchange(e, "country")}
          onBlur={handleBlur}
          // text={errors.countryIdFk}
          invalid={touched.countryIdFk && !!errors.countryIdFk}
          className="field-width"

        >
          <option value="" disabled selected>
            Select a Country
          </option>
          {countrylist?.map((country, index) => (
            <option key={index} value={country.value}>
              {country.label}
            </option>
          ))}
         
        </CFormSelect>
        {touched.countryIdFk && errors.countryIdFk && (
            <div className="invalid-feedback">{errors.countryIdFk}</div>
      )}
      </CCol>

      <CCol sm={12} md={6}>
        <CFormSelect
          name="stateIdFk"
          label="State *"
          placeholder="Country Name"
          value={values.stateIdFk}
          aria-label="Default select example"
          onChange={(e) => handleselectchange(e, "state")}
          onBlur={handleBlur}
          // text={errors.stateIdFk}
          invalid={touched.stateIdFk && !!errors.stateIdFk}

          className="field-width"

        >
          <option value="" disabled selected
          >
            Select a State
          </option>
          {statelist.map((country, index) => (
            <option key={index} value={country.value}>
              {country.label}
            </option>
          ))}
         
        </CFormSelect>
        {touched.stateIdFk && errors.stateIdFk && (
            <div className="invalid-feedback">{errors.stateIdFk}</div>
          )}
      </CCol>

      <CCol sm={12} md={6}>
        <CFormInput
          name="cityName"
          type="text"
          value={values.cityName}
          id="exampleFormControlInput1"
          label="City Name *"
          placeholder="City Name"
          onChange={handleChange}
          onBlur={handleBlur}
          // text={errors.cityName}
          invalid={touched.cityName && !!errors.cityName}

          aria-describedby="exampleFormControlInputHelpInline"
          className="field-width"

        />
        {touched.cityName && errors.cityName && (
          <div className="invalid-feedback">{errors.cityName}</div>
        )}
      </CCol>
    </CRow>

    <div className="d-grid gap-2 d-md-flex justify-content-md-end py-5">
      {/* <CButton style={{
            backgroundColor: "var(--colorused)",
            border: "none",
            color: "var(--colorWhite)",
          }} type="submit" className="me-md-2">
       {id ? "Update" : "Submit"}
      </CButton> */}

      <CButton style={{
        backgroundColor: "var(--colorused)",
        border: "none",
        width: "120px",
        color: "var(--colorWhite)",
      }} type="submit" className="me-md-2">
        {loading ? (
          <CircularProgress size={24} style={{ color: "white" }} />
        ) :

          <>{id ? "Update" : "Submit"}</>
        }

      </CButton>
    </div>
  </CContainer>
</CForm>
{/* } */}

</>



   
  );
};

export default CityAdd;
