import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormSelect,
  CRow,
} from "@coreui/react";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react"; 
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { countryactivelist, createstate_api, editstateById_api, getstateById_api } from "../../API/baseUrl";
import CircularProgress from "@mui/material/CircularProgress";
import {
  activecountrylistRequest,
  addstateRequest,
  editstatebyidRequest,
  getstatebyidRequest,
  removeDataFromReducer,
} from "../../redux/actions";
import ToastMessage from "../../utils/ToastMessage";
import { stateaddshema } from "../../utils/schema";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const StateAdd = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [countrylist, setCountrylist] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { activecountrydata } = useSelector(
    (state) => state?.activecountrylistreduser || {}
  );
  const { addstatedata } = useSelector((state) => state?.stateaddreduser || {});
  const { editStatedata } = useSelector((state) => state?.EditStateReduser || {});
  const { getstatedata } = useSelector((state) => state?.getStateReduser || {});

  const Intial = {
    countryname: "",
    stateName: "",
  };

  const {
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: Intial,
    validationSchema: stateaddshema,
    onSubmit: (values) => ApiHandlesubmit(),
  });


  useEffect(() => {
    if (activecountrydata?.status) {
      const newCountries = activecountrydata?.data?.map((country) => ({
        label: country.countryName,
        value: country.id,
      }));
      setCountrylist(newCountries);
    } else {
      ToastMessage("error", activecountrydata?.message);
    }
  }, [activecountrydata]);



  useEffect(() => {
    if (getstatedata?.status) {
    //  values.countryname = getstatedata?.data?.countryId
    //  values.stateName = getstatedata?.data?.stateName
    setFieldValue("countryname", getstatedata?.data?.countryId);
    setFieldValue("stateName", getstatedata?.data?.stateName);

    
    } 
      // ToastMessage("error", getstatedata?.message);
    
  }, [getstatedata]);


  useEffect(() => {
    const parameter = {
      url: `${getstateById_api}${id}`,
    };
    dispatch(getstatebyidRequest(parameter));
  }, [id]);





  useEffect(() => {
    if (addstatedata?.status) {
      setLoading(false)
      ToastMessage("success", addstatedata?.message);
      navigate("/dashboard/state");
      dispatch(removeDataFromReducer("ADD_STATE_RESET"));
    } else {
      ToastMessage("error", addstatedata?.message);
      setLoading(false)
    }
  }, [addstatedata]);


  useEffect(() => {
    if (editStatedata?.status) {
      setLoading(false)
      ToastMessage("success", editStatedata?.message);
      navigate("/dashboard/state");
      dispatch(removeDataFromReducer("STATE_EDIT_RESET"));
      dispatch(removeDataFromReducer("STATE_DETAILS_RESET"));
    } else {
      ToastMessage("error", editStatedata?.message);
      setLoading(false)
    }
  }, [editStatedata]);

  useEffect(() => {
    const parameter = {
      url: countryactivelist,
    };
    dispatch(activecountrylistRequest(parameter));
  }, []);

  const handleSubmit2 = (e) => {
    e.preventDefault();
    handleSubmit();
    console.log("error", errors);
    console.log("values", values);
  };

  const handleselectchange = (e) => {
    values.countryname = e.target.value;
    setFieldValue("countryname" , e.target.value)
  };

  const ApiHandlesubmit = async () => {
    setLoading(true)
    const parameter = {
      countryIdFk: values.countryname,
      stateName: values.stateName,
      url: id ? `${editstateById_api}${id}` : createstate_api ,
    };
    if(id){
      dispatch(editstatebyidRequest(parameter));
    }
    else{
      dispatch(addstateRequest(parameter));
    }
  
  };
  const handleBackNavigation = () => {
    navigate(-1); // Navigates back to the previous page
  };

  return (
    <CForm onSubmit={handleSubmit2}>
      <h3 className="AddEdit_Heading">  <ArrowBackIcon onClick={handleBackNavigation} style={{cursor:"pointer"}}/>{id ? "Edit State" : "Add State"}</h3>

      <CContainer className="add_form_container">
        <CRow xs={{ gutter: 2 }}>
          <CCol sm={12} md={6}>
            <CFormSelect
              name="countryname"
              label="Country Name *"
              placeholder="Country Name"
              value={values.countryname}
              // text={errors.countryname}
              invalid={touched.countryname && !!errors.countryname}
              onChange={handleselectchange}
              onBlur={handleBlur}
              // className="field-width"

            >
              <option value="" disabled selected> 
                Select a Country
              </option>
              {countrylist.map((country, index) => (
                <option key={index} value={country.value}>
                  {country.label}
                </option>
              ))}
                 
            </CFormSelect>
            {touched.countryname && errors.countryname && (
              <div className="invalid-feedback">{errors.countryname}</div>
            )}
          </CCol>

          <CCol sm={12} md={6}>
            <CFormInput
              name="stateName"
              type="text"
              value={values.stateName}
              id="exampleFormControlInput1"
              label="State Name *"
              placeholder="State Name"
              // text={errors.stateName}
              invalid={touched.stateName && !!errors.stateName}
              onChange={handleChange}
              onBlur={handleBlur}
              aria-describedby="exampleFormControlInputHelpInline"
              // className="field-width"

            />
             {touched.stateName && errors.stateName && (
              <div className="invalid-feedback">{errors.stateName}</div>
            )}
          </CCol>
        </CRow>

        <div className="d-grid gap-2 d-md-flex justify-content-md-end py-5">
        <CButton style={{
            backgroundColor: "var(--colorused)",
            border: "none",
            width: "120px",
            color: "var(--colorWhite)",
          }} type="submit" className="me-md-2">
            {loading ? (
              <CircularProgress size={24} style={{ color: "white" }} />
            ) :

              <>{id ? "Update" : "Submit"}</>
            }

          </CButton>
          
        </div>
      </CContainer>
    </CForm>
  );
};

export default StateAdd;


























// import {
//   CButton,
//   CCol,
//   CContainer,
//   CForm,
//   CFormInput,
//   CFormSelect,
//   CRow,
// } from "@coreui/react";
// import { useFormik } from "formik";
// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate, useParams } from "react-router-dom";
// import { countryactivelist, createstate_api, editstateById_api, getstateById_api } from "../../API/baseUrl";
// import CircularProgress from "@mui/material/CircularProgress";



// import {
//   activecountrylistRequest,
//   addstateRequest,
//   editstatebyidRequest,
//   getstatebyidRequest,
//   removeDataFromReducer,
// } from "../../redux/actions";
// import ToastMessage from "../../utils/ToastMessage";
// import { stateaddshema } from "../../utils/schema";

// const StateAdd = () => {
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const [countrylist, setCountrylist] = useState([]);
//   const [isLoading, setIsLoading] = useState(true); // State for loader
//   const dispatch = useDispatch();
//   const { activecountrydata } = useSelector(
//     (state) => state?.activecountrylistreduser || {}
//   );
//   const { addstatedata } = useSelector((state) => state?.stateaddreduser || {});
//   const { editStatedata } = useSelector((state) => state?.EditStateReduser || {});
//   const { getstatedata } = useSelector((state) => state?.getStateReduser || {});

//   const Intial = {
//     countryname: "",
//     stateName: "",
//   };

//   const {
//     handleChange,
//     handleSubmit,
//     setFieldValue,
//     handleBlur,
//     values,
//     errors,
//     touched,
//   } = useFormik({
//     initialValues: Intial,
//     validationSchema: stateaddshema,
//     onSubmit: async (values) => {
//       // setIsLoading(true); // Start loader
//       await ApiHandlesubmit();
//       // setIsLoading(false); // Turn off loader after API call completes
//     },
//   });

//   useEffect(() => {
//     if (activecountrydata?.status) {
//       const newCountries = activecountrydata?.data?.map((country) => ({
//         label: country.countryName,
//         value: country.id,
//       }));
//       setCountrylist(newCountries);
//     } else {
//       ToastMessage("error", activecountrydata?.message);
//     }
//   }, [activecountrydata]);

//   useEffect(() => {
//     if (getstatedata?.status) {
//       setFieldValue('countryname', getstatedata?.data?.countryId);
//       setFieldValue('stateName', getstatedata?.data?.stateName);
//       setIsLoading(false);

//     } else {
//       // ToastMessage("error", getstatedata?.message);
//       setIsLoading(false);

//     }
//     // debugger
    
//   }, [getstatedata, setFieldValue]);

//   useEffect(() => {
//     const parameter = {
//       url: `${getstateById_api}${id}`,
//     };
//     dispatch(getstatebyidRequest(parameter));
//   }, [id]);

//   useEffect(() => {
//     const parameter = {
//       url: countryactivelist,
//     };
//     dispatch(activecountrylistRequest(parameter));
//   }, []);

//   useEffect(() => {
//     if (addstatedata?.status) {
//       ToastMessage("success", addstatedata?.message);
//       navigate("/dashboard/state");
//       dispatch(removeDataFromReducer("ADD_STATE_RESET"));
//     } else {
//       ToastMessage("error", addstatedata?.message);
//     }
//   }, [addstatedata, navigate, dispatch]);

//   useEffect(() => {
//     if (editStatedata?.status) {
//       ToastMessage("success", editStatedata?.message);
//       navigate("/dashboard/state");
//       dispatch(removeDataFromReducer("STATE_EDIT_RESET"));
//     } else {
//       ToastMessage("error", editStatedata?.message);
//     }
//   }, [editStatedata, navigate, dispatch]);

//   const handleselectchange = (e) => {
//     setFieldValue('countryname', e.target.value);
//   };

//   const ApiHandlesubmit = async () => {
//     // debugger
//     setIsLoading(true);
//     const parameter = {
//       countryIdFk: values.countryname,
//       stateName: values.stateName,
//       url: id ? `${editstateById_api}${id}` : createstate_api ,
//     };
//     if(id){
//       await dispatch(editstatebyidRequest(parameter));
//     }
//     else{
//       await dispatch(addstateRequest(parameter));
//     }
//   };

//   return (
//     <>
//       {isLoading && <div className="loader">Loading...</div>}
//       <CForm onSubmit={handleSubmit}>
//         <h3 className="AddEdit_Heading">{id ? "Edit State" : "Add State"}</h3>

//         <CContainer className="add_form_container">
//           <CRow xs={{ gutter: 2 }}>
//             <CCol sm={12} md={6}>
//               <CFormSelect
//                 name="countryname"
//                 label="Country Name *"
//                 placeholder="Country Name"
//                 value={values.countryname}
//                 invalid={touched.countryname && !!errors.countryname}
//                 onChange={handleselectchange}
//                 onBlur={handleBlur}
//               >
//                 <option value="" disabled selected>
//                   Select a Country
//                 </option>
//                 {countrylist.map((country, index) => (
//                   <option key={index} value={country.value}>
//                     {country.label}
//                   </option>
//                 ))}
//               </CFormSelect>
//               {touched.countryname && errors.countryname && (
//                 <div className="invalid-feedback">{errors.countryname}</div>
//               )}
//             </CCol>

//             <CCol sm={12} md={6}>
//               <CFormInput
//                 name="stateName"
//                 type="text"
//                 value={values.stateName}
//                 id="exampleFormControlInput1"
//                 label="State Name *"
//                 placeholder="State Name"
//                 invalid={touched.stateName && !!errors.stateName}
//                 onChange={handleChange}
//                 onBlur={handleBlur}
//                 aria-describedby="exampleFormControlInputHelpInline"
//               />
//               {touched.stateName && errors.stateName && (
//                 <div className="invalid-feedback">{errors.stateName}</div>
//               )}
//             </CCol>
//           </CRow>

//           <div className="d-grid gap-2 d-md-flex justify-content-md-end py-5">
//             <CButton
//               style={{
//                 backgroundColor: "var(--colorused)",
//                 border: "none",
//                 color: "var(--colorWhite)",
//               }}
//               type="submit"
//               className="me-md-2"
//             >
//               {/* {id ? "Update" : "Submit"}
//               {isLoading && (
//           <CircularProgress size={24} style={{ color: "white" }} />
//           {id ? "Update" : "Submit"}
//         )} */}



// {isLoading ? (
//   <CircularProgress size={24} style={{ color: "white" }} />
// ) : (
//   <button type="submit">
//     {id ? "Update" : "Submit"}
//   </button>
// )}

//             </CButton>
//           </div>
//         </CContainer>
//       </CForm>
//     </>
//   );
// };

// export default StateAdd;
