import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CFormSelect,
} from "@coreui/react";
import { Box, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  document_viewlist_api,
  ImageUrl,
  orderList_api,
  restaurantView_api,
} from "../../API/baseUrl";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import {
  documentViewRequest,
  restaurantViewRequest,
  vendorList,
} from "../../redux/actions";
import ToastMessage from "../../utils/ToastMessage";
import "./index.css";
import StatusModal from "./modal";
import locationicon from "../../assets/images/location.gif";
import { FaLocationDot } from "react-icons/fa6";
import DataTable from "react-data-table-component";
import { TableSk } from "../skelaton/tableSk";
import LiveVendorOrder from "./LiveVendorOrder";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CommisionCalulation from "./CommisionCalulation";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";

const ViewLiveRestuarant = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [restaurantdata, setRestaurantdata] = useState("");
  const [statusvalue, setStatusvalue] = useState("");
  const [accountstatus, setAccountstatus] = useState("");
  const [visible, setVisible] = useState(false);
  const [typeforlist, setTypeforlist] = useState("Magic Bag");
  const [restaurantdatadocument, setRestaurantdatadocument] = useState("");
  const [Order, setOrder] = useState([]);
  const [resturantpending, setResturantpending] = useState(true);
  const [docpending, setDocpending] = useState(true);
  const [bankpending, setBankpending] = useState(true);

  const { id } = useParams();
  const { restaurantViewdata } = useSelector(
    (state) => state?.restaurantViewreduser || {}
  );
  const { vendorListInfo } = useSelector((state) => state?.vendorList || {});

  const { documentviewListdata } = useSelector(
    (state) => state?.documentviewListReduser || {}
  );

  console.log("restaurantViewdata", documentviewListdata);

  useEffect(() => {
    const parameter = {
      url: `${document_viewlist_api}${id}`,
    };
    dispatch(documentViewRequest(parameter));
  }, [id]);

  useEffect(() => {
    if (documentviewListdata?.status) {
      setDocpending(false)
      setBankpending(false)
      console.log("documentviewListdata", documentviewListdata?.fssaiLicence);
      setRestaurantdatadocument(documentviewListdata?.data);
    } else {
      ToastMessage("error", documentviewListdata?.message);
    }
  }, [documentviewListdata]);

  useEffect(() => {
    const parameter = {
      url: `${restaurantView_api}${id}`,
    };
    dispatch(restaurantViewRequest(parameter));
  }, [id]);

  useEffect(() => {
    if (restaurantViewdata?.status) {
      setRestaurantdata(restaurantViewdata?.data);
      setStatusvalue(restaurantViewdata?.data?.accountStatus);

      setResturantpending(false);
    } else {
      ToastMessage("error", restaurantViewdata?.message);
    }

    setResturantpending(false);
  }, [restaurantViewdata]);

  const statusArray = [
    {
      label: "Pending",
      value: 0,
    },
    {
      label: "Accept",
      value: 1,
    },
    {
      label: "Not-Accept",
      value: 2,
    },
  ];

  const handleOpenImage = (url) => {
    const newWindow = window.open(url, "_blank");
    if (newWindow) {
      newWindow.focus();
    }
  };
  const handleselectchange = (e) => {
    const selectedStatus = e.target.value;
    setAccountstatus(e.target.value);
    setVisible(true);
  };

  const googleMapsLink = `https://www.google.com/maps?q=${restaurantdata?.restaurantAddressLatitude},${restaurantdata?.restaurantAddressLongitude}`;

  const handleType = (type) => {
    setTypeforlist(type);
  };

  const checkAvailability = (data) => data || "-";

  const checkAvailability1 = (cuisines) => {
    // Check if cuisines is not undefined, null, or empty and split it into an array
    return cuisines && typeof cuisines === "string" && cuisines?.trim() !== ""
      ? cuisines?.split(",") // Split the string by commas
      : []; // Return an empty array if no valid cuisines
  };

  const cuisinesArray = checkAvailability1(restaurantdata?.cuisinesSpeacility);

  return (
    <div className="viewsreen_container">
      <Box maxWidth="false" className="containerdivuser">
        <Stack className="editAndAddHeadClass headandback">
          <ArrowBackIcon
            onClick={() => navigate(-1)}
            style={{ cursor: "pointer" }}
          />
          <h2>View Restaurant</h2>
        </Stack>
      </Box>

      <Tabs style={{ padding: "20px" }}>
        <TabList className="TabList">
          <Tab className="Tab" onClick={() => handleType("Magic Bag")}>
            Details
          </Tab>
          <Tab className="Tab" onClick={() => handleType("Restaurant")}>
            Documents
          </Tab>
          <Tab className="Tab" onClick={() => handleType("Customer")}>
            Bank Details
          </Tab>
          <Tab className="Tab" onClick={() => handleType("Customer")}>
            Orders
          </Tab>
          <Tab className="Tab" onClick={() => handleType("Customer")}>
            Commission Calculation
          </Tab>
        </TabList>

        <TabPanel>
          <div className="MainAcoordien_live_view">
            <CAccordion alwaysOpen activeItemKey={2}>
              <CAccordionItem itemKey={2}>
                <CAccordionHeader>Restaurant Information</CAccordionHeader>
                <CAccordionBody>
                  {resturantpending ? (
                    // <div style={{ justifyContent: "center", display: "grid" }}>
                    //   <TableSk />
                    // </div>
                    <LoaderAnimation/>
                  ) : (
                    <div className="acordian_body_inside">
                      <div className="detail_view_container_fluid">
                        <div className="view_membership_field">
                          <b>Restaurant ID : </b>
                          <p className="viewpagevalues">
                            {checkAvailability(restaurantdata?.restaurantId)}
                          </p>
                        </div>
                      </div>

                      <div className="view_membership_field">
                        <b>Restaurant Name : </b>
                        <p className="viewpagevalues">
                          {checkAvailability(restaurantdata?.restaurantName)}
                        </p>
                      </div>

                      <div className="view_membership_field">
                        <b>Restaurant Owner Name : </b>
                        <p className="viewpagevalues">
                          {checkAvailability(
                            restaurantdata?.restaurantOwnerName
                          )}
                        </p>
                      </div>

                      <div className="view_membership_field">
                        <b>Restaurant Owner Email : </b>
                        <p className="viewpagevalues">
                          {checkAvailability(
                            restaurantdata?.restaurantOwnerEmail
                          )}
                        </p>
                      </div>

                      <div className="view_membership_field">
                        <b>Owner Mobile Number : </b>
                        <p className="viewpagevalues">
                          +91{" "}
                          {checkAvailability(
                            restaurantdata?.restaurantOwnerMobileNumber
                          )}
                        </p>
                      </div>

                      <div className="view_membership_field">
                        <b>Restaurant Mobile Number : </b>
                        <p className="viewpagevalues">
                          +91{" "}
                          {checkAvailability(
                            restaurantdata?.restaurantMobileNumber
                          )}
                        </p>
                      </div>

                      <div className="view_membership_field">
                        <b>Restaurant Landline Number : </b>
                        <p className="viewpagevalues">
                          {restaurantdata?.restaurantLandlineNumberCountryCode}{" "}
                          {checkAvailability(
                            restaurantdata?.restaurantLandlineNumber
                          )}
                        </p>
                      </div>

                      <div className="view_membership_field">
                        <b>Restaurant Address : </b>
                        <p className="viewpagevalues location_view">
                          <p>
                            {" "}
                            {checkAvailability(
                              restaurantdata?.restaurantAddress
                            )}
                          </p>
                          <a
                            className="location_class"
                            href={googleMapsLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaLocationDot color="red" /> view location
                          </a>
                        </p>
                      </div>

                      <div className="view_membership_field">
                        <b>Account Status : </b>
                        <CFormSelect
                          className="Status_dropdown"
                          name="status"
                          label=""
                          disabled={statusvalue == 1 || statusvalue == 2||  accountstatus == 1 || accountstatus == 2}
                          placeholder="Account Status"
                          value={statusvalue}
                          onChange={handleselectchange}
                        >
                          {statusArray?.map((item, index) => (
                            // <option key={index} value={item.value}>
                            //   {item.label}
                            // </option>

                            <option key={index} value={item.value}>
                              {statusvalue === item.value
                                ? item.value === 1
                                  ? "Accepted"
                                  : item.value === 2
                                  ? "Not Accepted"
                                  : item.label
                                : item.label}
                            </option>
                          ))}
                        </CFormSelect>
                      </div>
                    </div>
                  )}
                </CAccordionBody>
              </CAccordionItem>
            </CAccordion>

            <CAccordion alwaysOpen activeItemKey={4}>
              {/* <CAccordionItem itemKey={4}>
                                <CAccordionHeader>Restaurant Type & Timings</CAccordionHeader>
                                <CAccordionBody>
                                    <div className="acordian_body_inside">
                                        <div className="view_membership_field">
                                            <b>Cuisines : </b>
                                            <p className="viewpagevalues">
                                                {checkAvailability(restaurantdata?.cuisinesSpeacility)}
                                            </p>
                                        </div>

                                        <div className="view_membership_field">
                                            <b>Establishment Type : </b>
                                            <p className="viewpagevalues">
                                                {checkAvailability(restaurantdata?.establishmentType)}
                                            </p>
                                        </div>
                                    </div>
                                </CAccordionBody>
                            </CAccordionItem> */}

              <CAccordionItem itemKey={4}>
                <CAccordionHeader>Restaurant Type & Timings</CAccordionHeader>
                <CAccordionBody>
                  {resturantpending ? (
                    <div style={{ justifyContent: "center", display: "grid" }}>
                      <TableSk />
                    </div>
                  ) : (
                    <div className="acordian_body_inside">
                      <div className="view_membership_field">
                        <b>Cuisines: </b>
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            width: "60%",
                            flexWrap: "wrap",
                            justifyContent: "flex-end", // Align items to the right
                          }}
                        >
                          {cuisinesArray.length > 0 ? (
                            cuisinesArray.map((cuisine, index) => (
                              <div
                                key={index}
                                style={{
                                  backgroundColor: "#e5ebe5",
                                  color: "black", // Set text color to black for better visibility
                                  padding: "10px", // Add some padding
                                  borderRadius: "5px", // Optional: Add rounded corners
                                  fontSize: "14px",
                                }}
                              >
                                {cuisine.trim()} {/* Trim any extra spaces */}
                              </div>
                            ))
                          ) : (
                            <div style={{ color: "grey" }}>
                              No cuisines available
                            </div> // Message for empty data
                          )}
                        </div>
                      </div>
                      <div className="view_membership_field">
                        <b>Establishment Type : </b>
                        <p className="viewpagevalues">
                          {checkAvailability(restaurantdata?.establishmentType)}
                        </p>
                      </div>
                    </div>
                  )}
                </CAccordionBody>
              </CAccordionItem>
            </CAccordion>
          </div>
        </TabPanel>

        <TabPanel>
          <div className="MainAcoordien_live_view">
            <CAccordion alwaysOpen activeItemKey={2}>
              <CAccordionItem itemKey={2}>
                <CAccordionHeader>Documents</CAccordionHeader>
                <CAccordionBody>
                  {docpending ? (
                    <div style={{ justifyContent: "center", display: "grid" }}>
                      <TableSk />
                    </div>
                  ) : (
                    <div className="acordian_body_inside">
                      <div className="detail_view_container_fluid">
                        <div className="view_membership_field">
                          <b>FSSAI License:</b>
                          <div className="view_image_container">
                            {restaurantdatadocument?.fssaiLicence?.map(
                              (item, index) => {
                                const filePath = `${ImageUrl}${checkAvailability(
                                  item.documentPath
                                )}`;
                                const isPdf = item.documentPath
                                  .toLowerCase()
                                  .endsWith(".pdf");

                                return isPdf ? (
                                  <a
                                    key={index}
                                    href={filePath}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      cursor: "pointer",
                                      display: "block",
                                      width: "80px",
                                      height: "80px",
                                      border: "1px solid #ddd",
                                      textAlign: "center",
                                      lineHeight: "80px",
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    View PDF
                                  </a>
                                ) : (
                                  <img
                                    key={index}
                                    src={filePath}
                                    height={80}
                                    width={80}
                                    alt={`FSSAI License document ${index + 1}`}
                                    onClick={() => handleOpenImage(filePath)}
                                    style={{
                                      cursor: "pointer",
                                      border: "1px solid #ddd",
                                      borderRadius: "5px",
                                    }}
                                  />
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="view_membership_field">
                        <b>Fssai Licence Expiry Date : </b>
                        <p className="viewpagevalues">
                          {checkAvailability(
                            restaurantdatadocument?.fssaiLicenceExpiryDate
                          )}
                        </p>
                      </div>

                      <div className="view_membership_field">
                        <b>PanCard : </b>
                        {/* <div className='view_image_container'>
                                                {(restaurantdatadocument?.panCard)?.map((item, index) => {
                                                    return (
                                                        <img onClick={() => handleOpenImage(`${ImageUrl}${checkAvailability(item.documentPath)}`)} height={80} width={80} key={index} src={`${ImageUrl}${checkAvailability(item.documentPath)}`} alt="" />
                                                    )
                                                })}
                                            </div> */}

                        <div className="view_image_container">
                          {restaurantdatadocument?.panCard?.map(
                            (item, index) => {
                              const filePath = `${ImageUrl}${checkAvailability(
                                item.documentPath
                              )}`;
                              const isPdf = item.documentPath
                                .toLowerCase()
                                .endsWith(".pdf");

                              return isPdf ? (
                                <a
                                  key={index}
                                  href={filePath}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    cursor: "pointer",
                                    display: "block",
                                    width: "80px",
                                    height: "80px",
                                    border: "1px solid #ddd",
                                    textAlign: "center",
                                    lineHeight: "80px",
                                  }}
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  View PDF
                                </a>
                              ) : (
                                <img
                                  key={index}
                                  src={filePath}
                                  height={80}
                                  width={80}
                                  alt={`PAN Card document ${index + 1}`}
                                  onClick={() => handleOpenImage(filePath)}
                                  style={{
                                    cursor: "pointer",
                                    border: "1px solid #ddd",
                                    borderRadius: "5px",
                                  }}
                                />
                              );
                            }
                          )}
                        </div>
                      </div>

                      <div className="view_membership_field">
                        <b>Regular GSTin : </b>
                        {/* <div className='view_image_container'>
                                                {(restaurantdatadocument?.regularGstIn)?.map((item, index) => {
                                                    return (
                                                        <img onClick={() => handleOpenImage(`${ImageUrl}${checkAvailability(item.documentPath)}`)} height={80} width={80} key={index} src={`${ImageUrl}${checkAvailability(item.documentPath)}`} alt="" />
                                                    )
                                                })}
                                            </div> */}

                        <div className="view_image_container">
                          {restaurantdatadocument?.regularGstIn?.map(
                            (item, index) => {
                              const filePath = `${ImageUrl}${checkAvailability(
                                item.documentPath
                              )}`;
                              const isPdf = item.documentPath
                                .toLowerCase()
                                .endsWith(".pdf");

                              return isPdf ? (
                                <a
                                  key={index}
                                  href={filePath}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    cursor: "pointer",
                                    display: "block",
                                    width: "80px",
                                    height: "80px",
                                    border: "1px solid #ddd",
                                    textAlign: "center",
                                    lineHeight: "80px",
                                  }}
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  View PDF
                                </a>
                              ) : (
                                <img
                                  key={index}
                                  src={filePath}
                                  height={80}
                                  width={80}
                                  alt={`Regular GSTIN document ${index + 1}`}
                                  onClick={() => handleOpenImage(filePath)}
                                  style={{
                                    cursor: "pointer",
                                    border: "1px solid #ddd",
                                    borderRadius: "5px",
                                  }}
                                />
                              );
                            }
                          )}
                        </div>
                      </div>

                      <div className="view_membership_field">
                        <b>Food Image : </b>
                        {/* <div className='view_image_container'>
                                                {(restaurantdatadocument?.foodImage)?.map((item, index) => {
                                                    return (
                                                        <img onClick={() => handleOpenImage(`${ImageUrl}${checkAvailability(item.documentPath)}`)} height={80} width={80} key={index} src={`${ImageUrl}${checkAvailability(item.documentPath)}`} alt="foodImage" />
                                                    )
                                                })}
                                            </div> */}
                        <div className="view_image_container">
                          {restaurantdatadocument?.foodImage?.map(
                            (item, index) => {
                              const filePath = `${ImageUrl}${checkAvailability(
                                item.documentPath
                              )}`;
                              const isPdf = item.documentPath
                                .toLowerCase()
                                .endsWith(".pdf");

                              return isPdf ? (
                                <a
                                  key={index}
                                  href={filePath}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    cursor: "pointer",
                                    display: "block",
                                    width: "80px",
                                    height: "80px",
                                    border: "1px solid #ddd",
                                    textAlign: "center",
                                    lineHeight: "80px",
                                  }}
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  View PDF
                                </a>
                              ) : (
                                <img
                                  key={index}
                                  src={filePath}
                                  height={80}
                                  width={80}
                                  alt={`Food Image ${index + 1}`}
                                  onClick={() => handleOpenImage(filePath)}
                                  style={{
                                    cursor: "pointer",
                                    border: "1px solid #ddd",
                                    borderRadius: "5px",
                                  }}
                                />
                              );
                            }
                          )}
                        </div>
                      </div>

                      <div className="view_membership_field">
                        <b>Restaurant Logo : </b>
                        {/* <div className='view_image_container'>
                                                {(restaurantdatadocument?.restaurantLogo)?.map((item, index) => {
                                                    return (
                                                        <img onClick={() => handleOpenImage(`${ImageUrl}${checkAvailability(item.documentPath)}`)} height={80} width={80} key={index} src={`${ImageUrl}${checkAvailability(item.documentPath)}`} alt="foodImage" />
                                                    )
                                                })}
                                            </div> */}

                        <div className="view_image_container">
                          {restaurantdatadocument?.restaurantLogo?.map(
                            (item, index) => {
                              const filePath = `${ImageUrl}${checkAvailability(
                                item.documentPath
                              )}`;
                              const isPdf = item.documentPath
                                .toLowerCase()
                                .endsWith(".pdf");

                              return isPdf ? (
                                <a
                                  key={index}
                                  href={filePath}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    cursor: "pointer",
                                    display: "block",
                                    width: "80px",
                                    height: "80px",
                                    border: "1px solid #ddd",
                                    textAlign: "center",
                                    lineHeight: "80px",
                                  }}
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  View PDF
                                </a>
                              ) : (
                                <img
                                  key={index}
                                  src={filePath}
                                  height={80}
                                  width={80}
                                  alt={`Restaurant Logo ${index + 1}`}
                                  onClick={() => handleOpenImage(filePath)}
                                  style={{
                                    cursor: "pointer",
                                    border: "1px solid #ddd",
                                    borderRadius: "5px",
                                  }}
                                />
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </CAccordionBody>
              </CAccordionItem>
            </CAccordion>
          </div>
        </TabPanel>

        <TabPanel>
          <div className="MainAcoordien_live_view">
            <CAccordion alwaysOpen activeItemKey={2}>
              <CAccordionItem itemKey={2}>
                <CAccordionHeader>Bank Details</CAccordionHeader>
                <CAccordionBody>
                  {bankpending ? (
                    <div style={{ justifyContent: "center", display: "grid" }}>
                      <TableSk />
                    </div>
                  ) : (
                    <div className="acordian_body_inside">
                      <div className="view_membership_field">
                        <b>Bank Name : </b>
                        <p className="viewpagevalues">
                          {checkAvailability(restaurantdatadocument?.bankName)}
                        </p>
                      </div>

                      <div className="view_membership_field">
                        <b>Bank Account Number : </b>
                        <p className="viewpagevalues">
                          {checkAvailability(
                            restaurantdatadocument?.account_number
                          )}
                        </p>
                      </div>

                      <div className="view_membership_field">
                        <b>Account Holder Name : </b>
                        <p className="viewpagevalues">
                          {checkAvailability(
                            restaurantdatadocument?.account_holder_name
                          )}
                        </p>
                      </div>

                      <div className="view_membership_field">
                        <b>IFSC code : </b>
                        <p className="viewpagevalues">
                          {checkAvailability(restaurantdatadocument?.ifsc_code)}
                        </p>
                      </div>

                      <div className="view_membership_field">
                        <b>Branch Name : </b>
                        <p className="viewpagevalues">
                          {checkAvailability(
                            restaurantdatadocument?.branch_name
                          )}
                        </p>
                      </div>
                    </div>
                  )}
                </CAccordionBody>
              </CAccordionItem>
            </CAccordion>
          </div>
        </TabPanel>

        <TabPanel>
          <div className="MainAcoordien_live_view">
            <CAccordion alwaysOpen activeItemKey={3}>
              <CAccordionItem itemKey={3}>
                <CAccordionHeader>Orders</CAccordionHeader>
                <CAccordionBody>
                  <LiveVendorOrder></LiveVendorOrder>
                </CAccordionBody>
              </CAccordionItem>
            </CAccordion>
          </div>
        </TabPanel>

        <TabPanel>
          <div className="MainAcoordien_live_view">
            <CommisionCalulation id={id} />
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default ViewLiveRestuarant;
