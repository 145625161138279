import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CFormSelect,
} from "@coreui/react";
import { Box, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ImageUrl, restaurantView_api } from "../../API/baseUrl";
import ViewScreen from "../../components/viewScreen";
import { restaurantViewRequest } from "../../redux/actions";
import ToastMessage from "../../utils/ToastMessage";
import "./index.css";
import StatusModal from "./modal";
import { FaLocationDot } from "react-icons/fa6";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TableSk } from "../skelaton/tableSk";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";

const ViewRestuarant = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [restaurantdata, setRestaurantdata] = useState("");
  const [statusvalue, setStatusvalue] = useState("");
  const [accountstatus, setAccountstatus] = useState("");
  const [visible, setVisible] = useState(false);
  const [pending, setPending] = useState(true);

  const { id } = useParams();
  const { restaurantViewdata } = useSelector(
    (state) => state?.restaurantViewreduser || {}
  );

  console.log("restaurantViewdata", restaurantViewdata);

  useEffect(() => {
    const parameter = {
      url: `${restaurantView_api}${id}`,
    };
    dispatch(restaurantViewRequest(parameter));
  }, [id]);

  useEffect(() => {
    if (restaurantViewdata?.status) {
      setPending(false)
      setRestaurantdata(restaurantViewdata?.data);
      setStatusvalue(restaurantViewdata?.data?.accountStatus);
    } else {
      ToastMessage("error", restaurantViewdata?.message);
    }
  }, [restaurantViewdata]);

  const checkAvailability = (data) => data || "-";

  // const statusArray = [
  //   {
  //     label: "Pending",
  //     value: 0,
  //   },
  //   {
  //     label: "Accepted",
  //     value: 1,
  //   },
  //   {
  //     label: "Not-Accepted",
  //     value: 2,
  //   },
  // ];

  const statusArray = [
    {
      label: "Pending",
      value: 0,
    },
    {
      label: "Accept",
      value: 1,
    },
    {
      label: "Not-Accept",
      value: 2,
    },
  ];

  const handleOpenImage = (url) => {
    const newWindow = window.open(url, "_blank");
    if (newWindow) {
      newWindow.focus();
    }
  };
  const handleselectchange = (e) => {
    const selectedStatus = e.target.value;
    setAccountstatus(e.target.value);
    setVisible(true);
  };

  const googleMapsLink = `https://www.google.com/maps?q=${restaurantdata?.restaurantAddressLatitude},${restaurantdata?.restaurantAddressLongitude}`;
  // Function to check availability and convert to array
  const checkAvailability1 = (cuisines) => {
    // Check if cuisines is not undefined, null, or empty and split it into an array
    return cuisines && typeof cuisines === "string" && cuisines?.trim() !== ""
      ? cuisines?.split(",") // Split the string by commas
      : []; // Return an empty array if no valid cuisines
  };

  // Get the array of cuisines
  const cuisinesArray = checkAvailability1(restaurantdata?.cuisinesSpeacility);

  return (
    <div className="viewsreen_container">
      <Box maxWidth="false" className="containerdivuser">
        <Stack className="editAndAddHeadClass headandback">
          <ArrowBackIcon onClick={() => navigate(-1)} style={{ cursor: "pointer" }} />
          <h2>View Restaurant</h2>
        </Stack>
      </Box>

      {visible ? (
        <StatusModal
          {...{ visible, setVisible, setStatusvalue }}
          restaurantId={id}
          statusId={accountstatus}
        />
      ) : (
        ""
      )}

      <CAccordion alwaysOpen activeItemKey={2}>
        <CAccordionItem itemKey={2}>
          <CAccordionHeader>Restaurant Information</CAccordionHeader>
          <CAccordionBody>

            {pending ? (
              // <div style={{ justifyContent: "center", display: "grid" }}>
              //   <TableSk />
              // </div>
              <LoaderAnimation/>
            ) : (
              <div className="acordian_body_inside">
                <div className="detail_view_container_fluid">
                  <div className="view_membership_field">
                    <b>Restaurant ID : </b>
                    <p className="viewpagevalues">
                      {checkAvailability(restaurantdata?.restaurantId)}
                    </p>
                  </div>
                </div>

                <div className="view_membership_field">
                  <b>Restaurant Name : </b>
                  <p className="viewpagevalues">
                    {checkAvailability(restaurantdata?.restaurantName)}
                  </p>
                </div>

                <div className="view_membership_field">
                  <b>Restaurant Owner Name : </b>
                  <p className="viewpagevalues">
                    {checkAvailability(restaurantdata?.restaurantOwnerName)}
                  </p>
                </div>

                <div className="view_membership_field">
                  <b>Restaurant Owner Email : </b>
                  <p className="viewpagevalues">
                    {checkAvailability(restaurantdata?.restaurantOwnerEmail)}
                  </p>
                </div>

                <div className="view_membership_field">
                  <b>Owner Mobile Number : </b>
                  <p className="viewpagevalues">
                    +91{" "}
                    {checkAvailability(
                      restaurantdata?.restaurantOwnerMobileNumber
                    )}
                  </p>
                </div>

                <div className="view_membership_field">
                  <b>Restaurant Mobile Number : </b>
                  <p className="viewpagevalues">
                    +91{" "}
                    {checkAvailability(restaurantdata?.restaurantMobileNumber)}
                  </p>
                </div>

                {/* <div className="view_membership_field">
            <b>Restaurant STD code : </b>
            <p className="viewpagevalues">
          {checkAvailability(restaurantdata?.restaurantLandlineNumberCountryCode)}
            </p>
          </div> */}

                <div className="view_membership_field">
                  <b>Restaurant Landline Number : </b>
                  <p className="viewpagevalues">
                    {restaurantdata?.restaurantLandlineNumberCountryCode}{" "}
                    {checkAvailability(restaurantdata?.restaurantLandlineNumber)}
                  </p>
                </div>

                {/* <div className="view_membership_field">
                <b>Restaurant Latitude : </b>
                <p className="viewpagevalues">
                  {checkAvailability(restaurantdata?.restaurantAddressLatitude)}
                </p>
              </div>

              <div className="view_membership_field">
                <b>Restaurant Longitude : </b>
                <p className="viewpagevalues">
                  {checkAvailability(
                    restaurantdata?.restaurantAddressLongitude
                  )}
                </p>
              </div> */}

                <div className="view_membership_field">
                  <b>Restaurant Address : </b>
                  <p className="viewpagevalues location_view">
                    <p> {checkAvailability(restaurantdata?.restaurantAddress)}</p>
                    <a
                      href={googleMapsLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaLocationDot color="red" /> view location
                    </a>
                  </p>
                </div>

                <div className="view_membership_field">
                  <b>Account Status : </b>
                  <CFormSelect
                    className="Status_dropdown"
                    name="status"
                    label=""
                    disabled={statusvalue == 1 || statusvalue == 2||  accountstatus == 1 || accountstatus == 2}
                    placeholder="Account Status"
                    value={statusvalue}
                    onChange={handleselectchange}
                  >
                    {statusArray?.map((item, index) => (
                      // <option key={index} value={item.value}>
                      //   {item.label}
                      // </option>

                      <option key={index} value={item.value}>
                        {statusvalue === item.value
                          ? item.value === 1
                            ? "Accepted"
                            : item.value === 2
                              ? "Not Accepted"
                              : item.label
                          : item.label}
                      </option>
                    ))}
                  </CFormSelect>
                </div>
              </div>
            )}
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>

      <CAccordion alwaysOpen activeItemKey={4}>
        <CAccordionItem itemKey={4}>
          <CAccordionHeader>Restaurant Type & Timings</CAccordionHeader>
          <CAccordionBody>
            <div className="acordian_body_inside">

              <div className="view_membership_field">
                <b>Cuisines: </b>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    width: "60%",
                    flexWrap: "wrap",
                    justifyContent: "flex-end", // Align items to the right
                  }}
                >
                  {cuisinesArray.length > 0 ? (
                    cuisinesArray.map((cuisine, index) => (
                      <div
                        key={index}
                        style={{
                          backgroundColor: "#e5ebe5",
                          color: "black", // Set text color to black for better visibility
                          padding: "10px", // Add some padding
                          borderRadius: "5px", // Optional: Add rounded corners
                          fontSize: "14px",
                        }}
                      >
                        {cuisine.trim()} {/* Trim any extra spaces */}
                      </div>
                    ))
                  ) : (
                    <div style={{ color: "grey" }}>No cuisines available</div> // Message for empty data
                  )}
                </div>

              </div>
              <div className="view_membership_field">
                <b>Establishment Type : </b>
                {pending ? (
                    <div style={{ justifyContent: "center", display: "grid" }}>
                      <TableSk />
                    </div>
                  ) : (
                <p className="viewpagevalues">

                  
                  {checkAvailability(restaurantdata?.establishmentType)}
                </p>
                  )}
              </div>
            </div>
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
    </div>
  );
};

export default ViewRestuarant;
