



import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useEffect, useState } from "react";
import { CButton, CCol, CContainer, CForm, CFormInput, CRow } from "@coreui/react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { get_restaurantType_api, typecreate_api, update_type_api } from "../../API/baseUrl";
import { editTypeRequest, removeDataFromReducer, typeDetailsRequest, typeadd } from "../../redux/actions";
import ToastMessage from "../../utils/ToastMessage";
import CircularProgress from "@mui/material/CircularProgress";
import { addtypeshema } from "../../utils/schema";

const RestaurantTypeAdd = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { typeAdddata } = useSelector((state) => state?.typeAddReduser || {});

  const { typeupdatedata } = useSelector((state) => state?.typeupdateReduser || {});

  const { typedetailsdata } = useSelector((state) => state?.typedetailsReduser || {});

  const [loading, setLoading] = useState(false); // State for managing loader

  const initialValues = {
    type: "",
  };

 
  const formik = useFormik({
    initialValues,
    validationSchema: addtypeshema,
    onSubmit: (values) => {
      ApiHandlesubmit(values);
    },
  });

  useEffect(() => {
    if (typeAdddata?.status) {
      ToastMessage("success", typeAdddata?.message);
      dispatch(removeDataFromReducer("TYPE_ADD_RESET"));
      navigate("/dashboard/type");
      setLoading(false); 
    } else {
      ToastMessage("error", typeAdddata?.message);
      setLoading(false); 
    }
  }, [typeAdddata]);


 useEffect(() => {
    if (typeupdatedata?.status) {
      ToastMessage("success", typeupdatedata?.message);
      dispatch(removeDataFromReducer("TYPE_UPDATE_RESET"));
      dispatch(removeDataFromReducer("TYPE_DETAILS_RESET"));
      
      navigate("/dashboard/type");
      setLoading(false); 
    } else {
      ToastMessage("error", typeupdatedata?.message);
      setLoading(false); 
    }
  }, [typeupdatedata]);


  useEffect(() => {
    if (typedetailsdata?.status) {
      formik.values.type = typedetailsdata?.data?.type
      formik.setFieldValue("type" , typedetailsdata?.data?.type)

    } 

  }, [typedetailsdata]);


  const ApiHandlesubmit = async () => {
    setLoading(true)
    const parameter = {
      type: formik.values.type,
      url: id ? `${update_type_api}${id}` : typecreate_api ,
    };
    if(id){
      dispatch(editTypeRequest(parameter));
    }
    else{
      dispatch(typeadd(parameter));
    }
  };


  
  useEffect(() => {
    const parameter = {
      url: `${get_restaurantType_api}${id}`,
    };
    dispatch(typeDetailsRequest(parameter));
  }, [id]);

  const handleBackNavigation = () => {
    navigate(-1); // Navigates back to the previous page
  };

  return (
    <CForm onSubmit={formik.handleSubmit}>
      <h3 className="AddEdit_Heading">  <ArrowBackIcon onClick={handleBackNavigation} style={{cursor:"pointer"}}/>{id ? "Edit Restaurant Type" : "Add Restaurant Type"}</h3>

      <CContainer className="add_form_container">
        <CRow xs={{ gutter: 4 }}>
          <CCol sm={12} md={12} lg={12}>
            <CFormInput
              name="type"
              type="text"
              value={formik.values.type}
              id="exampleFormControlInput1"
              label="Restaurant Type *"
              placeholder="Restaurant Type"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              aria-describedby="exampleFormControlInputHelpInline"
              autoComplete="off"
              invalid={formik.touched.type && !!formik.errors.type}
            />
            {formik.touched.type && formik.errors.type ? (
              <div className="invalid-feedback">{formik.errors.type}</div>
            ) : null}
          </CCol>
        </CRow>

        <div className="d-grid gap-2 d-md-flex justify-content-md-end py-5">
        <CButton style={{
            backgroundColor: "var(--colorused)",
            border: "none",
            width: "120px",
            color: "var(--colorWhite)",
          }} type="submit" className="me-md-2">
            {loading ? (
              <CircularProgress size={24} style={{ color: "white" }} />
            ) :

              <>{id ? "Update" : "Submit"}</>
            }

          </CButton>
        </div>
      </CContainer>
    </CForm>
  );
};

export default RestaurantTypeAdd;





