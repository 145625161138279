import { combineReducers } from "redux";
import * as type from "../../type";
import CreateOrderList from "../../../views/orderList/createOrderList";

const initialState = {};

const loginUser = (state = initialState, action) => {
  switch (action.type) {
    case type.LOGIN_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: true,
        loginInfo: action.data,
      };
    case type.LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.LOGIN_USER_RESET:
        return {
          ...state,
          loading: false,
          loginInfo: null,
        };
    default:
      return state;
  }
};

const sendemail = (state = initialState, action) => {
  switch (action.type) {
    case type.SEND_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.SEND_EMAIL_SUCCESS:
      return {
        ...state,
        loading: true,
        sendemailInfo: action.data,
      };
    case type.SEND_EMAIL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    default:
      return state;
  }
};

const countryList = (state = initialState, action) => {
  switch (action.type) {
    case type.COUNTRY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        countryListInfo: action.data,
      };
    case type.COUNTRY_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    default:
      return state;
  }
};

const stateList = (state = initialState, action) => {
  switch (action.type) {
    case type.STATE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.STATE_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        stateListInfo: action.data,
      };
    case type.STATE_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    default:
      return state;
  }
};

const cityList = (state = initialState, action) => {
  switch (action.type) {
    case type.CITY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.CITY_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        cityListInfo: action.data,
      };
    case type.CITY_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    default:
      return state;
  }
};

const vendorList = (state = initialState, action) => {
  
  switch (action.type) {
    case type.VENDOR_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.VENDOR_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        vendorListInfo: action.data,
      };
    case type.VENDOR_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    default:
      return state;
  }
};

const countrystatus = (state = initialState, action) => {
  switch (action.type) {
    case type.COUNTRY_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.COUNTRY_STATUS_SUCCESS:
      return {
        ...state,
        loading: true,
        countrystatusdata: action.data,
      };
    case type.COUNTRY_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.COUNTRY_STATUS_RESET:
      return {
        ...state,
        loading: false,
        countrystatusdata: null,
      };
    default:
      return state;
  }
};

const statestatus = (state = initialState, action) => {
  switch (action.type) {
    case type.STATE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.STATE_STATUS_SUCCESS:
      return {
        ...state,
        loading: true,
        statestatusdata: action.data,
      };
    case type.STATE_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.STATE_STATUS_RESET:
      return {
        ...state,
        loading: false,
        statestatusdata: null,
      };
    default:
      return state;
  }
};

const citystatus = (state = initialState, action) => {
  switch (action.type) {
    case type.CITY_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.CITY_STATUS_SUCCESS:
      return {
        ...state,
        loading: true,
        citystatusdata: action.data,
      };
    case type.CITY_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.CITY_STATUS_RESET:
      return {
        ...state,
        loading: false,
        citystatusdata: null,
      };
    default:
      return state;
  }
};

const countryaddreduser = (state = initialState, action) => {
  switch (action.type) {
    case type.ADD_COUNTRY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.ADD_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: true,
        createcountrydata: action.data,
      };
    case type.ADD_COUNTRY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.ADD_COUNTRY_RESET:
      return {
        ...state,
        loading: false,
        createcountrydata: null,
      };
    default:
      return state;
  }
};

const stateaddreduser = (state = initialState, action) => {
  switch (action.type) {
    case type.ADD_STATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.ADD_STATE_SUCCESS:
      return {
        ...state,
        loading: true,
        addstatedata: action.data,
      };
    case type.ADD_STATE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.ADD_STATE_RESET:
      return {
        ...state,
        loading: false,
        addstatedata: null,
      };
    default:
      return state;
  }
};

const cityaddreduser = (state = initialState, action) => {
  switch (action.type) {
    case type.ADD_CITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.ADD_CITY_SUCCESS:
      return {
        ...state,
        loading: false,
        citycreatedata: action.data,
      };
    case type.ADD_CITY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.ADD_CITY_RESET:
      return {
        ...state,
        loading: false,
        citycreatedata: null,
      };
    default:
      return state;
  }
};

const specialityaddreduser = (state = initialState, action) => {
  switch (action.type) {
    case type.ADD_SPECIALITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.ADD_SPECIALITY_SUCCESS:
      return {
        ...state,
        loading: true,
        addspecialitydata: action.data,
      };
    case type.ADD_SPECIALITY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.ADD_SPECIALITY_RESET:
      return {
        ...state,
        loading: false,
        addspecialitydata: null,
      };
    default:
      return state;
  }
};

const activecountrylistreduser = (state = initialState, action) => {
  switch (action.type) {
    case type.ACTIVE_COUNTRYLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.ACTIVE_COUNTRYLIST_SUCCESS:
      return {
        ...state,
        loading: true,
        activecountrydata: action.data,
      };
    case type.ACTIVE_COUNTRYLIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    default:
      return state;
  }
};

const activestatelistreduser = (state = initialState, action) => {
  switch (action.type) {
    case type.ACTIVE_STATELIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.ACTIVE_STATELIST_SUCCESS:
      return {
        ...state,
        loading: true,
        activestatedata: action.data,
      };
    case type.ACTIVE_STATELIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    default:
      return state;
  }
};

const specialitylistreduser = (state = initialState, action) => {
  switch (action.type) {
    case type.SPECIALITY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.SPECIALITY_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        specialitylistdata: action.data,
      };
    case type.SPECIALITY_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    default:
      return state;
  }
};

const specialitystatusreduser = (state = initialState, action) => {
  switch (action.type) {
    case type.SPECIALITY_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.SPECIALITY_STATUS_SUCCESS:
      return {
        ...state,
        loading: true,
        specialitystatusdata: action.data,
      };
    case type.SPECIALITY_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.SPECIALITY_STATUS_RESET:
      return {
        ...state,
        loading: false,
        specialitystatusdata: null,
      };
    default:
      return state;
  }
};

const restaurantViewreduser = (state = initialState, action) => {
  switch (action.type) {
    case type.RESTAURANT_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.RESTAURANT_VIEW_SUCCESS:
      return {
        ...state,
        loading: true,
        restaurantViewdata: action.data,
      };
    case type.RESTAURANT_VIEW_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    // case type.RESTAURANT_VIEW_RESET:
    //   return {
    //     ...state,
    //     loading: false,
    //     specialitystatusdata: null,
    //   };
    default:
      return state;
  }
};


const restauranttypeListreduser = (state = initialState, action) => {
  switch (action.type) {
    case type.RESTAURANT_TYPELIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.RESTAURANT_TYPELIST_SUCCESS:
      return {
        ...state,
        loading: true,
        TypeListdata: action.data,
      };
    case type.RESTAURANT_TYPELIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    // case type.RESTAURANT_VIEW_RESET:
    //   return {
    //     ...state,
    //     loading: false,
    //     specialitystatusdata: null,
    //   };
    default:
      return state;
  }
};


const accountStatusReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.ACCOUNT_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.ACCOUNT_STATUS_SUCCESS:
      return {
        ...state,
        loading: true,
       accountStatusdata: action.data,
      };
    case type.ACCOUNT_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.ACCOUNT_STATUS_RESET:
      return {
        ...state,
        loading: false,
        accountStatusdata: null,
      };
    default:
      return state;
  }
};


const getCityReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.CITY_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.CITY_DETAILS_SUCCESS:
      return {
        ...state,
        loading: true,
        getCitydata: action.data,
      };
    case type.CITY_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.CITY_DETAILS_RESET:
      return {
        ...state,
        loading: false,
        getCitydata: null,
      };
    default:
      return state;
  }
};


const SpecialityUpdateReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.SPECIALITY_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        updateSpecialitydata: null,
      };
    case type.SPECIALITY_UPDATE_SUCCESS:
      return {
        ...state,
        loading: true,
        updateSpecialitydata: action.data,
      };
    case type.SPECIALITY_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.SPECIALITY_UPDATE_RESET:
      return {
        ...state,
        loading: false,
        updateSpecialitydata: null,
      };
    default:
      return state;
  }
};

const SpecialitydetailsReduser = (state = initialState, action) => {
  
  switch (action.type) {
    case type.SPECIALITY_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        detailSpecialitydata: null,
      };
    case type.SPECIALITY_DETAILS_SUCCESS:
      return {
        ...state,
        loading: true,
        detailSpecialitydata: action.data,
      };
    case type.SPECIALITY_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.SPECIALITY_DETAILS_RESET:
      return {
        ...state,
        loading: false,
        detailSpecialitydata: null,
      };
    default:
      return state;
  }
};

const getCountryyReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.COUNTRY_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.COUNTRY_DETAILS_SUCCESS:
      return {
        ...state,
        loading: true,
        getCountrydata: action.data,
      };
    case type.COUNTRY_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.COUNTRY_DETAILS_RESET:
      return {
        ...state,
        loading: false,
        getCountrydata: null,
      };
    default:
      return state;
  }
};

const getStateReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.STATE_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.STATE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: true,
        getstatedata: action.data,
      };
    case type.STATE_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.STATE_DETAILS_RESET:
      return {
        ...state,
        loading: false,
        getstatedata: null,
      };
    default:
      return state;
  }
};

const EditCityReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.CITY_EDIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.CITY_EDIT_SUCCESS:
      return {
        ...state,
        loading: true,
        editCitydata: action.data,
      };
    case type.CITY_EDIT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.CITY_EDIT_RESET:
      return {
        ...state,
        loading: false,
        editCitydata: null,
      };
    default:
      return state;
  }
};
const EditStateReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.STATE_EDIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.STATE_EDIT_SUCCESS:
      return {
        ...state,
        loading: true,
        editStatedata: action.data,
      };
    case type.STATE_EDIT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.STATE_EDIT_RESET:
      return {
        ...state,
        loading: false,
        editStatedata: null,
      };
    default:
      return state;
  }
};

const EditcountryReduser = (state = initialState, action) => {

  switch (action.type) {
    case type.COUNTRY_EDIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.COUNTRY_EDIT_SUCCESS:
      return {
        ...state,
        loading: true,
        editCountrydata: action.data,
      };
    case type.COUNTRY_EDIT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.COUNTRY_EDIT_RESET:
      return {
        ...state,
        loading: false,
        editCountrydata: null,
      };
    default:
      return state;
  }
};


const documentListReduser = (state = initialState, action) => {
 
  switch (action.type) {
    case type.DOCUMENT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.DOCUMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        documentListdata: action.data,
      };
    case type.DOCUMENT_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.DOCUMENT_LIST_RESET:
      return {
        ...state,
        loading: false,
        documentListdata: null,
      };
    default:
      return state;
  }
};


const documentviewListReduser = (state = initialState, action) => {
 
  switch (action.type) {
    case type.DOCUMENT_VIEWLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.DOCUMENT_VIEWLIST_SUCCESS:
      return {
        ...state,
        loading: true,
        documentviewListdata: action.data,
      };
    case type.DOCUMENT_VIEWLIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.DOCUMENT_VIEWLIST_RESET:
      return {
        ...state,
        loading: false,
        documentviewListdata: null,
      };
    default:
      return state;
  }
};

const bagSizeListReduser = (state = initialState, action) => {
 
  switch (action.type) {
    case type.BAGSIZE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.BAGSIZE_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        surpricebagListdata: action.data,
      };
    case type.BAGSIZE_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.BAGSIZE_LIST_RESET:
      return {
        ...state,
        loading: false,
        surpricebagListdata: null,
      };
    default:
      return state;
  }
};


const bagsizeAddReduser = (state = initialState, action) => {
 
  switch (action.type) {
    case type.BAGSIZE_ADD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.BAGSIZE_ADD_SUCCESS:
      return {
        ...state,
        loading: true,
        surpricebagAdddata: action.data,
      };
    case type.BAGSIZE_ADD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.BAGSIZE_ADD_RESET:
      return {
        ...state,
        loading: false,
        surpricebagAdddata: null,
      };
    default:
      return state;
  }
};



const typeListReduser = (state = initialState, action) => {

  switch (action.type) {
    case type.TYPE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.TYPE_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        RestaurantTypeData: action.data,
      };
    case type.TYPE_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.TYPE_LIST_RESET:
      return {
        ...state,
        loading: false,
        RestaurantTypeData:  action.data,
      };
    default:
      return state;
  }
};

const activetypeListReduser = (state = initialState, action) => {

  switch (action.type) {
    case type.ACTIVETYPE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.ACTIVETYPE_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        RestaurantactiveTypeData: action.data,
      };
    case type.ACTIVETYPE_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.ACTIVETYPE_LIST_RESET:
      return {
        ...state,
        loading: false,
        RestaurantactiveTypeData:  null,
      };
    default:
      return state;
  }
};


const typeAddReduser = (state = initialState, action) => {
 
  switch (action.type) {
    case type.TYPE_ADD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.TYPE_ADD_SUCCESS:
      return {
        ...state,
        loading: true,
        typeAdddata: action.data,
      };
    case type.TYPE_ADD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.TYPE_ADD_RESET:
      return {
        ...state,
        loading: false,
        typeAdddata: null,
      };
    default:
      return state;
  }
};

const typestatusReduser = (state = initialState, action) => {
 
  switch (action.type) {
    case type.TYPE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.TYPE_STATUS_SUCCESS:
      return {
        ...state,
        loading: true,
        typestatusdata: action.data,
      };
    case type.TYPE_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.TYPE_STATUS_RESET:
      return {
        ...state,
        loading: false,
        typestatusdata: null,
      };
    default:
      return state;
  }
};


const bagsizeStatusReduser = (state = initialState, action) => {
 
  switch (action.type) {
    case type.BAGSIZE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.BAGSIZE_STATUS_SUCCESS:
      return {
        ...state,
        loading: true,
        bagsizestatusdata: action.data,
      };
    case type.BAGSIZE_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.BAGSIZE_STATUS_RESET:
      return {
        ...state,
        loading: false,
        bagsizestatusdata: null,
      };
    default:
      return state;
  }
};

const bagsizedetailsReduser = (state = initialState, action) => {
 
  switch (action.type) {
    case type.BAGSIZE_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.BAGSIZE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: true,
        bagsizedetailsdata: action.data,
      };
    case type.BAGSIZE_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.BAGSIZE_DETAILS_RESET:
      return {
        ...state,
        loading: false,
        bagsizedetailsdata: null,
      };
    default:
      return state;
  }
};



const bagsizeupdateReduser = (state = initialState, action) => {
 
  switch (action.type) {
    case type.BAGSIZE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.BAGSIZE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: true,
        bagsizeupdatedata: action.data,
      };
    case type.BAGSIZE_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.BAGSIZE_UPDATE_RESET:
      return {
        ...state,
        loading: false,
        bagsizeupdatedata: null,
      };
    default:
      return state;
  }
};


const typedetailsReduser = (state = initialState, action) => {
 
  switch (action.type) {
    case type.TYPE_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.TYPE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: true,
        typedetailsdata: action.data,
      };
    case type.TYPE_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.TYPE_DETAILS_RESET:
      return {
        ...state,
        loading: false,
        typedetailsdata: null,
      };
    default:
      return state;
  }
};



const supportStatusReduser = (state = initialState, action) => {

  switch (action.type) {
    case type.SUPPORT_STATUSEDIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.SUPPORT_STATUSEDIT_SUCCESS:
      return {
        ...state,
        loading: true,
        supportStatusdata: action.data,
      };
    case type.SUPPORT_STATUSEDIT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.SUPPORT_STATUSEDIT_RESET:
      return {
        ...state,
        loading: false,
        supportStatusdata: null,
      };
    default:
      return state;
  }
};



const typeupdateReduser = (state = initialState, action) => {
 
  switch (action.type) {
    case type.TYPE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.TYPE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: true,
        typeupdatedata: action.data,
      };
    case type.TYPE_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.TYPE_UPDATE_RESET:
      return {
        ...state,
        loading: false,
        typeupdatedata: null,
      };
    default:
      return state;
  }
};

const orderListReduser = (state = initialState, action) => {
 
  switch (action.type) {
    case type.ORDER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.ORDER_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        orderlistdata: action.data,
      };
    case type.ORDER_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.ORDER_LIST_RESET:
      return {
        ...state,
        loading: false,
        orderlistdata: null,
      };
    default:
      return state;
  }
};



const faqAddReduser = (state = initialState, action) => {
 
  switch (action.type) {
    case type.ADD_FAQ_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.ADD_FAQ_SUCCESS:
      return {
        ...state,
        loading: true,
        createfaqdata: action.data,
      };
    case type.ADD_FAQ_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.ADD_FAQ_RESET:
      return {
        ...state,
        loading: false,
        createfaqdata: null,
      };
    default:
      return state;
  }
};

const faqtypelistReduser = (state = initialState, action) => {
 
  switch (action.type) {
    case type.FAQ_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.FAQ_TYPE_SUCCESS:
      return {
        ...state,
        loading: true,
        faqtypedata: action.data,
      };
    case type.FAQ_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.FAQ_TYPE_RESET:
      return {
        ...state,
        loading: false,
        faqtypedata: null,
      };
    default:
      return state;
  }
};

const faqlistReduser = (state = initialState, action) => {
 
  switch (action.type) {
    case type.FAQ_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.FAQ_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        faqListdata: action.data,
      };
    case type.FAQ_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.FAQ_LIST_RESET:
      return {
        ...state,
        loading: false,
        faqListdata: null,
      };
    default:
      return state;
  }
};
const createorderlistReduser = (state = initialState, action) => {
 
  switch (action.type) {
    case type.CREATE_ORDER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.CREATE_ORDER_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        CreateOrderListdata: action.data,
      };
    case type.CREATE_ORDER_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.CREATE_ORDER_LIST_RESET:
      return {
        ...state,
        loading: false,
        CreateOrderListdata: null,
      };
    default:
      return state;
  }
};

const supportListReduser = (state = initialState, action) => {
 
  switch (action.type) {
    case type.SUPPORTTICKET_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.SUPPORTTICKET_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        supportListdata: action.data,
      };
    case type.SUPPORTTICKET_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.SUPPORTTICKET_LIST_RESET:
      return {
        ...state,
        loading: false,
        supportListdata: null,
      };
    default:
      return state;
  }
};

const getsupportStatusReduser = (state = initialState, action) => {
 
  switch (action.type) {
    case type.GETSUPPORT_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.GETSUPPORT_STATUS_SUCCESS:
      return {
        ...state,
        loading: true,
        supportStatusdata: action.data,
      };
    case type.GETSUPPORT_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.GETSUPPORT_STATUS_RESET:
      return {
        ...state,
        loading: false,
        supportStatusdata: null,
      };
    default:
      return state;
  }
};



const settingReduser = (state = initialState, action) => {
 
  switch (action.type) {
    case type.CREATE_SETTING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.CREATE_SETTING_SUCCESS:
      return {
        ...state,
        loading: true,
        settingdata: action.data,
      };
    case type.CREATE_SETTING_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.CREATE_SETTING_RESET:
      return {
        ...state,
        loading: false,
        settingdata: null,
      };
    default:
      return state;
  }
};


const getsettingReduser = (state = initialState, action) => {
 
  switch (action.type) {
    case type.GET_SETTING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.GET_SETTING_SUCCESS:
      return {
        ...state,
        loading: true,
        getsettingdata: action.data,
      };
    case type.GET_SETTING_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.GET_SETTING_RESET:
      return {
        ...state,
        loading: false,
        getsettingdata: null,
      };
    default:
      return state;
  }
};

const chatSendReduser = (state = initialState, action) => {
 
  switch (action.type) {
    case type.CHAT_SEND_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.CHAT_SEND_SUCCESS:
      return {
        ...state,
        loading: true,
        chatsenddata: action.data,
      };
    case type.CHAT_SEND_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.CHAT_SEND_RESET:
      return {
        ...state,
        loading: false,
        chatsenddata: null,
      };
    default:
      return state;
  }
};

const chatGetReduser = (state = initialState, action) => {
 
  switch (action.type) {
    case type.CHAT_GET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.CHAT_GET_SUCCESS:
      return {
        ...state,
        loading: true,
        getChatdata: action.data,
      };
    case type.CHAT_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.CHAT_GET_RESET:
      return {
        ...state,
        loading: false,
        getChatdata: null,
      };
    default:
      return state;
  }
};


const  ExcleDownloadOrder = (state = initialState, action) => {

  switch (action.type) {
    case type.Excle_Download_REQUEST:
      return {
        ...state,
        loading: true,
        ExcleDownloadOrderInfo: null,
      };
    case type.Excle_Download_SUCCESS:
      return {
        ...state,
        loading: true,
        ExcleDownloadOrderInfo: action.data,
      };
    case type.Excle_Download_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.Excle_Download_RESET:
        return {
          ...state,
          loading: false,
          ExcleDownloadOrderInfo: null,
        };
    default:
      return state;
  }
};


const  DashboardReducer = (state = initialState, action) => {

  switch (action.type) {
    case type.DASHBOARD_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        dashboardInfo: null,
      };
    case type.DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        loading: true,
        dashboardInfo: action.data,
      };
    case type.DASHBOARD_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.DASHBOARD_DATA_RESET:
        return {
          ...state,
          loading: false,
          dashboardInfo: null,
        };
    default:
      return state;
  }
};


const UserList = (state = initialState, action) => {
  
  switch (action.type) {
    case type.User_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.User_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        UserListInfo: action.data,
      };
    case type.User_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    default:
      return state;
  }
};


const UserViewreduser = (state = initialState, action) => {
  switch (action.type) {
    case type.User_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.User_VIEW_SUCCESS:
      return {
        ...state,
        loading: true,
        UserViewdata: action.data,
      };
    case type.User_VIEW_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    // case type.RESTAURANT_VIEW_RESET:
    //   return {
    //     ...state,
    //     loading: false,
    //     specialitystatusdata: null,
    //   };
    default:
      return state;
  }
};




const  Commissionreduser = (state = initialState, action) => {
  switch (action.type) {
    case type.DASHBOARD_COMMISSION_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        Commissiondata: null,
      };
    case type.DASHBOARD_COMMISSION_DATA_SUCCESS:
      return {
        ...state,
        loading: true,
        Commissiondata: action.data,
      };
    case type.DASHBOARD_COMMISSION_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.DASHBOARD_COMMISSION_DATA_RESET:
        return {
          ...state,
          loading: false,
          Commissiondata: null,
        };
    default:
      return state;
  }
};

export {
  chatSendReduser,
  activetypeListReduser,
  DashboardReducer,
  ExcleDownloadOrder,
  chatGetReduser,
  createorderlistReduser,
  supportStatusReduser,
  bagSizeListReduser,
  getsettingReduser,
  settingReduser,
  getsupportStatusReduser,
  supportListReduser,
  faqlistReduser,
  faqtypelistReduser,
  faqAddReduser,
  orderListReduser,
  typeupdateReduser,
  bagsizeAddReduser,
  typedetailsReduser,
  bagsizeupdateReduser,
  typestatusReduser,
  SpecialitydetailsReduser,
  bagsizedetailsReduser,
  bagsizeStatusReduser,
  SpecialityUpdateReduser,
  loginUser,
  sendemail,
  countryList,
  stateList,
  cityList,
  countrystatus,
  statestatus,
  citystatus,
  countryaddreduser,
  stateaddreduser,
  cityaddreduser,
  activecountrylistreduser,
  specialitylistreduser,
  specialityaddreduser,
  specialitystatusreduser,
  activestatelistreduser,
  vendorList,
  documentviewListReduser,
  restaurantViewreduser,
  restauranttypeListreduser,
  accountStatusReduser,
  getCityReduser,
  EditCityReduser,
  EditStateReduser,
  EditcountryReduser,
  getStateReduser,
getCountryyReduser,
documentListReduser,
typeAddReduser,
typeListReduser,
UserList,
UserViewreduser,
Commissionreduser,
};
