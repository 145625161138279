import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ToastMessage from "../../utils/ToastMessage";
// import { CreateOrderListReducer } from '../../redux/reducers/Reducerfile';
import { Create_Orderlist_api, ImageUrl } from "../../API/baseUrl";
import { createOrderListRequest } from "../../redux/actions";
import { Box, Stack } from "@mui/material";
import StatusModal from "../vendor/modal";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CFormSelect,
} from "@coreui/react";
import moment from 'moment';
import UserDetailsSkeleton from "../User/viewskeleton";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";
function CreateOrderList() {
  const dispatch = useDispatch(); 
   const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [restaurantdata, setRestaurantdata] = useState("");
  const [statusvalue, setStatusvalue] = useState("");
  const [accountstatus, setAccountstatus] = useState("");
  const [loader, setLoader] = useState(true);
  const [visible, setVisible] = useState(false);
  const { CreateOrderListdata } = useSelector(
    (state) => state?.createorderlistReduser || {}
  );

  useEffect(() => {
    if (id) {
      const parameter = {
        orderId: id,
        url: Create_Orderlist_api,
      };
      dispatch(createOrderListRequest(parameter));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (CreateOrderListdata?.status) {
      setData(CreateOrderListdata?.data);
      setLoader(false)
    } else if (CreateOrderListdata?.message) {
      ToastMessage("error", CreateOrderListdata?.message);
      setLoader(false)
    }
  }, [CreateOrderListdata]);

  const checkAvailability = (data) => data || "-";

  const checkAvailability1 = (value) => {
    return value === 0 ? "No" : value === 1 ? "Yes" : "unknown";
  };

  const handleselectchange = (e) => {
    const selectedStatus = e.target.value;
    setAccountstatus(e.target.value);
    setVisible(true);
  };


  const formatOrderDate = (date) => {
    return date ? moment(date).format("MMMM Do YYYY, h:mm:ss a") : "---";
  };


  return (
<>
{loader ?
  <LoaderAnimation/>:
  

  <div className="viewsreen_container">
  <Box maxWidth="false" className="containerdivuser">
    <Stack className="editAndAddHeadClass headandback">
    <ArrowBackIcon  onClick={()=>navigate(-1)}
    style={{ cursor: "pointer" }}/>
      <h2>Order History</h2>
    </Stack>
  </Box>
  {visible ? (
    <StatusModal
      {...{ visible, setVisible, setStatusvalue }}
      restaurantId={id}
      statusId={accountstatus}
    />
  ) : (
    ""
  )}
  <CAccordion alwaysOpen activeItemKey={2}>
    <CAccordionItem itemKey={2}>
      <CAccordionHeader>Customer Details</CAccordionHeader>
      <CAccordionBody>
        <div className="acordian_body_inside">
          <div className="detail_view_container_fluid">
            <div className="view_membership_field">
              <b> Customer Name : </b>
              <p className="viewpagevalues">
                {checkAvailability(data?.userName)}
              </p>
            </div>
          </div>
          <div className="detail_view_container_fluid">
            <div className="view_membership_field">
              <b>Customer Email : </b>
              <p className="viewpagevalues">
                {checkAvailability(data?.userEmail)}
              </p>
            </div>
          </div>
          <div className="detail_view_container_fluid">
            <div className="view_membership_field">
              <b>Customer Mobile Number : </b>
              {data?.userMobileNumber ?  <p className="viewpagevalues">{data?.userCountryCode} {checkAvailability(data?.userMobileNumber)}
              </p> : ""}
             
            </div>
          </div>
        </div>
      </CAccordionBody>
    </CAccordionItem>
  </CAccordion>
  <CAccordion alwaysOpen activeItemKey={3}>
    <CAccordionItem itemKey={3}>
      <CAccordionHeader>Restaurant Details</CAccordionHeader>
      <CAccordionBody>
        <div className="acordian_body_inside">
          <div className="detail_view_container_fluid">
            <div className="view_membership_field">
              <b>Restaurant ID :</b>
              <p className="viewpagevalues">
                {checkAvailability(data?.restaurantId)}
              </p>
            </div>
          </div>
          <div className="detail_view_container_fluid">
            <div className="view_membership_field">
              <b>Restaurant Name :</b>
              <p className="viewpagevalues">
                {checkAvailability(data?.restaurantName)}
              </p>
            </div>
          </div>
          <div className="detail_view_container_fluid">
            <div className="view_membership_field">
              <b>Restaurant Address :</b>
              <p className="viewpagevalues"
              style={{wordBreak: "break-all" ,}}
              >
                {checkAvailability(data?.restaurantAddress)}
              </p>
            </div>
          </div>
          <div className="detail_view_container_fluid">
            <div className="view_membership_field">
              <b>Restaurant Mobile Number :</b>
              <p className="viewpagevalues">
                {checkAvailability(
                  `${data?.restaurantCountryCode || ""} ${data?.restaurantMobileNumber || ""
                  }`
                )}
              </p>
            </div>
          </div>
          <div className="detail_view_container_fluid">
            <div className="view_membership_field">
              <b>Restaurant Landline Number :</b>
              <p className="viewpagevalues">
                {checkAvailability(
                  `${data?.restaurantLandlineNumberCountryCode || ""} ${data?.restaurantLandlineNumber || ""
                  }`
                )}
              </p>
            </div>
          </div>
          <div className="detail_view_container_fluid">
            <div className="view_membership_field">
              <b>Restaurant Owner Mobile Number :</b>
              <p className="viewpagevalues">
                {checkAvailability(
                  `${data?.restaurantOwnerCountryCode || ""} ${data?.restaurantOwnerMobileNumber || ""
                  }`
                )}
              </p>
            </div>
          </div>
          <div className="detail_view_container_fluid">
            <div className="view_membership_field">
              <b>Restaurant Owner E-mail :</b>
              <p className="viewpagevalues">
                {checkAvailability(data?.restaurantOwnerEmail)}
              </p>
            </div>
          </div>
        </div>
      </CAccordionBody>
    </CAccordionItem>
  </CAccordion>
  <CAccordion alwaysOpen activeItemKey={4}>
    <CAccordionItem itemKey={4}>
      <CAccordionHeader>Order Details</CAccordionHeader>
      <CAccordionBody>
        <div className="acordian_body_inside">
          <div className="view_membership_field">
            <b>Order Id: </b>
            <p className="viewpagevalues">
              {checkAvailability(data?.orderId)}
            </p>
          </div>
          <div className="view_membership_field">
            <b>Order Quantity : </b>
            <p className="viewpagevalues">
              {checkAvailability(data?.orderQuantity)}
            </p>
          </div>

          <div className="view_membership_field">
            <b>Order Status : </b>
            <p className="viewpagevalues">
              {checkAvailability(data?.orderStatus)}
            </p>
          </div>
          {/* <div className="view_membership_field">
            <b>Payment Status : </b>
            <p className="viewpagevalues">
              {checkAvailability(data?.paymentStatus)}
            </p>
          </div> */}
          <div className="view_membership_field">
            <b>Payment Status: </b>
            <p className="viewpagevalues">
              {(() => {
                const status = data?.paymentStatus;
                switch (status) {
                  case "pending":
                    return "Pending";
                  case "captured":
                    return "Successful";
                  case "authorized":
                    return "Pending";
                  case "failed":
                    return "Failed";
                  default:
                    return status;
                }
              })()}
            </p>
          </div>

          <div className="view_membership_field">
            <b>Payment Id : </b>
            <p className="viewpagevalues">
              {checkAvailability(data?.paymentId)}
            </p>
          </div>
          <div className="view_membership_field">
            <b>Total Order Amount : </b>
            <p className="viewpagevalues">
              {`₹${data?.totalOrderAmount || ""}`}
            </p>
          </div>
          <div className="view_membership_field">
            <b>Payment Mode : </b>
            <p className="viewpagevalues">
              {checkAvailability(data?.paymentMode)}
            </p>
          </div>
          {/* <div className="view_membership_field">
            <b>Order Rated: </b>
            <p className="viewpagevalues">
              {checkAvailability(data?.isOrderRated)}
            </p>
          </div> */}
          <div className="view_membership_field">
            <b>Order Rating : </b>
            <p className="viewpagevalues">
              {checkAvailability(data?.orderRating)}
            </p>
          </div>
          <div className="view_membership_field">
            <b>Order Reveal : </b>
            <p className="viewpagevalues">
              {/* {checkAvailability1(data?.orderStatus)} */}
              {data?.orderStatus === "Collected" ? "Yes" : "No"}
            </p>
          </div>
          <div className="view_membership_field">
            <b>Magic Bag Category : </b>
            <p className="viewpagevalues">
              {checkAvailability(data?.surpriseBagCategory)}
            </p>
          </div>
          <div className="view_membership_field">
            <b>Magic Bag Name : </b>
            <p className="viewpagevalues">
              {checkAvailability(data?.surpriseBagName)}
            </p>
          </div>
          <div className="view_membership_field">
            <b>Order Private Code : </b>
            <p className="viewpagevalues">
              {checkAvailability(data?.orderPrivateCode)}
            </p>
          </div>

          <div className="view_membership_field">
            <b>Order Date & Time </b>
            <p className="viewpagevalues">
              {data?.orderCreatedAt}
            </p>
          </div>


        </div>
      </CAccordionBody>
    </CAccordionItem>
  </CAccordion>
</div>
}


</>


  );
}

export default CreateOrderList;
