import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ToastMessage from "../../utils/ToastMessage";
import DataTable from "react-data-table-component";
import { CButton, CRow, CCol } from "@coreui/react";
import { useNavigate } from "react-router-dom";
import { vendorList_api } from "../../API/baseUrl";
import { vendorList } from "../../redux/actions";
import moment from 'moment/moment';
import ClearIcon from '@mui/icons-material/Clear';
import StatusDropdown from "./statusDropdown";
import "./index.css"
import { TableSk } from "../skelaton/tableSk";
import { VendorAccountsTitle } from "../Title";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";


const Accountverification = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pending, setPending] = useState(true);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [sortBy, setSortBy] = useState("DESC");
  const [sortByColumnName, setSortByColumnName] = useState("");
  const dispatch = useDispatch();
  const { vendorListInfo } = useSelector((state) => state?.vendorList || {});

  console.log("vendorListInfo", vendorListInfo)


  const handleView = (id) => {
    navigate(`/dashboard/restaurantView/${id}`);
  }
  useEffect(() => {

    document.title = VendorAccountsTitle;
  }, []);
  const customStyles = {
    headCells: {
        style: {
            fontSize: "16px",
            fontWeight: 500,
        },
    },
};
  const handlePerRowsChange = async (newLimit, offset) => {
    setOffset(offset);
    setLimit(newLimit);
  };

  const handlePageChange = (offset) => {
    setOffset(offset);
  };

  const handleSort = async (column, sortDirection) => {
    setSortBy(sortDirection);
    setSortByColumnName(column.column_name || "");
  };


  const handleclearSearch = () => {
    setSearchText("");
  };

  const columns = [
    {
      name: "Id",
      width: "130px",
      selector: (row) => <p className="id_navigate" onClick={() => handleView(row?.restaurantIdFK)}>{row?.restaurantId ? row.restaurantId : "---"}</p>,
      sortable: true,
      column_name: "restaurantId",
      reorder: true,

    },
    {
      name: "Restaurant Name",
      width: "200px",
      selector: (row) => row.restaurantName ? row.restaurantName : "---",
      sortable: true,
      column_name: "restaurantName",
      reorder: true,
    },
    {
      name: "Restaurant Mobile Number",
      width: "280px",
      selector: (row) => row.restaurantMobileNumber ? <p className="margin-0">{row.restaurantCountryCode} {row.restaurantMobileNumber}</p> : "---",
      sortable: true,
      column_name: "restaurantMobileNumber",
      reorder: true,
    },

    {
      name: "Restaurant Email",
      width: "200px",
      selector: (row) => row.restaurantEmail ? row.restaurantEmail : "---",
      sortable: true,
      column_name: "restaurantEmail",
      reorder: true,
    },
    {
      name: "Owner Name",
      width: "180px",
      selector: (row) => row.restaurantOwnerName ? row.restaurantOwnerName : "---",
      sortable: true,
      column_name: "restaurantOwnerName",
      reorder: true,
    },
    {
      name: "Owner Email",
      width: "180px",
      selector: (row) => row.restaurantOwnerEmail ? row.restaurantOwnerEmail : "---",
      // sortable: true,
      column_name: "restaurantOwnerEmail",
      reorder: true,
    },

    {
      name: "Owner Mobile Number",
      width: "250px",
      selector: (row) => row.restaurantOwnerMobileNumber ? <p className="margin-0">{row.restaurantOwnerCountryCode} {row.restaurantOwnerMobileNumber}</p> : "---",
      // sortable: true,
      column_name: "restaurantMobileNumber",
      reorder: true,
    },
    // {
    //   name: "STD code",
    //   width:"200px",
    //   selector: (row) => row.restaurantLandlineNumberCountryCode ? row.restaurantLandlineNumberCountryCode : "---",
    //   // sortable: true,
    //   column_name: "restaurantLandlineNumberCountryCode",
    //   reorder: true,
    // },
    {
      name: "Restaurant Landline Number",
      width: "280px",
      selector: (row) => row.restaurantLandlineNumber ? <p className="margin-0">{row.restaurantLandlineNumberCountryCode}  {row.restaurantLandlineNumber}</p> : "---",
      // sortable: true,
      column_name: "restaurantLandlineNumber",
      reorder: true,
    },
    {
      name: 'Created Date',
      width: "180px",
      selector: row => row.restaurantCreationDateAndTime,
      format: (row) => moment(row.restaurantCreationDateAndTime).format('MMMM Do YYYY'),
      // sortable: true,
      column_name: 'restaurantCreationDateAndTime',
      reorder: true,
    },
    {
      name: "Status",
      width: "150px",
      selector: (row) => row.accountStatus === 0 ? "Pending" : row.accountStatus === 1 ? "Accepted" : "Rejected",
      sortable: true,
      column_name: "",
      reorder: true,
    },
    //  {
    //   name: "Document Status",
    //   selector: (row) => <StatusDropdown/>,
    //   sortable: true,
    //   column_name: "",
    //   reorder: true,
    // },

  ];


  useEffect(() => {
    if (vendorListInfo?.status) {
      setData(vendorListInfo?.data);
      setTotalRows(vendorListInfo?.count);
      setPending(false);
    } else {
      ToastMessage("error", vendorListInfo?.message);
    }
  }, [vendorListInfo]);



  useEffect(() => {
    const parameter = {
      offset: offset,
      limit: limit,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      url: vendorList_api,
    };
    dispatch(vendorList(parameter));
  }, [offset, limit, searchText, sortByColumnName, sortBy]);

 
  return (
    <div>

      {/* <div 
    // className="d-grid gap-2 d-md-flex pb-5"
    >
      <h3>Vendor Accounts</h3> */}

      <div>
        {/* <CRow>
          <CCol className="d-grid gap-2 d-md-flex justify-content-md-start pb-5">
            <h3>Vendor Accounts</h3>
          </CCol>
          <CCol className="d-grid gap-2 d-md-flex justify-content-md-center pb-5">
            <input
              type="search"
              placeholder="Search"
              className="search-feild"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </CCol>
        </CRow> */}
        <CRow>
          <CCol
            className="d-grid gap-2 d-md-flex justify-content-md-start pb-5"
            lg={9}
            md={9}
            sm={8}
            xs={12}
          >
            <h3>Vendor Accounts</h3>
          </CCol>
          <CCol className="d-grid gap-2 d-md-flex justify-content-md-center pb-5"
            lg={3}
            md={3}
            sm={3}
            xs={7}>
            <div className="search-feild">
              <input
                type="text"
                placeholder="Search"

                style={{ outline: 'none' }}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              {searchText ? <ClearIcon onClick={handleclearSearch} sx={{ color: `var(--colorused)`, cursor: "pointer", fontSize: "20px" }} /> : ""}

            </div>
          </CCol>

        </CRow>
      </div>

      {/* </div> */}

      <DataTable
        customStyles={customStyles}
        columns={columns}
        data={data}
        dataAlign="center"
        fixedHeader
        pagination
        progressPending={pending}
        progressComponent={<LoaderAnimation />}
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        fixedHeaderScrollHeight="58vh"
        sortServer
        responsive
        onSort={handleSort}
      />
    </div>
  );
};

export default Accountverification;
