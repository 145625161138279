import * as Yup from "yup";
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const loginschema = Yup.object().shape({
  email: Yup.string()
    .matches(emailRegex, "Please enter valid email")
    .required("This is required"),
  password: Yup.string()
    .required("This is required")
    .min(6, "Password must be atleast 6 digits"),
});

export const sendemailshema = Yup.object().shape({
  email: Yup.string()
    .matches(emailRegex, "Please enter valid email")
    .required("This is required"),
});

export const countryaddshema = Yup.object().shape({
  countryName: Yup.string()
    .matches(
      /^(?!.*\d)(?!\s*$).+$/,
      "Must not contain numbers and must not be only spaces"
    )
    .required("This field is Required"),
  countryShortCode: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
  countryISD: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
  countryCurrency: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
  file: Yup.mixed().required("This field is Required"),
});

export const stateaddshema = Yup.object().shape({
  countryname: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
  stateName: Yup.string()
    .matches(
      /^(?!.*\d)(?!\s*$).+$/,
      "Must not contain numbers and must not be only spaces"
    )
    .required("This field is Required"),
});

export const Settingaddshema = Yup.object().shape({
  type: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
  data: Yup.string()
    .matches(/^(?!-).*$/, "Negative values are not allowed") // Disallow negative values
    .matches(/\S/, "Must not be only spaces") // Disallow strings composed only of spaces
    .required("This field is Required"),
  description: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
});

export const Settingeditshema = Yup.object().shape({

// discountType: Yup.string()
// .oneOf(['%', '₹', 'period'], 'Invalid discount type')
// .required('Discount Type is required'),

data: Yup.number()
.typeError('Value must be a number')
.required('This field is Required')
.test('valid-value', 'Value must be between 0 and 100', function(value) {
  const { discountType } = this.parent;

  if (discountType === '%' && (value < 0 || value > 100)) {
    return false;
  }

  return value >= 0; 
}),

  description: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
});

export const cityaddshema = Yup.object().shape({
  countryIdFk: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
  stateIdFk: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
  cityName: Yup.string()
    .matches(
      /^(?!.*\d)(?!\s*$).+$/,
      "Must not contain numbers and must not be only spaces"
    )
    .required("This field is Required"),
});

export const specialityshema = Yup.object().shape({
  specialty: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
});

export const bagsizeaddshema = Yup.object().shape({
  surpriseBagSize: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("Bag Size is required"),

  restaurantTypeIdFk: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("Restaurant Type is required"),

  commission: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("commission is required"),

  surpriseBagRetailPrice: Yup.number()
    .typeError("Retail Price must be a number")
    .required("Retail Price is required")
    .min(0, "Retail Price must be greater than or equal to 0"),

  surpriseBagSellingPrice: Yup.number()
    .typeError("Selling Price must be a number")
    .required("Selling Price is required")
    .min(0, "Selling Price must be greater than or equal to 0"),
});

export const addtypeshema = Yup.object().shape({
  type: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
});
export const addBankshema = Yup.object().shape({
  bank: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
});
export const rejectStatusShema = Yup.object().shape({
  reasonOfRejection: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
});

export const addFaqshema = Yup.object().shape({
  question: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
  answer: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
});

export const AccountStatusShema = Yup.object().shape({
  restaurantTypeIdFk: Yup.string().required("This field is Required"),
});

export const acountReasonShema = Yup.object().shape({
  // restaurantTypeIdFk: Yup.string().required('This field is Required'),
  reasonOfRejection: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
});
