import axios from "axios";
// const headers = {
//   Accept: 'application/json',
//   'Content-Type': 'application/json',
//   timezone: RNLocalize.getTimeZone(),
// };

const customHeaders = () => ({
  token: localStorage.getItem("accessToken"),
  refreshToken: localStorage.getItem("refreshToken"),
  "ngrok-skip-browser-warning": "69420",
});

async function postNetworkCall(params, action) {
  let response;

  try {
    response = await axios.post(params?.data?.url, params?.data, {
      headers: customHeaders(),
    });
  } catch (error) {
    if (
      error.response.data.message === "Invalid authorization token Admin" ||  error.response.data.statusCode === 405 ||
      error.response.data.statusCode === 406
    ) {
      localStorage.clear();
      window.location.href = "/";
    } else if (
      error.response.data.message === "Authorization token is missing!"
    ) {
      localStorage.clear();
      window.location.href = "/";
    } else if (error.response.data.statusCode === 401) {
      await handleTokenRefresh();
      localStorage.clear();
      window.location.href = "/";

      response = await axios.post(params?.data?.url, params?.data, {
        headers: customHeaders(),
      });
    } else {
      console.error(error.message, "error");
      return error.message;
    }
  }

  return response.data;
}


async function putNetworkCall(params, action) {
  let response;

  try {
    response = await axios.put(params?.data?.url, params?.data, {
      headers: customHeaders(),
    });
  } catch (error) {
    if (
      error.response.data.message === "Invalid authorization token Admin"  ||  error.response.data.statusCode === 405||
      error.response.data.statusCode === 406
    ) {
      localStorage.clear();
      window.location.href = "/";
    } else if (
      error.response.data.message === "Authorization token is missing!"
    ) {
      localStorage.clear();
      window.location.href = "/";
    } else if (error.response.data.statusCode === 401) {
      await handleTokenRefresh();

      response = await axios.put(params?.data?.url, params?.data, {
        headers: customHeaders(),
      });
      localStorage.clear();
      window.location.href = "/";
    } else {
      console.error(error.message, "error");
      return error.message;
    }
  }

  return response.data;
}



const MultiformCustomHeaders = () => ({
  token: localStorage.getItem("accessToken"),
  refreshToken: localStorage.getItem("refreshToken"),
  "ngrok-skip-browser-warning": "69420",
  "Content-Type": "multipart/form-data",
});

async function postNetworkcreateCall(params, action) {
  let response;

  try {
    response = await axios.post(params?.url, params?.values, {
      headers: MultiformCustomHeaders(),
    });
  } catch (error) {
    if (
      error.response.data.message === "Invalid authorization token Admin" ||  error.response.data.statusCode === 405 ||
      error.response.data.statusCode === 406
    ) {
      localStorage.clear();
      window.location.href = "/";
    } else if (
      error.response.data.message === "Authorization token is missing!"
    ) {
      localStorage.clear();
      window.location.href = "/";
    } else if (error.response.data.statusCode === 401) {
      await handleTokenRefresh();

      response = await axios.post(params?.data?.url, params?.data, {
        headers: MultiformCustomHeaders(),
      });
      localStorage.clear();
      window.location.href = "/";
    } else {
      console.error(error.message, "error");
      return error.message;
    }
  }

  return response.data;
}

async function putNetworkcreateCall(params, action) {
  let response;
// alert("098")
  try {
    response = await axios.put(params?.url, params?.values, {
      headers: MultiformCustomHeaders(),
    });
  } catch (error) {
    if (
      error.response.data.message === "Invalid authorization token Admin"  ||  error.response.data.statusCode === 405 ||
      error.response.data.statusCode === 406
    ) {
      localStorage.clear();
      window.location.href = "/";
    } else if (
      error.response.data.message === "Authorization token is missing!"
    ) {
      localStorage.clear();
      window.location.href = "/";
    } else if (error.response.data.statusCode === 401 ) {
      await handleTokenRefresh();

      response = await axios.put(params?.data?.url, params?.data, {
        headers: MultiformCustomHeaders(),
      });
      localStorage.clear();
      window.location.href = "/";
    } else {
      console.error(error.message, "error");
      return error.message;
    }
  }

  return response.data;
}


async function handleTokenRefresh() {
  const result = await axios.post(
    "https://dev.api.pickntreat.in/generate/admin/access-token/from/refresh-token",
    {
      refreshToken: localStorage.getItem("refreshToken"),
    }
  );

  localStorage.setItem("accessToken", result.data.accessToken);
}

async function getNetworkCall(params, action) {
  console.log("actionaction", params)
  let response;

  try {
    response = await axios.get(params?.data?.url, {
      headers: customHeaders(),
    });
  } catch (error) {
    if (
      error.response.data.message === "Invalid authorization token Admin" ||  error.response.data.statusCode === 405 ||
      error.response.data.statusCode === 406
    ) {
      localStorage.clear();
      window.location.href = "/";
    } else if (
      error.response.data.message === "Authorization token is missing"
    ) {
      localStorage.clear();
      window.location.href = "/";
    } else if (error.response.data.statusCode === 401) {
      await handleTokenRefresh();

      response = await axios.get(params?.data?.url, {
        headers: customHeaders(),
      });
      localStorage.clear();
      window.location.href = "/";
    } else {
      console.error(error.message, "error");
      return error.message;
    }
  }

  return response.data;
}


async function postexcelNetworkCall(params) {
  let response;

  try {
    response = await axios.post(params?.data?.url, params?.data, {
      headers: customHeaders(),
      responseType: 'blob', // Handle binary data
    });


    if (response.data.statusCode === 405) {
      await handleTokenRefresh();
      response = await axios.post(params?.data?.url, params?.data, {
        headers: customHeaders(),
        responseType: 'blob', // Handle binary data
      });
  } 
}catch (error) {
    if (error.response.data.message === 'Invalid authorization token Vendor' ||  error.response.data.statusCode === 405  || error.response.data.statusCode === 406 ||  error.response.data.statusCode === 401) {
      localStorage.clear();
      window.location.href = '/';
    } else if (error.response.data.message === 'Authorization token is missing!') {
      localStorage.clear();
      window.location.href = '/';
    } else if (error.response.data.statusCode === 405) {
      await handleTokenRefresh();
      response = await axios.post(params?.data?.url, params?.data, {
        headers: customHeaders(),
        responseType: 'blob', // Handle binary data
      });
    } else {
      console.error(error.message, 'error');
      throw error; // Throw error instead of returning it
    }
  }

  return response.data; // This should now be binary data
}



export { getNetworkCall, postNetworkCall, postNetworkcreateCall ,putNetworkCall,putNetworkcreateCall , postexcelNetworkCall};
