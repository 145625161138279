import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { restaurantViewRequest, vendorList } from "../../redux/actions";
import ToastMessage from "../../utils/ToastMessage";
import { ImageUrl, user_viewlist_api, vendor_order_api } from "../../API/baseUrl";
import DataTable from "react-data-table-component";
import { CAccordion, CAccordionBody, CAccordionHeader, CAccordionItem, CCol } from "@coreui/react";
import { Box, TextField, Button } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import { Stack } from "react-bootstrap";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UserDetailsSkeleton from "./viewskeleton.jsx"
import LoaderAnimation from "../../logo-2/loader/loaderanimation.jsx";

const ViewUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [restaurantdata, setRestaurantdata] = useState("");
  const [order, setOrder] = useState([]);
  const [filteredOrder, setFilteredOrder] = useState([]);
  const [loader, setLoader] = useState(true);
  
  const [sortBy, setSortBy] = useState("DESC");
  const [sortByColumnName, setSortByColumnName] = useState("");

  const [searchText, setSearchText] = useState("");
  const { vendorListInfo } = useSelector((state) => state?.vendorList || {});
  const { id } = useParams();

  const { restaurantViewdata } = useSelector(
    (state) => state?.restaurantViewreduser || {}
  );

  useEffect(() => {
    const parameter = {
      url: `${user_viewlist_api}${id}`,
    };
    dispatch(restaurantViewRequest(parameter));
  }, [id]);

 



  useEffect(() => {
    if (restaurantViewdata) {
      setLoader(true); 
      const timer = setTimeout(() => {
        setLoader(false);
        if (restaurantViewdata?.status) {
          setRestaurantdata(restaurantViewdata?.data);
          setLoader(false)
        } else {
          ToastMessage("error", restaurantViewdata?.message);
          setLoader(false)
        }
      }, 200); 
      return () => clearTimeout(timer);
    }
  }, [restaurantViewdata]);



  useEffect(() => {
    const parameter = {
      offset: 0,
      limit: 0,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      url: `${vendor_order_api}${id}`,
    };
    dispatch(vendorList(parameter));
  }, [id,searchText, sortByColumnName]);

  useEffect(() => {
    if (vendorListInfo?.status) {
      setOrder(vendorListInfo?.data || []);
      setFilteredOrder(vendorListInfo?.data || []); 
    } else {
      ToastMessage("error", vendorListInfo?.message);
    }
  }, [vendorListInfo]);

  // Search function for orders
  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchText(searchTerm);
    if (searchTerm !== "") {
      const filteredData = order.filter((item) =>
        Object.values(item).some((val) =>
          String(val).toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setFilteredOrder(filteredData);
    } else {
      setFilteredOrder(order); 
    }
  };

  const handlenavigate = (orderIdFK) => {
    navigate(`/dashboard/createorderlist/${orderIdFK}`)
  }

  const columns = [
    {
      name: "Order ID",
      // selector: (row) => row.orderId || "---",
      selector: (row) => (
        <div
          className="column_id"
          onClick={() => handlenavigate(row?.orderId)}
          style={{ cursor: "pointer", color: "blue" }}
        >
          {row?.orderId || "-"}
        </div>
      ),
      sortable: true,
      width: "150px",
      column_name: "orderId",
      reorder: true,
    },
    {
      name: "Order Status",
      selector: (row) => row.orderStatus || "---",
      sortable: true,
      width: "150px",
      column_name: "orderStatus",
      reorder: true,
    },
    {
      name: "Total Amount",
      selector: (row) => row.totalOrderAmount ? `₹ ${row.totalOrderAmount}` : "---",
      sortable: true,
      width: "150px",
      column_name: "totalOrderAmount",
      reorder: true,
    },
    {
      name: "Payment Mode",
      selector: (row) => row.paymentMode || "---",
      width: "150px",
      column_name: "paymentMode",
      reorder: true,
    },
    {
      name: "Restaurant",
      selector: (row) => row.restaurantName || "---",
      width: "200px",
      column_name: "restaurantName",
      reorder: true,
    },
    {
      name: "Restaurant Address",
      selector: (row) => row.restaurantAddress || "---",
      width: "250px",
      column_name: "restaurantAddress",
      reorder: true,
    },
  ];



  const handleSort = async (column, sortDirection) => {
    setSortBy(sortDirection);
    setSortByColumnName(column.column_name || "");
  };


  const formattedDate = new Date(restaurantdata.dateOfBirth).toLocaleDateString('en-GB');

  return (

    <>{loader?
      <LoaderAnimation/> :

      <div className="viewsreen_container">
      <Box maxWidth="false" className="containerdivuser">
      <Stack className="editAndAddHeadClass headandback">
        <ArrowBackIcon  onClick={()=>navigate(-1)} style={{ cursor: "pointer" }}/>
        <h2>User Details</h2>
        </Stack>
                
            </Box>
      <Box className="restaurant_details">
        {restaurantdata && (
        <CAccordion alwaysOpen  defaultActiveKey={[1, 2]} className="accordion" id="accordionExample">
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>User Details</CAccordionHeader>
          <CAccordionBody>
            <p><strong>User ID:</strong> {`UID00${restaurantdata.userIdFK}` || "--"}</p>
            <p><strong>Email:</strong> {restaurantdata.email || "--"}</p>
            <p><strong>Name:</strong> {restaurantdata.name || "--"}</p>
            <p><strong>Gender:</strong> {restaurantdata.gender || "--"}</p>
            <p><strong>Date of Birth:</strong> {restaurantdata.dateOfBirth == null ? "--" : formattedDate || "--"}</p>
            <p><strong>Mobile Number:</strong> {restaurantdata.userCountryCode || "--"} {restaurantdata.userMobileNumber || "--"}</p>
            <p><strong>Country Name:</strong> {restaurantdata.countryName || "--"}</p>
            <p><strong>Dietary Preference:</strong> {restaurantdata.dietaryPreference || "--"}</p>
          </CAccordionBody>
        </CAccordionItem>
      
        <CAccordionItem itemKey={2}>
          <CAccordionHeader>Order Details</CAccordionHeader>
          <CAccordionBody>
            <TextField
              variant="outlined"
              label="Search Orders"
              value={searchText}
              onChange={handleSearch}
              margin="normal"
              size="small"
            />
            <DataTable
              title="Order Details"
              columns={columns}
              data={filteredOrder}
              pagination
              fixedHeader
              responsive
              onSort={handleSort}
            />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
      
        )}
      </Box>
    </div>
    }</>

   
  );
};

export default ViewUser;
