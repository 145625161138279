import { call, put, takeEvery } from 'redux-saga/effects';
import * as type from '../../type';
import {
  getNetworkCall,
  postexcelNetworkCall,
  postNetworkCall,
  postNetworkcreateCall,
  putNetworkCall,
  putNetworkcreateCall,
} from '../../../networkCall';
import CreateOrderList from '../../../views/orderList/createOrderList';

function* loginUsers(action) {
  try {
    const loginUser = yield call(postNetworkCall, action.payload);
    yield put({ type: type.LOGIN_USER_SUCCESS, data: loginUser });
  } catch (e) {
    yield put({ type: type.LOGIN_USER_ERROR, message: e.message });
  }
}

function* sendemailS(action) {
  try {
    const sendemail = yield call(postNetworkCall, action.payload);
    yield put({ type: type.SEND_EMAIL_SUCCESS, data: sendemail });
  } catch (e) {
    yield put({ type: type.SEND_EMAIL_ERROR, message: e.message });
  }
}

function* countryListS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.COUNTRY_LIST_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.COUNTRY_LIST_ERROR, message: e.message });
  }
}

function* stateListS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.STATE_LIST_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.STATE_LIST_ERROR, message: e.message });
  }
}

function* cityListS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.CITY_LIST_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.CITY_LIST_ERROR, message: e.message });
  }
}
function* vendorListS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.VENDOR_LIST_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.VENDOR_LIST_ERROR, message: e.message });
  }
}


function* documentListS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.DOCUMENT_LIST_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.DOCUMENT_LIST_ERROR, message: e.message });
  }
}

function* countrystatusS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.COUNTRY_STATUS_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.COUNTRY_STATUS_ERROR, message: e.message });
  }
}

function* statestatusS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.STATE_STATUS_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.STATE_STATUS_ERROR, message: e.message });
  }
}

function* citystatusS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.CITY_STATUS_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.CITY_STATUS_ERROR, message: e.message });
  }
}

function* countryaddS(action) {


  console.log("sagaaction", action)
  try {
    const data = yield call(postNetworkcreateCall, action.payload.data);
    yield put({ type: type.ADD_COUNTRY_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.ADD_COUNTRY_ERROR, message: e.message });
  }
}

function* stateaddS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.ADD_STATE_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.ADD_STATE_ERROR, message: e.message });
  }
}

function* cityaddS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.ADD_CITY_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.ADD_CITY_ERROR, message: e.message });
  }
}

function* specialityS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.SPECIALITY_LIST_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.SPECIALITY_LIST_ERROR, message: e.message });
  }
}

function* specialityaddS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.ADD_SPECIALITY_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.ADD_SPECIALITY_ERROR, message: e.message });
  }
}

function* specialitystatusS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.SPECIALITY_STATUS_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.SPECIALITY_STATUS_ERROR, message: e.message });
  }
}

function* activestatelistS(action) {

  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.ACTIVE_STATELIST_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.ACTIVE_STATELIST_ERROR, message: e.message });
  }
}

function* activecountryS(action) {
  try {
    const data = yield call(getNetworkCall, action.payload);
    yield put({ type: type.ACTIVE_COUNTRYLIST_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.ACTIVE_COUNTRYLIST_ERROR, message: e.message });
  }
}

function* activerestaurantTypeS(action) {
  try {
    const data = yield call(getNetworkCall, action.payload);
    yield put({ type: type.ACTIVETYPE_LIST_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.ACTIVETYPE_LIST_ERROR, message: e.message });
  }
}


function* restraurantviewS(action) {
  try {
    const data = yield call(getNetworkCall, action.payload);
    yield put({ type: type.RESTAURANT_VIEW_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.RESTAURANT_VIEW_ERROR, message: e.message });
  }
}



function* restraurantTypeListS(action) {
  try {
    const data = yield call(getNetworkCall, action.payload);
    yield put({ type: type.RESTAURANT_TYPELIST_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.RESTAURANT_TYPELIST_ERROR, message: e.message });
  }
}


function* accountStatusS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.ACCOUNT_STATUS_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.ACCOUNT_STATUS_ERROR, message: e.message });
  }
}


function* cityDetailsS(action) {
  try {
    const data = yield call(getNetworkCall, action.payload);
    yield put({ type: type.CITY_DETAILS_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.CITY_DETAILS_ERROR, message: e.message });
  }
}

function* countryDetailsS(action) {
  try {
    const data = yield call(getNetworkCall, action.payload);
    yield put({ type: type.COUNTRY_DETAILS_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.COUNTRY_DETAILS_ERROR, message: e.message });
  }
}

function* stateDetailsS(action) {
  try {
    const data = yield call(getNetworkCall, action.payload);
    yield put({ type: type.STATE_DETAILS_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.STATE_DETAILS_ERROR, message: e.message });
  }
}


function* documentViewS(action) {
  try {
    const data = yield call(getNetworkCall, action.payload);
    yield put({ type: type.DOCUMENT_VIEWLIST_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.DOCUMENT_VIEWLIST_ERROR, message: e.message });
  }
}


function* cityeditS(action) {
  try {
    const data = yield call(putNetworkCall, action.payload);
    yield put({ type: type.CITY_EDIT_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.CITY_EDIT_ERROR, message: e.message });
  }
}

function* stateeditS(action) {
  try {
    const data = yield call(putNetworkCall, action.payload);
    yield put({ type: type.STATE_EDIT_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.STATE_EDIT_ERROR, message: e.message });
  }
}

function* supportStatusS(action) {
  try {
    const data = yield call(putNetworkCall, action.payload);
    yield put({ type: type.SUPPORT_STATUSEDIT_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.SUPPORT_STATUSEDIT_ERROR, message: e.message });
  }
}

function* bagsizeAddS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.BAGSIZE_ADD_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.BAGSIZE_ADD_ERROR, message: e.message });
  }
}

function* bagsizelistS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.BAGSIZE_LIST_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.BAGSIZE_LIST_ERROR, message: e.message });
  }
}



function* typeAddS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.TYPE_ADD_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.TYPE_ADD_ERROR, message: e.message });
  }
}

function* typelistS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.TYPE_LIST_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.TYPE_LIST_ERROR, message: e.message });
  }
}



function* typestatusS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.TYPE_STATUS_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.TYPE_STATUS_ERROR, message: e.message });
  }
}

function* bagsizestatusS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.BAGSIZE_STATUS_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.BAGSIZE_STATUS_ERROR, message: e.message });
  }
}



function* countryeditS(action) {
  console.log("action", action)
  try {
    const data = yield call(putNetworkcreateCall, action.payload.data);
    yield put({ type: type.COUNTRY_EDIT_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.COUNTRY_EDIT_ERROR, message: e.message });
  }
}


function* specialityeditS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.SPECIALITY_UPDATE_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.SPECIALITY_UPDATE_ERROR, message: e.message });
  }
}

function* specialitydetailsS(action) {

  try {
    const data = yield call(getNetworkCall, action.payload);
    yield put({ type: type.SPECIALITY_DETAILS_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.SPECIALITY_DETAILS_ERROR, message: e.message });
  }
}


function* bagsizeeditS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.BAGSIZE_UPDATE_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.BAGSIZE_UPDATE_ERROR, message: e.message });
  }
}

function* bagsizedetailsS(action) {

  try {
    const data = yield call(getNetworkCall, action.payload);
    yield put({ type: type.BAGSIZE_DETAILS_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.BAGSIZE_DETAILS_ERROR, message: e.message });
  }
}


function* typeeditS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.TYPE_UPDATE_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.TYPE_UPDATE_ERROR, message: e.message });
  }
}

function* orderlistS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.ORDER_LIST_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.ORDER_LIST_ERROR, message: e.message });
  }
}

function* addFaqS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.ADD_FAQ_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.ADD_FAQ_ERROR, message: e.message });
  }
}

function* FaqtypeS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.FAQ_TYPE_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.FAQ_TYPE_ERROR, message: e.message });
  }
}

function* FaqlistS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.FAQ_LIST_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.FAQ_LIST_ERROR, message: e.message });
  }
}


function* supportlistS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.SUPPORTTICKET_LIST_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.SUPPORTTICKET_LIST_ERROR, message: e.message });
  }
}


function* settingS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.CREATE_SETTING_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.CREATE_SETTING_ERROR, message: e.message });
  }
}


function* getsettingS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.GET_SETTING_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.GET_SETTING_ERROR, message: e.message });
  }
}

function* typedetailsS(action) {

  try {
    const data = yield call(getNetworkCall, action.payload);
    yield put({ type: type.TYPE_DETAILS_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.TYPE_DETAILS_ERROR, message: e.message });
  }
}



function* getSupportStatusS(action) {

  try {
    const data = yield call(getNetworkCall, action.payload);
    yield put({ type: type.GETSUPPORT_STATUS_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.GETSUPPORT_STATUS_ERROR, message: e.message });
  }
}


function* CreateOrderListS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.CREATE_ORDER_LIST_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.CREATE_ORDER_LIST_ERROR, message: e.message });
  }
}


function* sendChatS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.CHAT_SEND_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.CHAT_SEND_ERROR, message: e.message });
  }
}


function* getChatS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.CHAT_GET_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.CHAT_GET_ERROR, message: e.message });
  }
}


function* dashboardS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.DASHBOARD_DATA_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.DASHBOARD_DATA_ERROR, message: e.message });
  }
}



function* ExcleDownloadOrderS(action) {
  try {
    const data = yield call(postexcelNetworkCall, action.payload);
    yield put({type: type.Excle_Download_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.Excle_Download_ERROR, message: e.message});
  }
}

function* userListS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.User_LIST_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.User_LIST_ERROR, message: e.message });
  }
}

function* UserviewS(action) {
  try {
    const data = yield call(getNetworkCall, action.payload);
    yield put({ type: type.User_VIEW_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.User_VIEW_ERROR, message: e.message });
  }
}
function* CommissionS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.DASHBOARD_COMMISSION_DATA_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.DASHBOARD_COMMISSION_DATA_ERROR, message: e.message });
  }
}

function* DashboardSaga() {
  yield takeEvery(type.DASHBOARD_DATA_REQUEST, dashboardS);
}

function* ExcleDownloadOrderSaga() {
  yield takeEvery(type.Excle_Download_REQUEST, ExcleDownloadOrderS);
}

function* sendChatSaga() {
  yield takeEvery(type.CHAT_SEND_REQUEST, sendChatS);
}

function* getChatSaga() {
  yield takeEvery(type.CHAT_GET_REQUEST, getChatS);
}

function* createorderlistSaga() {
  yield takeEvery(type.CREATE_ORDER_LIST_REQUEST, CreateOrderListS);
}

function* getSupportStatusSaga() {
  yield takeEvery(type.GETSUPPORT_STATUS_REQUEST, getSupportStatusS);
}

function* supportStatusSaga() {
  yield takeEvery(type.SUPPORT_STATUSEDIT_REQUEST, supportStatusS);
}

function* settingSaga() {
  yield takeEvery(type.CREATE_SETTING_REQUEST, settingS);
}
function* getsettingSaga() {
  yield takeEvery(type.GET_SETTING_REQUEST, getsettingS);
}

function* FaqlistSaga() {
  yield takeEvery(type.FAQ_LIST_REQUEST, FaqlistS);
}

function* supportlistSaga() {
  yield takeEvery(type.SUPPORTTICKET_LIST_REQUEST, supportlistS);
}

function* FaqtypeSaga() {
  yield takeEvery(type.FAQ_TYPE_REQUEST, FaqtypeS);
}
function* addFaqSaga() {
  yield takeEvery(type.ADD_FAQ_REQUEST, addFaqS);
}
function* orderListSaga() {
  yield takeEvery(type.ORDER_LIST_REQUEST, orderlistS);
}

function* typedetailsSaga() {
  yield takeEvery(type.TYPE_DETAILS_REQUEST, typedetailsS);
}

function* typeeditSaga() {
  yield takeEvery(type.TYPE_UPDATE_REQUEST, typeeditS);

}

function* specialitydetailsSaga() {
  yield takeEvery(type.SPECIALITY_DETAILS_REQUEST, specialitydetailsS);
}

function* specialityeditSaga() {
  yield takeEvery(type.SPECIALITY_UPDATE_REQUEST, specialityeditS);

}
function* bagsizedetailsSaga() {
  yield takeEvery(type.BAGSIZE_DETAILS_REQUEST, bagsizedetailsS);
}

function* bagsizeeditSaga() {
  yield takeEvery(type.BAGSIZE_UPDATE_REQUEST, bagsizeeditS);
}
function* bagsizeAddSaga() {
  yield takeEvery(type.BAGSIZE_ADD_REQUEST, bagsizeAddS);
}

function* bagsizelistSaga() {
  yield takeEvery(type.BAGSIZE_LIST_REQUEST, bagsizelistS);
}

function* activerestaurantTypeSaga() {
  yield takeEvery(type.ACTIVETYPE_LIST_REQUEST, activerestaurantTypeS);
}


function* typeAddSaga() {
  yield takeEvery(type.TYPE_ADD_REQUEST, typeAddS);
}

function* typeStatusSaga() {
  yield takeEvery(type.TYPE_STATUS_REQUEST, typestatusS);
}


function* bagsizeStatusSaga() {
  yield takeEvery(type.BAGSIZE_STATUS_REQUEST, bagsizestatusS);
}

function* typelistSaga() {
  yield takeEvery(type.TYPE_LIST_REQUEST, typelistS);
}

function* countryeditSaga() {
  yield takeEvery(type.COUNTRY_EDIT_REQUEST, countryeditS);
}

function* documentViewSaga() {
  yield takeEvery(type.DOCUMENT_VIEWLIST_REQUEST, documentViewS);
}

function* stateeditSaga() {
  yield takeEvery(type.STATE_EDIT_REQUEST, stateeditS);
}
function* cityeditSaga() {
  yield takeEvery(type.CITY_EDIT_REQUEST, cityeditS);
}
function* cityDetailsSaga() {
  yield takeEvery(type.CITY_DETAILS_REQUEST, cityDetailsS);
}
function* stateDetailsSaga() {
  yield takeEvery(type.STATE_DETAILS_REQUEST, stateDetailsS);
}
function* countryDetailsSaga() {
  yield takeEvery(type.COUNTRY_DETAILS_REQUEST, countryDetailsS);
}
function* accountStatusSaga() {
  yield takeEvery(type.ACCOUNT_STATUS_REQUEST, accountStatusS);
}
function* restraurantTypeListSaga() {
  yield takeEvery(type.RESTAURANT_TYPELIST_REQUEST, restraurantTypeListS);
}
function* restraurantviewSaga() {
  yield takeEvery(type.RESTAURANT_VIEW_REQUEST, restraurantviewS);
}
function* loginSaga() {
  yield takeEvery(type.LOGIN_USER_REQUEST, loginUsers);
}

function* sendemailSaga() {
  yield takeEvery(type.SEND_EMAIL_REQUEST, sendemailS);
}

function* countryListSaga() {
  yield takeEvery(type.COUNTRY_LIST_REQUEST, countryListS);
}

function* stateListSaga() {
  yield takeEvery(type.STATE_LIST_REQUEST, stateListS);
}

function* cityListSaga() {
  yield takeEvery(type.CITY_LIST_REQUEST, cityListS);
}

function* vendorListSaga() {
  yield takeEvery(type.VENDOR_LIST_REQUEST, vendorListS);
}

function* countrystatusSaga() {
  yield takeEvery(type.COUNTRY_STATUS_REQUEST, countrystatusS);
}

function* statestatusSaga() {
  yield takeEvery(type.STATE_STATUS_REQUEST, statestatusS);
}

function* citystatusSaga() {
  yield takeEvery(type.CITY_STATUS_REQUEST, citystatusS);
}

function* countryaddSaga() {
  yield takeEvery(type.ADD_COUNTRY_REQUEST, countryaddS);
}

function* stateaddSaga() {
  yield takeEvery(type.ADD_STATE_REQUEST, stateaddS);
}

function* cityaddSaga() {
  yield takeEvery(type.ADD_CITY_REQUEST, cityaddS);
}

function* activecountrySaga() {
  yield takeEvery(type.ACTIVE_COUNTRYLIST_REQUEST, activecountryS);
}

function* specialitySaga() {
  yield takeEvery(type.SPECIALITY_LIST_REQUEST, specialityS);
}

function* specialityaddSaga() {
  yield takeEvery(type.ADD_SPECIALITY_REQUEST, specialityaddS);
}

function* specialitystatusSaga() {
  yield takeEvery(type.SPECIALITY_STATUS_REQUEST, specialitystatusS);
}

function* activestatelistSaga() {
  yield takeEvery(type.ACTIVE_STATELIST_REQUEST, activestatelistS);
}

function* documentListSaga() {
  yield takeEvery(type.DOCUMENT_LIST_REQUEST, documentListS);
}


function* userListSaga() {
  yield takeEvery(type.User_LIST_REQUEST, userListS);
}

function* userviewSaga() {
  yield takeEvery(type.RESTAURANT_VIEW_REQUEST, UserviewS);
}

function* CommissionSaga() {
  yield takeEvery(type.DASHBOARD_COMMISSION_DATA_REQUEST, CommissionS);
}
export {
  loginSaga,
  activerestaurantTypeSaga,
  DashboardSaga,
  ExcleDownloadOrderSaga,
  supportStatusSaga,
  settingSaga,
  getsettingSaga,
  getSupportStatusSaga,
  supportlistSaga,
  FaqlistSaga,
  createorderlistSaga,
  FaqtypeSaga,
  orderListSaga,
  countryeditSaga,
  sendemailSaga,
  documentListSaga,
  cityDetailsSaga,
  specialityeditSaga,
  specialitydetailsSaga,
  stateDetailsSaga,
  countryDetailsSaga,
  countryListSaga,
  stateListSaga,
  cityListSaga,
  countrystatusSaga,
  statestatusSaga,
  citystatusSaga,
  countryaddSaga,
  stateaddSaga,
  cityaddSaga,
  activecountrySaga,
  specialitySaga,
  typeeditSaga,
  specialityaddSaga,
  specialitystatusSaga,
  activestatelistSaga,
  vendorListSaga,
  restraurantviewSaga,
  restraurantTypeListSaga,
  accountStatusSaga,
  cityeditSaga,
  stateeditSaga,
  documentViewSaga,
  bagsizeAddSaga,
  bagsizelistSaga,
  typeAddSaga,
  typedetailsSaga,
  typelistSaga,
  bagsizeStatusSaga,
  typeStatusSaga,
  bagsizedetailsSaga,
  bagsizeeditSaga,
  addFaqSaga,
  sendChatSaga,
  getChatSaga,
  userListSaga,
  userviewSaga,
  CommissionSaga,
};
