import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ToastMessage from "../../utils/ToastMessage";
import DataTable from "react-data-table-component";
import { CButton, CRow, CCol } from "@coreui/react";
import { useNavigate, useParams } from "react-router-dom";
import { orderList_api } from "../../API/baseUrl";
import { countryStatusRequest, UserList } from "../../redux/actions";
import ClearIcon from '@mui/icons-material/Clear';
import { TableSk } from "../skelaton/tableSk";
import Switch from '@mui/material/Switch';
import { FormControlLabel } from "@mui/material";
import { Tabs, Tab } from "react-bootstrap";
import styled from "@mui/material/styles/styled";
import './index.css'
import LoaderAnimation from "../../logo-2/loader/loaderanimation";

const LiveVendorOrder = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [pending, setPending] = useState(true);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [sortBy, setSortBy] = useState("DESC");
    const [sortByColumnName, setSortByColumnName] = useState("");
    const [typeforlist, setTypeforlist] = useState("Accepted"); // Default to 'Pending' tab
    const dispatch = useDispatch();
    const { id } = useParams();
    const { vendorListInfo } = useSelector((state) => state?.vendorList || {});

    useEffect(() => {
        document.title = "Users";
    }, []);

    useEffect(() => {
        const parameter = {
            orderStatus: typeforlist,
            offset,
            limit,
            search: searchText,
            columnName: sortByColumnName,
            sortBy: sortBy.toUpperCase(),
            url: `${orderList_api}${id}`,
            startDate: "",
            endDate: ""
        };
        dispatch(UserList(parameter));
    }, [offset, limit, searchText, sortByColumnName, sortBy, typeforlist, id]);

    useEffect(() => {
        if (vendorListInfo?.status) {
            setData(vendorListInfo?.data);
            setTotalRows(vendorListInfo?.count);
            setPending(false);
        } else {
            ToastMessage("error", vendorListInfo?.message);
        }
    }, [vendorListInfo]);

    const handleView = (id) => {

        // *****+-
        navigate(`/dashboard/createorderlist/${id}`);
    };

    const handlePerRowsChange = (newLimit, offset) => {
        setLimit(newLimit);
        setOffset(offset);
    };

    const handlePageChange = (offset) => {
        setOffset(offset);
    };

    const handleSort = (column, sortDirection) => {
        setSortBy(sortDirection);
        setSortByColumnName(column.column_name || "");
    };

    const handleclearSearch = () => {
        setSearchText("");
    };

    const PinkSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: "#267887",
            '&:hover': {
                backgroundColor: 'none',
            },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: "#267887",
        },
    }));

    const columns = [
        {
            name: "Id",
            width: "180px",
            selector: (row) => (
                <p className="id_navigate" onClick={() => handleView(row?.orderId)}>
                    {row?.orderIdFK ? row.orderIdFK : "---"}
                </p>
            ),
            sortable: true,
            column_name: "orderId",
            reorder: true,
        },
        {
            name: "Total Order Amount",
            width: "250px",
            selector: (row) => row.totalOrderAmount ? row.totalOrderAmount : "---",
            sortable: true,
            column_name: "totalOrderAmount",
            reorder: true,
        },
        {
            name: "Surprise Bag Category",
            width: "200px",

            selector: (row) => row.surpriseBagCategory ? (
                <p className="margin-0">
                    {row.surpriseBagCategory}
                </p>
            ) : "---",
            column_name: "surpriseBagCategory",
            reorder: true,
        },
        {
            name: "User Email",
            width: "200px",
            selector: (row) => row.userEmail ? row.userEmail : "---",
            column_name: "userEmail",
            reorder: true,
        },

    ];

    return (
        <div>
            {/* <div>
                <CRow>
                    <CCol
                        className="d-grid gap-2 d-md-flex justify-content-md-center pb-5"
                        lg={3}
                        md={3}
                        sm={3}
                        xs={7}
                    >
                        <div className="search-feild">
                            <input
                                type="text"
                                placeholder="Search"
                                style={{ outline: 'none' }}
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                            {searchText && (
                                <ClearIcon
                                    onClick={handleclearSearch}
                                    sx={{
                                        color: `var(--colorused)`,
                                        cursor: 'pointer',
                                        fontSize: '20px',
                                    }}
                                />
                            )}
                        </div>
                    </CCol>
                </CRow>
            </div> */}
            <Tabs
                style={{ padding: '20px', color:"red" }}
                activeKey={typeforlist}
                onSelect={(k) => setTypeforlist(k)}
                className="nav-tabs"
            >
                <Tab className="nav-link" eventKey="Accepted" title="Pending" 
                style={{color:"green"}}
                />
                <Tab className="nav-link" eventKey="Collected" title="Collected" 
                style={{color:"green"}}
                />
                <Tab className="nav-link" eventKey="Not-Collected" title="Not Collected" 
                style={{color:"green"}}
                />
            </Tabs>

            <DataTable
                customStyles={{
                    headCells: {
                        style: {
                            fontSize: "16px",
                            fontWeight: 500,
                        },
                    },
                }}
                columns={columns}
                data={data}
                dataAlign="center"
                fixedHeader
                pagination
                progressPending={pending}
                progressComponent={<LoaderAnimation />}
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                fixedHeaderScrollHeight="58vh"
                sortServer
                responsive
                onSort={handleSort}
            />
        </div>
    );
};

export default LiveVendorOrder;
