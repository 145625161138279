

import { CButton, CCol, CFormInput, CFormSwitch, CRow } from "@coreui/react";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cityStatus_api, getcitylist_api } from "../../API/baseUrl";
import ClearIcon from '@mui/icons-material/Clear';

import {
  cityList,
  cityStatusRequest,
  removeDataFromReducer,
} from "../../redux/actions";
import ToastMessage from "../../utils/ToastMessage";
import { TableSk } from "../skelaton/tableSk";
import "./index.css";
import { CityTitle } from "../Title";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";
import { Box, CircularProgress, Modal, Typography } from "@mui/material";
import { useFormik } from "formik";



const Adminrole = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pending, setPending] = useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(localStorage.getItem('currentPage'));
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [sortBy, setSortBy] = useState("DESC");
  const [sortByColumnName, setSortByColumnName] = useState("");
  const dispatch = useDispatch();
  const { cityListInfo } = useSelector((state) => state?.cityList || {});
  const { citystatusdata } = useSelector((state) => state?.citystatus) || {};


  const Intial = {
    Role: "",
  };
  

  const {
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: Intial,
    validationSchema: "",
    onSubmit: (values) => "",
  });





  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    outline:"none",
    p: 2,
  };

  const handleClose = () => setOpen(false);


  useEffect(() => {

    document.title = "Role";
  }, []);

  const handleAdd = (e) => {
    setOpen(true)
    // dispatch(removeDataFromReducer("CITY_DETAILS_RESET"));
    // navigate("/dashboard/city/add");
  };


  // Retrieve the saved page number from local storage on component mount
  // useEffect(() => {
  //   const savedPage = localStorage.getItem('currentPage');
  //   if (savedPage) {
  //     setCurrentPage(Number(savedPage));
  //   }
  // }, []);

  // // Update the page and save the current page to local storage
  // const handlePageChange = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  //   localStorage.setItem('currentPage', pageNumber);
  // };

  // useEffect(() => {
  //   if (cityListInfo?.status) {
  //     setData(cityListInfo?.data);
  //     setTotalRows(cityListInfo?.count);
  //     setPending(false);
  //   } else {
  //     ToastMessage("error", cityListInfo?.message);
  //   }
  // }, [cityListInfo]);

  useEffect(() => {
    const parameter = {
      offset: localStorage.getItem('currentPage'),
      limit: limit,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      url: getcitylist_api,
    };
    dispatch(cityList(parameter));
  }, [offset, limit, searchText, sortByColumnName, sortBy]);

  const handleStatus = (id) => {
    const parameter = {
      url: `${cityStatus_api}/${id} `,
    };
    dispatch(cityStatusRequest(parameter));
  };

  useEffect(() => {
    const parameter = {
      offset: localStorage.getItem('currentPage'),
      limit: limit,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      url: getcitylist_api,
    };
    if (citystatusdata?.status) {
      ToastMessage("success", citystatusdata?.message);
      dispatch(cityList(parameter));
      dispatch(removeDataFromReducer("CITY_STATUS_RESET"));
    } else {
      ToastMessage("error", citystatusdata?.message);
    }
  }, [citystatusdata]);

  const columns = [
    {
      name: "Id",
      selector: (row) => row?.cityId,
      sortable: true,
      column_name: "cityId",
      reorder: true,
    },
    {
      name: "Name",
      selector: (row) => row.cityName,
      sortable: true,
      column_name: "cityName",
      reorder: true,
    },
    {
      name: "State ",
      selector: (row) => row.stateName,
      // sortable: true,
      column_name: "stateName",
      reorder: true,
    },
    {
      name: "Country ",
      selector: (row) => row.countryName,
      // sortable: true,
      column_name: "countryName",
      reorder: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <CFormSwitch
          onClick={() => handleStatus(row.cityId)}
          label={row.isActive === 1 ? "Active" : "Inactive"}
          id="formSwitchCheckChecked"
          defaultChecked={row.isActive}
          style={{
            backgroundColor: row.isActive === 1 ? "var(--colorused)" : "initial",
            color: row.isActive === 1 ? "var(--colorWhite)" : "initial",
          }}
        />
      ),
      // sortable: true,
      column_name: "isActive",
      reorder: true,
    },
    {
      name: "Edit",
      selector: (row) => (
        <button className="edit_btn" onClick={() => handleEdit(row.cityId)}
          style={{
            backgroundColor: "var(--colorused)",
            border: "none",
            color: "var(--colorWhite)",
          }}
        >
          Edit
        </button>
      ),
      // sortable: true,
      column_name: "",
      reorder: true,
    },
  ];

  const handleEdit = (id) => {
    dispatch(removeDataFromReducer("CITY_DETAILS_RESET"));
    navigate(`/dashboard/city/add/${id}`);
  };

  const customStyles = {
    headCells: {
      style: {
        fontSize: "16px",
        fontWeight: 500,
      },
    },
  };
  const handlePerRowsChange = async (newLimit, offset) => {
    localStorage.setItem('currentPage', offset)
    setOffset(offset);
    setLimit(newLimit);
  };

  const handlePageChange = (offset) => {
    localStorage.setItem('currentPage', offset)
    setOffset(offset);
  };

  const handleSort = async (column, sortDirection) => {
    setSortBy(sortDirection);
    setSortByColumnName(column.column_name || "");
  };


  const handleclearSearch = () => {
    setSearchText("");
  };
  return (
    <div>


      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "20px",
          flexWrap: "wrap",
        }}
        lg={8}
      >
        <div>
          <h3 style={{ margin: 0 }}>Role</h3>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="search-feild mx-1">
            <input
              type="text"
              placeholder="Search"
              style={{ outline: "none" }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            {searchText ? (
              <ClearIcon
                onClick={handleclearSearch}
                sx={{
                  color: `var(--colorused)`,
                  cursor: "pointer",
                  fontSize: "20px",
                }}
              />
            ) : (
              ""
            )}
          </div>
          <CButton
            style={{
              backgroundColor: "var(--colorused)",
              border: "none",
              color: "var(--colorWhite)",
              fontSize: "13px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            className="me-md-2 btn_add_country"
            onClick={handleAdd}
          // disabled={isLoading}
          >
            Add Role
          </CButton>
        </div>
      </div>
      {/* <div className="d-grid gap-2 d-md-flex justify-content-md-end pb-5">
        <CButton color="primary" className="me-md-2" onClick={handleAdd}>Add City</CButton>
      </div> */}
      <DataTable
        customStyles={customStyles}
        columns={columns}
        data={data}
        dataAlign="center"
        fixedHeader
        progressPending={pending}
        progressComponent={<LoaderAnimation />}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        fixedHeaderScrollHeight="58vh"
        sortServer
        responsive
        onSort={handleSort}
      />


      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
             Add Role
          </Typography> */}
          <CFormInput
          name="Role"
          type="text"
          value={values.Role}
          id="exampleFormControlInput1"
          label="User Role*"
          placeholder="User Role"
          onChange={handleChange}
          onBlur={handleBlur}
          // text={errors.cityName}
          invalid={touched.Role && !!errors.Role}

          aria-describedby="exampleFormControlInputHelpInline"
          className="field-width_modal"

        />
        {touched.Role && errors.Role && (
          <div className="invalid-feedback">{errors.Role}</div>
        )}


<div className="d-grid gap-2 d-md-flex justify-content-md-end py-3">
    
      <CButton style={{
        backgroundColor: "red",
        border: "none",
        // width: "120px",
        color: "var(--colorWhite)",
      }} type="button"
      onClick={handleClose}
      className="me-md-2">
        {loading ? (
          <CircularProgress size={24} style={{ color: "white" }} />
        ) :

          <>Cancel</>
        }

      </CButton> 
      
      <CButton style={{
        backgroundColor: "var(--colorused)",
        border: "none",
        // width: "120px",
        color: "var(--colorWhite)",
      }} type="submit" className="me-md-2">
        {loading ? (
          <CircularProgress size={24} style={{ color: "white" }} />
        ) :

          <>Submit</>
        }

      </CButton>
    </div>


        </Box>
      </Modal>




    </div>
  );
};

export default Adminrole;