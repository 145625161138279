import { CButton, CCol, CRow } from "@coreui/react";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./index.css"
import {
    countryStatus_api,
    faqList_api,
    getcountrylist_api,
    supportTicketList_api,
} from "../../API/baseUrl";
import {
    countryList,
    countryStatusRequest,
    faqListRequest,
    removeDataFromReducer,
    supportTicketListRequest,
} from "../../redux/actions";
import ToastMessage from "../../utils/ToastMessage";
import DataTable from "react-data-table-component";
import { TableSk } from "../skelaton/tableSk";
import { PrivacyPolicyTitle, SupportTicketListTitle } from "../Title";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";

const SupportList = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [pending, setPending] = useState(true); // State for loading indicator
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [typeforlist, setTypeforlist] = useState("vendor");
    const [sortBy, setSortBy] = useState("DESC");
    const [sortByColumnName, setSortByColumnName] = useState("");
    const dispatch = useDispatch();
    const { supportListdata } = useSelector((state) => state?.supportListReduser || {});
   



    
    
  useEffect(() => {
    if (supportListdata) {
      setPending(true); 
      const timer = setTimeout(() => {
        setPending(false);
        if (supportListdata?.status) {
          setData(supportListdata?.data);
          setTotalRows(supportListdata?.count);
          setPending(false);
      } else {
          ToastMessage("error", supportListdata?.message);
      }
      }, 500); 
      return () => clearTimeout(timer);
    }
  }, [supportListdata]);

    const handleType = (type) =>{
        setTypeforlist(type)
    }
    useEffect(() => {

      document.title = SupportTicketListTitle;
    }, []);
    useEffect(() => {
        const parameter = {
            offset: offset,
            limit: limit,
            userType:typeforlist,
            search: searchText,
            columnName: sortByColumnName,
            sortBy: sortBy.toUpperCase(),
            url: supportTicketList_api,
        };
        dispatch(supportTicketListRequest(parameter));
    }, [offset, limit, searchText, sortByColumnName, sortBy, typeforlist]);


    const handleView = (id) => {
      navigate(`/dashboard/supportView/${id}`);
    }
  
 
    const columns = [
        {
            name: "Id",
            width:"150px",
            selector: (row) => (row?.ticketId ? row.ticketId : "---"),
            column_name: "ticketId",
            reorder: true,
            cell: (row) => (
              <div onClick={()=>handleView(row?.ticketIdFk)} className="id_navigate">
                {row.ticketId ? row.ticketId : "---"}
              </div>
            ),
          },
          {
            name: "User Name",
            selector: (row) => (row.userName ? row.userName : "---"),
            column_name: "userName",
            minWidth:"250px",
            reorder: true,
            cell: (row) => (
              <div className="">
                {row.userName ? row.userName : "---"}
              </div>
            ),
          },
          {
            name: "Email",
            minWidth:"250px",
            selector: (row) => (row.email ? row.email : "---"),
            column_name: "email",
            reorder: true,
            cell: (row) => (
              <div className="">
                {row.email ? row.email : "---"}
              </div>
            ),
          },
          // {
          //   name: "Country Code",
          //   minWidth:"250px",
          //   selector: (row) => (row.countryCode ? row.countryCode : "---"),
          //   column_name: "countryCode",
          //   reorder: true,
          //   cell: (row) => (
          //     <div className="">
          //       {row.countryCode ? row.countryCode : "---"}
          //     </div>
          //   ),
          // },
          {
            name: "Mobile Number",
            minWidth:"250px",
            selector: (row) => <p className="margin-0">+{row.countryCode} {row.mobileNumber ? row.mobileNumber : "---"}</p>,
            column_name: "mobileNumber",
            reorder: true,
            cell: (row) => (
              <div className="">
                <p className="margin-0">{row.countryCode} {row.mobileNumber ? row.mobileNumber : "---"}</p>
              </div>
            ),
          },
          {
            name: "Purpose",
            minWidth:"250px",
            selector: (row) => (row.purpose ? row.purpose : "---"),
            column_name: "purpose",
            reorder: true,
            cell: (row) => (
              <div className="">
                {row.purpose ? row.purpose : "---"}
              </div>
            ),
          },
          {
            name: "Status",
            selector: (row) => (row.supportTicketStatus ? row.supportTicketStatus : "---"),
            column_name: "supportTicketStatus",
            reorder: true,
            cell: (row) => (
              <div className="">
                {row.supportTicketStatus ? row.supportTicketStatus : "---"}
              </div>
            ),
          },
       
    ];

 

    const customStyles = {
      headCells: {
          style: {
              fontSize: "16px",
              fontWeight: 500,
          },
      },
  };

    const handlePerRowsChange = async (newLimit, offset) => {
        setOffset(offset);
        setLimit(newLimit);
    };

    const handlePageChange = (offset) => {
        setOffset(offset);
    };

    const handleSort = async (column, sortDirection) => {
        setSortBy(sortDirection);
        setSortByColumnName(column.column_name || "");
    };

    const handleclearSearch = () => {
        setSearchText("");
    };

    return (
        <div>
            <div>
            <CRow>
          <CCol
            className="d-grid gap-2 d-md-flex justify-content-md-start pb-2"
            lg={9}
            md={9}
            sm={8}
            xs={12}
          >
            <h3>Support Ticket List</h3>
          </CCol>
          <CCol className="d-grid gap-2 d-md-flex justify-content-md-center pb-2"
             lg={3}
             md={3}
             sm={3}
             xs={7}>
              <div className="search-feild">
            <input
              type="text"
              placeholder="Search"
              
              style={{ outline: 'none' }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            {searchText ? <ClearIcon onClick={handleclearSearch} sx={{color:`var(--colorused)` , cursor:"pointer", fontSize:"20px"}} /> : ""}
            
            </div>
          </CCol>

        </CRow>
            </div>

            <div>

                <Tabs style={{ padding: "10px 20px" }} >
                    <TabList className="TabList">
                        <Tab className="Tab" onClick={()=>handleType("vendor")}>
                            Vendor
                        </Tab>
                        <Tab className="Tab" onClick={()=>handleType("customer")}>
                           Customer
                        </Tab>
                    </TabList>
                    <TabPanel>
                        <div className="m-t-10">
                            <DataTable
                                customStyles={customStyles}
                                columns={columns}
                                data={data}
                                dataAlign="center"
                                fixedHeader
                                progressPending={pending}
                                progressComponent={<LoaderAnimation />}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                fixedHeaderScrollHeight="58vh"
                                sortServer
                                responsive
                                onSort={handleSort}
                            />
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="m-t-10">
                            <DataTable
                                customStyles={customStyles}
                                columns={columns}
                                data={data}
                                dataAlign="center"
                                fixedHeader
                                progressPending={pending}
                                progressComponent={<LoaderAnimation />}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                fixedHeaderScrollHeight="58vh"
                                sortServer
                                responsive
                                onSort={handleSort}
                            />
                        </div>
                    </TabPanel>
                   
                </Tabs>
            </div>


        </div>
    );
};

export default SupportList;
