

import React, { useEffect, useState } from "react";
import { CButton, CCol, CContainer, CForm, CFormInput, CFormSelect, CFormTextarea, CRow } from "@coreui/react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { create_faq_api, get_restaurantType_api, getbyId_faq_api, typecreate_api, typelist_faq_api, update_faq_api, update_type_api } from "../../API/baseUrl";
import { editTypeRequest, removeDataFromReducer, typeDetailsRequest, faqadd, faqTypeRequest } from "../../redux/actions";
import ToastMessage from "../../utils/ToastMessage";
import CircularProgress from "@mui/material/CircularProgress";
import { addFaqshema, addtypeshema } from "../../utils/schema";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const AddFaq = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { createfaqdata } = useSelector((state) => state?.faqAddReduser || {});

  const { typeupdatedata } = useSelector((state) => state?.typeupdateReduser || {});

  const { faqtypedata } = useSelector((state) => state?.faqtypelistReduser || {});

  const { typedetailsdata } = useSelector((state) => state?.typedetailsReduser || {});

  const [loading, setLoading] = useState(false); // State for managing loader
  const [faqtypeList, setFaqtypeList] = useState([]); 

  const initialValues = {
    faqTypeIdFk: "",
    question: "",
    answer: "",
  };


  console.log("789", faqtypedata)
 
  const formik = useFormik({
    initialValues,
    validationSchema: addFaqshema,
    onSubmit: (values) => {
      ApiHandlesubmit(values);
    },
  });

  useEffect(() => {
    if (faqtypedata?.status) {
      setFaqtypeList(faqtypedata?.data);
    } else {
      ToastMessage("error", faqtypedata?.message);
    }
  }, [faqtypedata]);


  useEffect(() => {
    if (createfaqdata?.status) {
      ToastMessage("success", createfaqdata?.message);
      dispatch(removeDataFromReducer("ADD_FAQ_RESET"));
      navigate("/dashboard/faq");
      setLoading(false); 
    } else {
      ToastMessage("error", createfaqdata?.message);
      setLoading(false); 
    }
  }, [createfaqdata]);


 useEffect(() => {
    if (typeupdatedata?.status) {
      ToastMessage("success", typeupdatedata?.message);
      dispatch(removeDataFromReducer("TYPE_UPDATE_RESET"));
      dispatch(removeDataFromReducer("TYPE_DETAILS_RESET"));
      navigate("/dashboard/type");
      setLoading(false); 
    } else {
      ToastMessage("error", typeupdatedata?.message);
      setLoading(false); 
    }
  }, [typeupdatedata]);


  useEffect(() => {
    if (typedetailsdata?.status) {
      console.log("typedetailsdata",typedetailsdata)
      formik.setFieldValue("question" , typedetailsdata?.data?.question)
      formik.setFieldValue("answer" , typedetailsdata?.data?.answer)
      formik.setFieldValue("faqTypeIdFk" , typedetailsdata?.data?.faqTypeIdFk)
    } 
  }, [typedetailsdata]);

const handleselectchange = (e) => {
    formik.values.faqTypeIdFk = e.target.value;
    formik.setFieldValue("faqTypeIdFk" , e.target.value)
  };



  const ApiHandlesubmit = async () => {
    setLoading(true)
    const parameter = {
      faqTypeIdFk: formik.values.faqTypeIdFk,
      question: formik.values.question,
      answer: formik.values.answer,
      url: id ? `${update_faq_api}${id}` : create_faq_api ,
    };
      dispatch(faqadd(parameter));
  };


  
  useEffect(() => {
    const parameter = {
      url: `${getbyId_faq_api}${id}`,
    };
    dispatch(typeDetailsRequest(parameter));
  }, [id]);


  useEffect(() => {
    const parameter = {
      search: "",   
      url: typelist_faq_api,
    };
    dispatch(faqTypeRequest(parameter));
  }, []);

  const handleBackNavigation = () => {
    navigate(-1); // Navigates back to the previous page
  };

  return (
    <CForm onSubmit={formik.handleSubmit}>
      <h3 className="AddEdit_Heading">  <ArrowBackIcon onClick={handleBackNavigation} style={{cursor:"pointer"}}/>{id ? "Edit FAQ" : "Add FAQ"}</h3>

      <CContainer className="add_form_container">
        <CRow xs={{ gutter: 4 }}>



        <CCol sm={12} md={12} lg={12}>
            <CFormSelect
              name="faqTypeIdFk"
              label="FAQ Type *"
              placeholder="FAQ Type"
              value={formik.values.faqTypeIdFk}
              // text={errors.countryname}
              invalid={formik.touched.faqTypeIdFk && !!formik.errors.faqTypeIdFk}
              onChange={handleselectchange}
              onBlur={formik.handleBlur}
              // className="field-width"

            >
              <option value="" disabled selected> 
                Select a Type
              </option>
              {faqtypeList?.map((type, index) => (
                <option key={index} value={type.id}>
                  {type.type}
                </option>
              ))}
                  {formik.touched.faqTypeIdFk && formik.errors.faqTypeIdFk && (
              <div className="invalid-feedback">{formik.errors.faqTypeIdFk}</div>
            )}
            </CFormSelect>
          </CCol>





          <CCol sm={12} md={12} lg={12}>
            <CFormInput
              name="question"
              type="text"
              value={formik.values.question}
              id="exampleFormControlInput1"
              label="Question *"
              placeholder="Question"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              aria-describedby="exampleFormControlInputHelpInline"
              autoComplete="off"
              invalid={formik.touched.question && !!formik.errors.question}
            />
            {formik.touched.question && formik.errors.question ? (
              <div className="invalid-feedback">{formik.errors.question}</div>
            ) : null}
          </CCol>
       
          <CCol sm={12} md={12} lg={12}>
            <CFormTextarea
              name="answer"
              type="text"
              rows={4}
              value={formik.values.answer}
              id="exampleFormControlInput1"
              label="Answer *"
              placeholder="Answer"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              aria-describedby="exampleFormControlInputHelpInline"
              autoComplete="off"
              invalid={formik.touched.answer && !!formik.errors.answer}
            />
            {formik.touched.answer && formik.errors.answer ? (
              <div className="invalid-feedback">{formik.errors.answer}</div>
            ) : null}
          </CCol>
        </CRow>

        <div className="d-grid gap-2 d-md-flex justify-content-md-end py-5">
        <CButton style={{
            backgroundColor: "var(--colorused)",
            border: "none",
            width: "120px",
            color: "var(--colorWhite)",
          }} type="submit" className="me-md-2">
            {loading ? (
              <CircularProgress size={24} style={{ color: "white" }} />
            ) :

              <>{id ? "Update" : "Submit"}</>
            }

          </CButton>
        </div>
      </CContainer>
    </CForm>
  );
};

export default AddFaq;





