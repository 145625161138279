/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import { Grid } from '@mui/material'
import React from 'react'
import "./index.css"

const ViewScreen = ({ children }) => {
    return (
        <Grid className='view_membership' container spacing={4}>
            {children}
        </Grid>
    )
}

export default ViewScreen