import { CButton, CFormTextarea, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CFormSelect,
    CRow,
} from "@coreui/react";
import { useFormik } from "formik";
import { change_ticket_status_api, } from "../../API/baseUrl";
import { removeDataFromReducer,  supportStatusedit } from "../../redux/actions";
import ToastMessage from "../../utils/ToastMessage";


const Intial = {
    restaurantType: "",
    reasonOfRejection: "",
};



const SupportStatusModal = ({ticket,ticketId, visible, setVisible, restaurantId, statusId, setStatusvalue }) => {
    const dispatch = useDispatch();
    const { supportStatusdata } = useSelector((state) => state?.supportStatusReduser || {});

    console.log("wwww", supportStatusdata)
    const {
        handleChange,
        handleSubmit,
        setFieldValue,
        handleBlur,
        values,
        errors,
        touched,
    } = useFormik({
        initialValues: Intial,
        validationSchema: "",
        onSubmit: (values) => ApiHandlesubmit(),
    });


    const handleSubmitform = (e) => {
        e.preventDefault();
        handleSubmit();
        console.log("error", errors);
        console.log("values", values);
    };

    useEffect(() => {
        if (supportStatusdata?.status) {
            setStatusvalue(statusId)
            setVisible(false);
            ToastMessage("success", supportStatusdata?.message);
            dispatch(removeDataFromReducer("SUPPORT_STATUSEDIT_RESET"));
        } else {
            ToastMessage("error", supportStatusdata?.message);
        }
    }, [supportStatusdata]);


 


    const ApiHandlesubmit = async () => {
        const parameter = {
            statusIdFk: statusId,
            url:`${change_ticket_status_api}${ticketId}` ,
        };
        dispatch(supportStatusedit(parameter));
    };


    return (
        <div>
            <CModal
                backdrop="static"
                visible={visible}
                onClose={() => setVisible(false)}
                aria-labelledby="StaticBackdropExampleLabel"
            >
                <CModalHeader>
                    <CModalTitle style={{padding:'4px 31px'}} className="mt-3" id="StaticBackdropExampleLabel">{ticket == 'true' ? "Update Ticket Status" :"Update Document Status"}</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CForm onSubmit={handleSubmitform}>
                        <CContainer>
                            <CRow xs={{ gutter: 2 }}>

                           { ticket == 'true' ? <p>Are you sure you want to change the status ?</p> : <>{statusId == 0 || statusId == 1 ? <p>Are you sure you want to change the status ?</p> : ""}</>}
                                 

                            </CRow>

                        </CContainer>

                        <CModalFooter className="modalFooter">
                            <CButton color="secondary" onClick={() => setVisible(false)}>
                                Close
                            </CButton>
                            <CButton type="submit" className="btn_color" >Update</CButton>
                        </CModalFooter>
                    </CForm>
                </CModalBody>

            </CModal>
        </div>
    );
};

export default SupportStatusModal;
