import React from 'react';
import { Skeleton, Grid, Box, Typography } from '@mui/material';

const DashboardSkeleton = () => {
  return (
    <Box sx={{ padding: '20px' }}>
      {/* Header */}
      <Typography variant="h4">
        <Skeleton width={200} />
      </Typography>

      {/* Period Selection */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
        <Skeleton variant="text" width={80} height={30} sx={{ marginRight: 2 }} />
        <Skeleton variant="rectangular" width={120} height={30} />
      </Box>

      {/* Top Row Cards */}
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        <Grid item xs={12} sm={4}>
          <Box sx={{ padding: 2, borderRadius: 2, boxShadow: 1 }}>
            <Skeleton variant="text" width="60%" />
            <Skeleton variant="text" width="80%" height={40} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box sx={{ padding: 2, borderRadius: 2, boxShadow: 1 }}>
            <Skeleton variant="text" width="60%" />
            <Skeleton variant="text" width="80%" height={40} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box sx={{ padding: 2, borderRadius: 2, boxShadow: 1 }}>
            <Skeleton variant="text" width="60%" />
            <Skeleton variant="text" width="80%" height={40} />
          </Box>
        </Grid>
      </Grid>

      {/* Main Content Area */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <Box sx={{ padding: 2, borderRadius: 2, boxShadow: 1, height: 250 }}>
            <Skeleton variant="text" width="40%" />
            <Skeleton variant="text" width="60%" height={40} />
            <Skeleton variant="rectangular" width="100%" height={160} sx={{ marginTop: 2 }} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box sx={{ padding: 2, borderRadius: 2, boxShadow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', height: 250 }}>
            <Skeleton variant="circular" width={80} height={80} sx={{ marginBottom: 2 }} />
            <Skeleton variant="text" width="60%" />
          </Box>
        </Grid>
      </Grid>

      {/* Main Content Area */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <Box sx={{ padding: 2, borderRadius: 2, boxShadow: 1, height: 250 }}>
            <Skeleton variant="text" width="40%" />
            <Skeleton variant="text" width="60%" height={40} />
            <Skeleton variant="rectangular" width="100%" height={160} sx={{ marginTop: 2 }} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box sx={{ padding: 2, borderRadius: 2, boxShadow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', height: 250 }}>
            <Skeleton variant="circular" width={80} height={80} sx={{ marginBottom: 2 }} />
            <Skeleton variant="text" width="60%" />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardSkeleton;
