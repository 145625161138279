import { CButton, CCol, CFormSwitch, CRow } from "@coreui/react";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import "react-tabs/style/react-tabs.css";
import "./index.css"
import {
    countryStatus_api,
    faq_active_api,
    faqList_api,
    getcountrylist_api,
} from "../../API/baseUrl";
import {
    countryList,
    countryStatusRequest,
    faqListRequest,
    removeDataFromReducer,
} from "../../redux/actions";
import ToastMessage from "../../utils/ToastMessage";
import DataTable from "react-data-table-component";
import { TableSk } from "../skelaton/tableSk";
import { FormControlLabel } from "@mui/material";
import { FaqTitleTitle } from "../Title";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";


const FaqList = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [pending, setPending] = useState(true); // State for loading indicator
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [typeforlist, setTypeforlist] = useState("Magic Bag");
    const [sortBy, setSortBy] = useState("DESC");
    const [sortByColumnName, setSortByColumnName] = useState("");
    const dispatch = useDispatch();
    const { faqListdata } = useSelector((state) => state?.faqlistReduser || {});
    const { countrystatusdata } =
        useSelector((state) => state?.countrystatus) || {};


    
  useEffect(() => {
    if (faqListdata) {
      setPending(true); 
      const timer = setTimeout(() => {
        setPending(false);
        if (faqListdata?.status) {
            setData(faqListdata?.data);
            setTotalRows(faqListdata?.count);
        } else {
            ToastMessage("error", faqListdata?.message);
        }
      }, 500); 
      return () => clearTimeout(timer);
    }
  }, [faqListdata]);

    const handleType = (type) => {
        setTypeforlist(type)
    }

    useEffect(() => {
        const parameter = {
            offset: offset,
            limit: limit,
            type: typeforlist,
            search: searchText,
            columnName: sortByColumnName,
            sortBy: sortBy.toUpperCase(),
            url: faqList_api,
        };
        dispatch(faqListRequest(parameter));
    }, [offset, limit, searchText, sortByColumnName, sortBy, typeforlist]);

    useEffect(() => {

        document.title = FaqTitleTitle;
      }, []);

    const handleStatus = (id) => {
        const parameter = {
            url: `${faq_active_api}${id} `,
        };
        dispatch(countryStatusRequest(parameter));
    };


    const handleAddCountry = async (e) => {
        dispatch(removeDataFromReducer("ADD_FAQ_RESET"));
        navigate("/dashboard/faq/add");
    };

    useEffect(() => {
        const parameter = {
            offset: offset,
            limit: limit,
            type: typeforlist,
            search: searchText,
            columnName: sortByColumnName,
            sortBy: sortBy.toUpperCase(),
            url: faqList_api,
        };
        if (countrystatusdata?.status) {
            ToastMessage("success", countrystatusdata?.message);
            dispatch(faqListRequest(parameter))
            dispatch(removeDataFromReducer("COUNTRY_STATUS_RESET"));
        } else {
            ToastMessage("error", countrystatusdata?.message);
        }
    }, [countrystatusdata]);

    const columns = [
        {
            name: "Id",
            width: "150px",
            selector: (row) => (row?.faqIdFk ? row.faqIdFk : "---"),
            column_name: "faqIdFk",
            reorder: true,
            cell: (row) => (
                <div className="">
                    {row.faqIdFk ? row.faqIdFk : "---"}
                </div>
            ),
        },
        {
            name: "FAQ Type",
            selector: (row) => (row.type ? row.type : "---"),
            column_name: "type",
            width: "150px",
            reorder: true,
            cell: (row) => (
                <div className="">
                    {row.type ? row.type : "---"}
                </div>
            ),
        },
        {
            name: "Question",
            selector: (row) => (row.question ? row.question : "---"),
            column_name: "question",
            reorder: true,
            cell: (row) => (
                <div className="custom-column_question">
                    {row.question ? row.question : "---"}
                </div>
            ),
        },
        {
            name: "Answer",
            selector: (row) => (row.answer ? row.answer : "---"),
            column_name: "answer",
            reorder: true,
            cell: (row) => (
                <div className="custom-column">
                    {row.answer ? row.answer : "---"}
                </div>
            ),
        },
        {
            name: " Status",

            selector: (row) => (
                //   <CFormSwitch
                //     onClick={() => handleStatus(row.faqIdFk)}
                //     label={row.isActive === 1 ? "Active" : "Inactive"}
                //     id="formSwitchCheckChecked"
                //     defaultChecked={row.isActive}
                //     style={{
                //       backgroundColor: "var(--colorused)",
                //       border: "none",
                //       color: "var(--colorWhite)",
                //     }}
                //   />

                <FormControlLabel
                    className='form_control_lable'
                    control={
                        <PinkSwitch
                            size="small"
                            defaultChecked={row.isActive}
                            checked={row.isActive == '1'}
                            onChange={() => handleStatus(row.faqIdFk)}
                        />
                    }
                    label={row.isActive === 1 ? "Active" : "Inactive"}
                />
            ),
            // sortable: true,
            column_name: "isActive",
            reorder: true,
        },
        {
            name: "Edit",
            selector: (row) => (
                <button className="edit_btn" onClick={() => handleEdit(row.faqIdFk)}
                    style={{
                        backgroundColor: "var(--colorused)",
                        border: "none",
                        color: "var(--colorWhite)",
                    }}
                >
                    Edit
                </button>
            ),
            // sortable: true,
            column_name: "",
            reorder: true,
        },

    ];

    const handleEdit = (id) => {
        dispatch(removeDataFromReducer("TYPE_DETAILS_RESET"));
        navigate(`/dashboard/faq/add/${id}`);
    };

    const customStyles = {
        headCells: {
            style: {
                fontSize: "16px",
                fontWeight: 500,
            },
        },
    };

    const handlePerRowsChange = async (newLimit, offset) => {
        setOffset(offset);
        setLimit(newLimit);
    };

    const handlePageChange = (offset) => {
        setOffset(offset);
    };

    const handleSort = async (column, sortDirection) => {
        setSortBy(sortDirection);
        setSortByColumnName(column.column_name || "");
    };

    const handleclearSearch = () => {
        setSearchText("");
    };





    const PinkSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: "#267887",
            '&:hover': {
                backgroundColor: 'none',
            },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: "#267887",
        },
    }));




    return (
        <div>

            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap:"wrap",
                }}

            >
                <div>
                    <h3 style={{ margin: 0 }}>Faq List</h3>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="search-feild mx-1">
                        <input
                            type="text"
                            placeholder="Search"
                            style={{ outline: "none" }}
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        {searchText ? (
                            <ClearIcon
                                onClick={handleclearSearch}
                                sx={{ color: `var(--colorused)`, cursor: "pointer", fontSize: "20px" }}
                            />
                        ) : (
                            ""
                        )}
                    </div>

                    <CButton
                        style={{
                            backgroundColor: "var(--colorused)",
                            border: "none",
                            color: "var(--colorWhite)",
                            fontSize: "13px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                        }}
                        className="me-md-2 btn_add_country"
                        onClick={handleAddCountry}
                    // disabled={isLoading}
                    >
                        Add FAQ
                    </CButton>
                </div>

            </div>


            <Tabs style={{ padding: "20px" }} >
                <TabList className="TabList">
                    <Tab className="Tab" onClick={() => handleType("Magic Bag")}>
                        Magic Bag
                    </Tab>
                    <Tab className="Tab" onClick={() => handleType("Restaurant")}>Restaurant</Tab>
                    <Tab className="Tab" onClick={() => handleType("Customer")}>
                        Customer
                    </Tab>
                </TabList>
                <TabPanel>
                    <div className="m-t-10">
                        <DataTable
                            customStyles={customStyles}
                            columns={columns}
                            data={data}
                            dataAlign="center"
                            fixedHeader
                            progressPending={pending}
                            progressComponent={<LoaderAnimation />}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            fixedHeaderScrollHeight="58vh"
                            sortServer
                            responsive
                            onSort={handleSort}
                        />
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="m-t-10">
                        <DataTable
                            customStyles={customStyles}
                            columns={columns}
                            data={data}
                            dataAlign="center"
                            fixedHeader
                            progressPending={pending}
                            progressComponent={<LoaderAnimation />}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            fixedHeaderScrollHeight="58vh"
                            sortServer
                            responsive
                            onSort={handleSort}
                        />
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="m-t-10">
                        <DataTable
                            customStyles={customStyles}
                            columns={columns}
                            data={data}
                            dataAlign="center"
                            fixedHeader
                            progressPending={pending}
                            progressComponent={<LoaderAnimation/>}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            fixedHeaderScrollHeight="58vh"
                            sortServer
                            responsive
                            onSort={handleSort}
                        />
                    </div>
                </TabPanel>
            </Tabs>
        </div>



    );
};

export default FaqList;
