import React from 'react';
import logo from "../assets/images/Logo-with-text.png";

function Logo() {
  return (
    <div className="login-div">
      <img src={logo} alt="pick'n'treat" height={50} className="login_logo_image1" />
    </div>
  );
}

export default Logo;
