import { CButton, CCol, CFormSwitch, CRow } from "@coreui/react";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  countryStatus_api,
  ImageUrl,
  setting_activeInactive_api,
  setting_list_api,
} from "../../API/baseUrl";
import ClearIcon from "@mui/icons-material/Clear";
import {
  countryList,
  countryStatusRequest,
  removeDataFromReducer,
} from "../../redux/actions";
import ToastMessage from "../../utils/ToastMessage";
import { TableSk } from "../skelaton/tableSk";
import RemoveTag from "../../components/removeTag/Index";
import { SettingsTitle } from "../Title";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";


const SettingList = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pending, setPending] = useState(true); // State for loading indicator
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [sortBy, setSortBy] = useState("DESC");
  const [sortByColumnName, setSortByColumnName] = useState("");
  const dispatch = useDispatch();
  const { countryListInfo } = useSelector((state) => state?.countryList || {});
  const { countrystatusdata } =
    useSelector((state) => state?.countrystatus) || {};
    useEffect(() => {

      document.title = SettingsTitle;
    }, []);
  useEffect(() => {
    if (countryListInfo?.status) {
      setData(countryListInfo?.data);
      setTotalRows(countryListInfo?.count);
      setPending(false);
    } else {
      ToastMessage("error", countryListInfo?.message);
    }
  }, [countryListInfo]);

  useEffect(() => {
    const parameter = {
      offset: offset,
      limit: limit,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      url: setting_list_api,
    };
    dispatch(countryList(parameter));
  }, [offset, limit, searchText, sortByColumnName, sortBy]);

  const handleStatus = (id) => {
    const parameter = {
      url: `${setting_activeInactive_api}${id} `,
    };
    dispatch(countryStatusRequest(parameter));
  };

  //   const handleAddCountry = (e) => {
  //     navigate("/dashboard/country/add");
  //     setIsLoading(true)

  //     setTimeout(() => {
  //         setIsLoading(false);
  //       }, 5000);
  //   };

  const handleAddCountry = async (e) => {
    dispatch(removeDataFromReducer("COUNTRY_DETAILS_RESET"));
    navigate("/dashboard/setting/add");
  };

  useEffect(() => {
    const parameter = {
      offset: offset,
      limit: limit,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      url: setting_list_api,
    };
    if (countrystatusdata?.status) {
      ToastMessage("success", countrystatusdata?.message);
      dispatch(countryList(parameter));
      dispatch(removeDataFromReducer("COUNTRY_STATUS_RESET"));
    } else {
      ToastMessage("error", countrystatusdata?.message);
    }
  }, [countrystatusdata]);

  const columns = [
    {
      name: "Id",
      width: "150px",
      selector: (row) => row?.settingIdFk,
      sortable: true,
      column_name: "settingIdFk",
      reorder: true,
    },

    {
      name: "Data",
      width: "300px",
      selector: (row) => {
        const trimmedData = row?.settingData?.substring(0, 20);

        let formattedData = "";
        switch (row.dataValueIn) {
          case "rupees":
            formattedData = `₹ ${trimmedData}`;
            break;
          case "percent":
            formattedData = `${trimmedData} %`;
            break;
          default:
            formattedData = trimmedData;
        }

        return (
          formattedData
        );
      },
    },
    {
      name: "Description",
      width: "300px",
      selector: (row) =>
        row.settingDescription ? row.settingDescription : "-",

      column_name: "settingDescription",
      reorder: true,
    },
    {
      name: "Type",
      selector: (row) => row.settingType,
      sortable: true,
      column_name: "settingType",
      reorder: true,
    },

    // {
    //   name: "Status",
    // //   width:"150px",
    //   selector: (row) => (
    //     <div className="switch_row">
    //     <CFormSwitch

    //       onClick={() => handleStatus(row.settingIdFk)}
    //       label={row.isActive === 1 ? "Active" : "Inactive"}
    //       id="formSwitchCheckChecked"
    //       defaultChecked={row.isActive}
    //       style={{
    //         backgroundColor: row.isActive === 1 ? "var(--colorused)" : "initial",
    //            color: row.isActive === 1 ? "var(--colorWhite)" : "initial",
    //       }}
    //     />
    //     </div>
    //   ),
    //   // sortable: true,
    //   column_name: "isActive",
    //   reorder: true,
    // },
    {
      name: "Edit",
      selector: (row) => (
        <button
          className="edit_btn"
          onClick={() => handleEdit(row.settingIdFk)}
          style={{
            backgroundColor: "var(--colorused)",
            border: "none",
            color: "var(--colorWhite)",
          }}
        >
          Edit
        </button>
      ),
      // sortable: true,
      column_name: "",
      reorder: true,
    },
  ];

  const handleEdit = (id) => {
    dispatch(removeDataFromReducer("STATE_DETAILS_RESET"));
    navigate(`/dashboard/setting/add/${id}`);
  };
  const customStyles = {
    headCells: {
        style: {
            fontSize: "16px",
            fontWeight: 500,
        },
    },
};

  const handlePerRowsChange = async (newLimit, offset) => {
    setOffset(offset);
    setLimit(newLimit);
  };

  const handlePageChange = (offset) => {
    setOffset(offset);
  };

  const handleSort = async (column, sortDirection) => {
    setSortBy(sortDirection);
    setSortByColumnName(column.column_name || "");
  };

  const handleclearSearch = () => {
    setSearchText("");
  };

  return (
    <div>
      <div>
        <CRow>
          <CCol
            className="d-grid gap-2 d-md-flex justify-content-md-start pb-5"
            lg={9}
            md={9}
            sm={8}
            xs={12}
          >
            <h3>Settings List</h3>
          </CCol>
          <CCol
            className="d-grid gap-2 d-md-flex justify-content-md-center pb-5"
            lg={3}
            md={3}
            sm={3}
            xs={7}
          >
            <div className="search-feild">
              <input
                type="text"
                placeholder="Search"
                style={{ outline: "none" }}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              {searchText ? (
                <ClearIcon
                  onClick={handleclearSearch}
                  sx={{ color: `var(--colorused)`, cursor: "pointer", fontSize: "20px" }}
                />
              ) : (
                ""
              )}
            </div>
          </CCol>
          {/* <CCol
            className="d-grid gap-2 d-md-flex justify-content-md-end pb-5"
            lg={2}
            md={3}
            sm={4}
            xs={5}
          >
            <CButton
              style={{
                backgroundColor: "var(--colorused)",
                border: "none",
                color: "var(--colorWhite)",
                fontSize:"13px",
                overflow: "hidden", 
                whiteSpace: "nowrap", 
                textOverflow: "ellipsis", 
              }}
              className="me-md-2 btn_add_country"
              onClick={handleAddCountry}
              // disabled={isLoading}
            >
              Add Setting
            </CButton>
          </CCol> */}
        </CRow>
      </div>

      <DataTable
        customStyles={customStyles}
        columns={columns}
        data={data}
        dataAlign="center"
        fixedHeader
        progressPending={pending}
        progressComponent={<LoaderAnimation />}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        fixedHeaderScrollHeight="58vh"
        sortServer
        responsive
        onSort={handleSort}
      />
    </div>
  );
};

export default SettingList;
