import React, { useEffect, useState } from "react";
import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CRow,
} from "@coreui/react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  createcountry_api,
  editcountryById_api,
  getcountryById_api,
  ImageUrl,
} from "../../API/baseUrl";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import {
  addcountryRequest,
  editcountrybyidRequest,
  getcountrybyidRequest,
  removeDataFromReducer,
} from "../../redux/actions";
import ToastMessage from "../../utils/ToastMessage";
import { countryaddshema } from "../../utils/schema";
import "./index.css";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const initialValues = {
  countryName: "",
  countryShortCode: "",
  countryISD: "",
  countryCurrency: "",
  file: null,
};

const CountryAdd = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [imageUrlshow, setImageUrlshow] = useState("");

  const { createcountrydata } = useSelector(
    (state) => state.countryaddreduser || {}
  );
  const { editCountrydata } = useSelector(
    (state) => state.EditcountryReduser || {}
  );
  const { getCountrydata } = useSelector(
    (state) => state.getCountryyReduser || {}
  );

  const {
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: countryaddshema,
    onSubmit: (values) => handleSubmitForm(values),
  });

  const handleSubmitForm = async (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("countryName", values.countryName);
    formData.append("countryShortCode", values.countryShortCode);
    formData.append("countryISD", values.countryISD);
    formData.append("countryCurrency", values.countryCurrency);
    formData.append("file", values.file);

    const url = id ? `${editcountryById_api}${id}` : createcountry_api;

    try {
      if (id) {
        await dispatch(editcountrybyidRequest({ values: formData, url }));
      } else {
        await dispatch(addcountryRequest({ values: formData, url }));
      }
    } catch (error) {
      console.error("Error:", error);
      ToastMessage("error", "Failed to submit data.");
    }
  };

  useEffect(() => {
    if (createcountrydata?.status) {
      setLoading(false);
      ToastMessage("success", createcountrydata?.message);
      navigate("/dashboard/country");
      dispatch(removeDataFromReducer("ADD_COUNTRY_RESET"));
    } else if (createcountrydata?.message) {
      ToastMessage("error", createcountrydata?.message);
      setLoading(false);
    }
  }, [createcountrydata, dispatch]);

  useEffect(() => {
    if (editCountrydata?.status) {
      setLoading(false);
      ToastMessage("success", editCountrydata?.message);
      navigate("/dashboard/country");
      dispatch(removeDataFromReducer("COUNTRY_EDIT_RESET"));
      dispatch(removeDataFromReducer("COUNTRY_DETAILS_RESET"));
    } else if (editCountrydata?.message) {
      ToastMessage("error", editCountrydata?.message);
      setLoading(false);
    }
  }, [editCountrydata]);

  useEffect(() => {
    if (getCountrydata?.status) {
      const data = getCountrydata.data || {};
      setFieldValue("countryName", data.countryName || "");
      setFieldValue("countryShortCode", data.countryShortCode || "");
      setFieldValue("countryISD", data.countryISD || "");
      setFieldValue("countryCurrency", data.countryCurrency || "");
      setFieldValue("file", data.countryFlag || null); // Assuming countryFlag is the file value
      setIsFetchingData(false);
    } else if (getCountrydata?.message) {
      ToastMessage("error", getCountrydata.message);
      setIsFetchingData(false);
    }
  }, [getCountrydata, setFieldValue]);

  useEffect(() => {
    if (id) {
      setIsFetchingData(true);
      dispatch(getcountrybyidRequest({ url: `${getcountryById_api}/${id}` }));
    }
  }, [id]);

  const handleChangeimage = (e) => {
    console.log("asdfgfdsa", URL.createObjectURL(e.currentTarget.files[0]))
    setImageUrlshow(URL.createObjectURL(e.currentTarget.files[0]))
    setFieldValue("file", e.currentTarget.files[0])

  }


  // Function to handle back navigation
  const handleBackNavigation = () => {
    navigate(-1); // Navigates back to the previous page
  };

  return (
    <CForm onSubmit={handleSubmit}>
    <h3 className="AddEdit_Heading">  <ArrowBackIcon style={{cursor:"pointer"}} onClick={handleBackNavigation}/>{id ? "Edit Country" : "Add Country"}</h3>
      <CContainer className="add_form_container">
        {isFetchingData ? (
          <>
            <div className="loader-container_edit">
              <CircularProgress />
            </div>
          </>
        ) : (
          <CRow xs={{ gutter: 2 }}>
            <CCol sm={12} md={6}>
              <CFormInput
                name="countryName"
                value={values.countryName}
                type="text"
                label="Country Name *"
                placeholder="Country Name"
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={touched.countryName && !!errors.countryName}
              // className="field-width"
              />
              {touched.countryName && errors.countryName && (
                <div className="invalid-feedback">{errors.countryName}</div>
              )}
            </CCol>
            <CCol sm={12} md={6}>
              <CFormInput
                name="countryShortCode"
                type="text"
                value={values.countryShortCode}
                label="Short Code *"
                placeholder="Short Code"
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={touched.countryShortCode && !!errors.countryShortCode}
              // className="field-width"
              />
              {touched.countryShortCode && errors.countryShortCode && (
                <div className="invalid-feedback">
                  {errors.countryShortCode}
                </div>
              )}
            </CCol>

            <CCol sm={12} md={6}>
              <Form.Group md="4" controlId="validationCustomUsername">
                <Form.Label>Country ISD *</Form.Label>
                <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">+</InputGroup.Text>
                  <Form.Control
                    name="countryISD"
                    type="tel"
                    value={values.countryISD}
                    text={errors.countryISD}
                    invalid={touched.countryISD && !!errors.countryISD}
                    isInvalid={touched.countryISD && !!errors.countryISD}
                    label="Country ISD *"
                    placeholder="Country ISD"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === "" || /^\d+$/.test(value)) {
                        setFieldValue("countryISD", value);
                      }
                    }}
                    onBlur={handleBlur}
                  // className="field-width"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid country ISD code.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              {!values.countryISD ? (
                <>
                  {" "}
                  {touched.countryISD && errors.countryISD && (
                    <div className="invalid-feedback">This is required</div>
                  )}
                </>
              ) : (
                ""
              )}
            </CCol>

            <CCol sm={12} md={6}>
              <CFormInput
                name="countryCurrency"
                type="text"
                value={values.countryCurrency}
                label="Country Currency *"
                placeholder="Country Currency"
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={touched.countryCurrency && !!errors.countryCurrency}
              // className="field-width"
              />
              {touched.countryCurrency && errors.countryCurrency && (
                <div className="invalid-feedback">{errors.countryCurrency}</div>
              )}
            </CCol>


            <CCol sm={12} md={6}>
              <CFormInput
                name="file"
                type="file"
                accept="image/*" // This ensures only image files are accepted
                onChange={(e) => handleChangeimage(e)}
                onBlur={handleBlur}
                invalid={touched.file && !!errors.file}
                label="Country Flag *"
              // className="field-width"
              />
              {imageUrlshow ? (
                <div>
                  Selected File:
                  <img src={imageUrlshow} width={100} height={100} style={{ margin: "2px" }} />
                </div>
              ) :

                <>
                  {
                    values?.file ?
                      <div>
                        Selected File:
                        <img src={`${ImageUrl}${values?.file}`} width={100} height={100} style={{ margin: "2px" }} />
                      </div>
                      : ""

                  }

                </>


              }
              {touched.file && errors.file && (
                <div className="invalid-feedback">{errors.file}</div>
              )}
            </CCol>
          </CRow>
        )}
        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <CButton
            style={{
              backgroundColor: "var(--colorused)",
              border: "none",
              width: "120px",
              color: "var(--colorWhite)",
            }}
            type="submit"
            className="me-md-2"
          >
            {loading ? (
              <CircularProgress size={24} style={{ color: "white" }} />
            ) : (
              <>{id ? "Update" : "Submit"}</>
            )}
          </CButton>
        </div>
      </CContainer>
    </CForm>
  );
};

export default CountryAdd;
