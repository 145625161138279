import { CButton, CFormTextarea, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CFormSelect,
    CRow,
} from "@coreui/react";
import { accountStatusRequest, removeDataFromReducer, restauranttypeListRequest } from "../../redux/actions";
import { restaurantaccountstatus_api, restauranttypelist_api } from "../../API/baseUrl";
import { useFormik } from "formik";
import ToastMessage from "../../utils/ToastMessage";
import "./index.css"
import { AccountStatusShema, acountReasonShema } from "../../utils/schema";
import { CircularProgress } from "@mui/material";


const Intial = {
    restaurantTypeIdFk: "",
    reasonOfRejection: "",
};



const StatusModal = ({ visible, setVisible, restaurantId, statusId, setStatusvalue }) => {
    const dispatch = useDispatch();
    const [typelist, setTypelist] = useState([])
    const { TypeListdata } = useSelector((state) => state?.restauranttypeListreduser || {});
    const { accountStatusdata } = useSelector((state) => state?.accountStatusReduser || {});
    const [loading, setLoading] = useState(false);

    const {
        handleChange,
        handleSubmit,
        setFieldValue,
        handleBlur,
        values,
        errors,
        touched,
    } = useFormik({
        initialValues: Intial,
        validationSchema: statusId == 2 ? acountReasonShema : AccountStatusShema,
        onSubmit: (values) => ApiHandlesubmit(),
    });


    const handleselectchange = (e) => {
        values.restaurantTypeIdFk = e.target.value;
    };

    const handleSubmitform = (e) => {
        e.preventDefault();
        handleSubmit();
        console.log("error", errors);
        console.log("values", values);
    };


    useEffect(() => {
        if (TypeListdata?.status) {
            const newType = TypeListdata?.data?.map((item) => ({
                label: item.type,
                value: item.restaurantTypeIdFk,
            }));
            setTypelist(newType);
        } else {
            ToastMessage("error", TypeListdata?.message);
        }
    }, [TypeListdata]);

    useEffect(() => {
        if (accountStatusdata?.status) {
            setStatusvalue(statusId)
            setVisible(false);
            ToastMessage("success", accountStatusdata?.message);
            dispatch(removeDataFromReducer("ACCOUNT_STATUS_RESET"));
            setLoading(false); 
        } else {
            ToastMessage("error", accountStatusdata?.message);
            setLoading(false); 
        }
    }, [accountStatusdata]);


    useEffect(() => {
        const parameter = {
            url: restauranttypelist_api,
        };
        dispatch(restauranttypeListRequest(parameter));
    }, []);



    const ApiHandlesubmit = async () => {
        setLoading(true); 
        console.log("values", values);
        const parameter = {
            restaurantIdFk: restaurantId,
            accountStatus: statusId,
            restaurantTypeIdFk: values.restaurantTypeIdFk,
            reasonOfRejection: values.reasonOfRejection,
            url: restaurantaccountstatus_api,
        };
        dispatch(accountStatusRequest(parameter));
    };


    return (
        <div>
            <CModal
                backdrop="static"
                  alignment="center"
                visible={visible}
                onClose={() => setVisible(false)}
                aria-labelledby="StaticBackdropExampleLabel"
            >
               
                    <CModalTitle id="StaticBackdropExampleLabel" style={{padding:'4px 31px'}} className="mt-3">Update Account Status</CModalTitle>
            
                <CModalBody style={{border:"none"}}>
                    <CForm onSubmit={handleSubmitform}>
                        <CContainer> 
                            <CRow xs={{ gutter: 2 }}>
                            {statusId == 1 ? <CCol sm={12} md={12}>
                            <CFormSelect
                                        name="restaurantTypeIdFk"
                                        label="Restaurant Type"
                                        placeholder="Country Name"
                                        // value={values.countryname}
                                        text={errors.restaurantTypeIdFk}
                                        onChange={handleselectchange}
                                        onBlur={handleBlur}
                                    >
                                        <option value="" disabled selected>
                                            Select a Type
                                        </option>
                                        {typelist?.map((item, index) => (
                                            <option key={index} value={item.value}>
                                                {item.label}
                                            </option>
                                        ))}
                                    </CFormSelect>
                                    {/* {touched.restaurantType && errors.restaurantType && (
                                        <div className="invalid-feedback">{errors.restaurantType}</div>
                                    )} */}
                                </CCol> : ""}

                                {statusId == 2 ? <CCol sm={12} md={12}>
                                    <CFormTextarea
                                        id="exampleFormControlTextarea1"
                                        name="reasonOfRejection"
                                        type="text"
                                        value={values.reasonOfRejection}
                                        label="Reason Of Rejection"
                                        rows={3}
                                        placeholder="Reason Of Rejection"
                                        text={errors.reasonOfRejection}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    ></CFormTextarea>
                                    {/* {touched.reasonOfRejection && errors.reasonOfRejection && (
                                        <div className="invalid-feedback">{errors.reasonOfRejection}</div>
                                    )} */}
                                </CCol> : ""}



                            </CRow>

                        </CContainer>

                        <CModalFooter className="modalFooter">
                            <CButton color="secondary" onClick={() => setVisible(false)}>
                                Close
                            </CButton>
                            <CButton type="submit" className="btn_color"    disabled={loading}>   {loading ? <CircularProgress size={24}  color="success" /> : "Update"}</CButton>
                        </CModalFooter>


                    </CForm>
                </CModalBody>

            </CModal>
        </div>  
    );
};

export default StatusModal;










































