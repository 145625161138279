import React, { useEffect, useRef } from "react";
import { CChartLine } from "@coreui/react-chartjs";
import { getStyle } from "@coreui/utils";

const MainChart = ({ dashboarddata }) => {
  const chartRef = useRef(null);

  // Function to calculate the maximum Y-axis value dynamically
  const calculateMaxY = (data1 = [], data2 = []) => {
    // Ensure both data arrays are defined and are arrays
    const maxData1 = Array.isArray(data1) ? Math.max(...data1) : 0;
    const maxData2 = Array.isArray(data2) ? Math.max(...data2) : 0;
    return Math.ceil(Math.max(maxData1, maxData2) * 1.1); // Add a little buffer (10%) for better visibility
  };

  useEffect(() => {
    document.documentElement.addEventListener("ColorSchemeChange", () => {
      if (chartRef.current) {
        setTimeout(() => {
          chartRef.current.options.scales.x.grid.borderColor = getStyle(
            "--cui-border-color-translucent"
          );
          chartRef.current.options.scales.x.grid.color = getStyle(
            "--cui-border-color-translucent"
          );
          chartRef.current.options.scales.x.ticks.color =
            getStyle("--cui-body-color");
          chartRef.current.options.scales.y.grid.borderColor = getStyle(
            "--cui-border-color-translucent"
          );
          chartRef.current.options.scales.y.grid.color = getStyle(
            "--cui-border-color-translucent"
          );
          chartRef.current.options.scales.y.ticks.color =
            getStyle("--cui-body-color");
          chartRef.current.update();
        });
      }
    });
  }, [chartRef]);

  // Safeguard: Default empty arrays if data is undefined
  const magicBagRevenueArray = dashboarddata?.magic_bag_revenue_Array || [];
  // const magicBagPurchaseArray = dashboarddata?.magic_bag_purchase_Array || [];

  const maxYValue = calculateMaxY(
    magicBagRevenueArray
    // , magicBagPurchaseArray
  );

  return (
    <CChartLine
      ref={chartRef}
      style={{ height: "300px", marginTop: "40px" }}
      data={{
        labels: dashboarddata?.X_Axis_Array || [], // X-axis dynamic array with a fallback to an empty array
        datasets: [
          {
            label: "Magic Bag Revenue",
            backgroundColor: `rgba(${getStyle("--cui-info-rgb")}, .1)`,
            borderColor: getStyle("--cui-info"),
            pointHoverBackgroundColor: getStyle("--cui-info"),
            borderWidth: 2,
            data: magicBagRevenueArray, // Dynamic revenue data
            fill: true,
          },
          // {
          //   label: 'Magic Bag Purchases',
          //   backgroundColor: 'transparent',
          //   borderColor: getStyle('--cui-success'),
          //   pointHoverBackgroundColor: getStyle('--cui-success'),
          //   borderWidth: 2,
          //   data: magicBagPurchaseArray, // Dynamic purchase data
          // },
        ],
      }}
      options={{
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            grid: {
              color: getStyle("--cui-border-color-translucent"),
              drawOnChartArea: false,
            },
            ticks: {
              color: getStyle("--cui-body-color"),
            },
            title: {
              display: true,
              text: 'Time', // Label for X-axis
              color: getStyle('--cui-body-color'),
            },
          },
          y: {
            beginAtZero: true,
            grid: {
              color: getStyle("--cui-border-color-translucent"),
            },
            ticks: {
              color: getStyle("--cui-body-color"),
              maxTicksLimit: 5,
              stepSize: Math.ceil(maxYValue / 5),
            },
            max: maxYValue, // Dynamically set the Y-axis max value
            title: {
              display: true,
              text: 'Revenue', // Label for Y-axis
              color: getStyle('--cui-body-color'),
            },

          },
        },
        elements: {
          line: {
            tension: 0.4,
          },
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3,
          },
        },
      }}
    />
  );
};

export default MainChart;
