
import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic/build/ckeditor';
import "./index.css"


const CkEditor = ({ onChange, className, data }) => {
    // function CustomUploadAdapterPlugin(editor) {
    //     editor.plugins.get("FileRepository").createUploadAdapter = loader => new UploadAdapter(loader);
    // }
    const config = {
        language: "en",
        // extraPlugins: [CustomUploadAdapterPlugin]
    };
    return (
        <div className="App">
            <CKEditor
                className={className}
                config={config}
                editor={ClassicEditor}
                data={data}
                onChange={(event, editor) => onChange(event, editor)}
            />
        </div>
    );
}
export default CkEditor;