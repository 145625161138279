// import React, { useEffect, useState } from "react";
// import {
//   dashboard_revenue_api,
//   Revenue_Dashboard_Admin_vendor,
// } from "../../API/baseUrl";
// import ToastMessage from "../../utils/ToastMessage";
// import { useDispatch, useSelector } from "react-redux";
// import { commissionRequest, dashboardaction } from "../../redux/actions";
// import { CAccordion, CAccordionBody, CAccordionHeader, CAccordionItem } from "@coreui/react";

// function CommisionCalulation({ id }) {
//   const dispatch = useDispatch();

//   const { Commissiondata } = useSelector(
//     (state) => state?.Commissionreduser || {}
//   );
//   const [data, setData] = useState([]);
//   const [pending, setPending] = useState(true);

//   useEffect(() => {
//     if (Commissiondata) {
//       if (Commissiondata?.status) {
//         setData(Commissiondata);
//         setPending(false);
//       } else {
//         ToastMessage("error", Commissiondata?.message);
//         setPending(false);
//       }
//     }
//   }, [Commissiondata]);

//   useEffect(() => {
//     const parameter = {
//       url: Revenue_Dashboard_Admin_vendor,
//       restaurantIdFk: id,
//     };
//     dispatch(commissionRequest(parameter));
//   }, []);

//   return (
//     <div>
//       <CAccordion alwaysOpen activeItemKey={2}>
//         <CAccordionItem itemKey={2}>
//           <CAccordionHeader>Commission Calculation</CAccordionHeader>
//           <CAccordionBody>
//             <div className="acordian_body_inside">
//           <h2>hdfhdfhf</h2>
//             </div>
//           </CAccordionBody>
//         </CAccordionItem>
//       </CAccordion>
//     </div>
//   );
// }

// export default CommisionCalulation;
import React, { useEffect, useState } from "react";
import {
  dashboard_revenue_api,
  Revenue_Dashboard_Admin_vendor,
} from "../../API/baseUrl";
import ToastMessage from "../../utils/ToastMessage";
import { useDispatch, useSelector } from "react-redux";
import { commissionRequest, dashboardaction } from "../../redux/actions";
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
} from "@coreui/react";
import { TableSk } from "../skelaton/tableSk";
import { alignProperty } from "@mui/material/styles/cssUtils";
import { Skeleton, TableBody, TableCell, TableRow } from "@mui/material";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";

function CommisionCalulation({ id }) {
  const dispatch = useDispatch();

  const { Commissiondata } = useSelector(
    (state) => state?.Commissionreduser || {}
  );
  const [data, setData] = useState(null);
  const [pending, setPending] = useState(true);

  // Handle API data response
  useEffect(() => {
    if (Commissiondata) {
      if (Commissiondata?.status) {
        setData(Commissiondata?.magic_bag_details);

        setPending(false);
      } else {
        ToastMessage("error", Commissiondata?.message);

        setPending(false);
      }
    }
  }, [Commissiondata]);

  // Dispatch the action to fetch commission data
  useEffect(() => {
    const parameter = {
      url: Revenue_Dashboard_Admin_vendor,
      restaurantIdFk: id,
    };
    dispatch(commissionRequest(parameter));
  }, [id]);
  const checkAvailability = (data) => data || "-";

  return (
    <div>
      <CAccordion alwaysOpen activeItemKey={2}>
        <CAccordionItem itemKey={2}>
          <CAccordionHeader>Commission Calculation</CAccordionHeader>
          <CAccordionBody>
            {pending ? (
              // <div style={{justifyContent:"center",display:"grid"}}>
              //   <TableBody>
              //     {Array.from({ length: 3 }).map((_, index) => (
              //       <TableRow
              //         key={index}
              //         sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              //       >
              //         <TableCell
              //           className="first_cell"
              //           size="small"
              //           scope="row"
              //         >
              //           <Skeleton
              //             animation="pulse"
              //             style={{ width: "250px" }}
              //             height={55}
              //           />
              //         </TableCell>
              //         <TableCell
              //           className="first_cell"
              //           size="small"
              //           scope="row"
              //         >
              //           <Skeleton
              //             animation="pulse"
              //             style={{ width: "250px" }}
              //             height={55}
              //           />
              //         </TableCell>
              //         <TableCell
              //           className="first_cell"
              //           size="small"
              //           scope="row"
              //         >
              //           <Skeleton
              //             animation="pulse"
              //             style={{ width: "250px" }}
              //             height={55}
              //           />
              //         </TableCell>
              //         <TableCell
              //           className="first_cell"
              //           size="small"
              //           scope="row"
              //         >
              //           <Skeleton
              //             animation="pulse"
              //             style={{ width: "250px" }}
              //             height={55}
              //           />
              //         </TableCell>
              //       </TableRow>
              //     ))}
              //   </TableBody>
              // </div>
              <LoaderAnimation/>
            ) : (
              <div className="acordian_body_inside">
                {data ? (
                  <>
                    <div className="view_membership_field">
                      <b>Total Revenue: </b>
                      <p className="viewpagevalues">
                        {checkAvailability(data?.Total_Revenue)}
                      </p>
                    </div>
                    <div className="view_membership_field">
                      <b>Total Commision: </b>
                      <p className="viewpagevalues">
                        {checkAvailability(data?.Total_Commision)}
                      </p>
                    </div>
                    <div className="view_membership_field">
                      <b>Total Purchase: </b>
                      <p className="viewpagevalues">{data?.Total_Purchase}</p>
                    </div>
                    <div className="view_membership_field">
                      <b>Total Users: </b>
                      <p className="viewpagevalues">{data?.Total_Users}</p>
                    </div>
                    <div className="view_membership_field">
                      <b>Total Restaurant: </b>
                      <p className="viewpagevalues">{data?.Total_Restaurant}</p>
                    </div>
                  </>
                ) : (
                  <p>No data available</p>
                )}
              </div>
            )}
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
    </div>
  );
}

export default CommisionCalulation;
